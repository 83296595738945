import React from 'react';
import ContactUs from './../../shared/contact/Contact'

const Contact = () => {
    return (
        <React.Fragment>
            <ContactUs />
        </React.Fragment>
    )
}

export default Contact;