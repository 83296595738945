import './sanctionList.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

function createData(name, description) {
    return {
        name,
        description
    };
}

const rows = [
    //createData('Adverse Media/PEP','Adverse Media/PEP searches on more than 33 global sources on pattern of money laundering, Drug Trafficking, Human Trafficking & Terrorism.'),
    createData('UN', 'United Nations Security Council Consolidated List.'),
    createData('OFAC', "US department of treasury- OFAC List."),
    createData('HMT', "UK HM Treasury."),
    createData('EU', "European Union Sanctions List."),
    createData('DFAT', "Deptt. of Foreign Affairs & Trade Australia Consolidated List."),
    createData('UNSCR','United Nations Security Council Resolutions.'),
    createData('FBI','FBI terrorist list.'),
    createData('INTERPOL','INTERPOL terrorist list.'),
    createData('UAET','UAE terrorist list.'),
    createData('MHA','India MHA.'),
    createData('SEBI','India SEBI.'),
    createData('MCA','India MCA.'),
    createData('WB','World Bank.'),
    createData('FATF','FATF List.'),
    createData('NON-SDN OFAC','NON-SDN OFAC.'),
    createData('Canada List','Consolidated Canadian Autonomous Sanctions List'),
    createData('Inter-American Development Bank','IDB -Sanctioned Firms and Individuals'),
    createData('Belgium List','Fedral Public Services, Finance, Tresaurie, Consolidated Sanction List, Belgium'),
    createData('USA List','U.S. Department of State Consolidated List'),
    createData('Switzerland List','State Secretariat for Economic Affairs SECO List'),
    createData('Asia Development Bank','Asia Development Bank, Published List'),
    createData('African Development Bank','African Development Bank Group List'),
    createData('Poland List','List of persons and entities subject to sanctions, Poland'),
    createData('Argentina List','Public Registry of Persons and Entities linked to acts of Terrorism and its Financing, Argentina List'),
    createData('RBIs NBFCs & ARC List','List of NBFCs and ARCs whose CoR has been cancelled by the RBI'),
    createData('FIU India','Financial Intelligence Unit India, Order & Judgment List'),
    createData('Income Tax','Income Tax Department India Defaulter List'),

];

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Type',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    }

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } =
        props;


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all types',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={false}
                    >

                        {headCell.label}


                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}





const SanctionList = (props) => {

    const [selected, setSelected] = React.useState(["UN", "OFAC", "HMT", "EU", "DFAT",'UNSCR','FBI',
    'INTERPOL','UAET','MHA','SEBI','MCA','WB','FATF','NON-SDN OFAC','Canada List','Inter-American Development Bank',
    'Belgium List','USA List','Switzerland List','Asia Development Bank','African Development Bank','Poland List','Argentina List','RBIs NBFCs & ARC List','FIU India','Income Tax']);
    const [page] = React.useState(0);

    const [rowsPerPage] = React.useState(5);

    const nextHandler = () => {
        props.handleInput({ sanction_list: selected,scan_state:"not ready" });
        // Do validation if selected array is empty don't allow to move to next screen
        if (selected.length !== 0) {
            props.setActiveStep(2)
        }
    }

    const backHandler = () => {
        props.setActiveStep(0)
    }


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <div style={{ color: "#234E70", padding: "10px" }}><h2>Select Sanctions List </h2></div>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}

                            onSelectAllClick={handleSelectAllClick}

                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.description}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ color: "#EB2188", padding: "5px", textAlign: "center" }}>{selected.length === 0 ? <h4>Please select at least one list!</h4> : null}</div>
            </Paper>
            <div className="button-holder">
                <Button onClick={backHandler} class="button-pink">Back</Button>
                <Button onClick={nextHandler} class="button-blue">Next</Button>
            </div>
        </Box>
    );

}

export default SanctionList;