import React from 'react';
import PriceTable from './../../shared/pricing/PriceTable';
import config from './../../../utility/configurations.json';
import MiniFeatureCard from '../../shared/featuresCard/MiniFeatureCard';
const Pricing = () => {
    
    return (
        <React.Fragment>
            <div style={{marginTop:"110px"}}>
            <MiniFeatureCard/>
           <PriceTable price={config.pricing}></PriceTable>
           </div>
       </React.Fragment>
     )
 }

 export default Pricing;