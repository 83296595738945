import Wrapper from './../../Wrapper';
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { postData } from '../../../../utility/networkCall';
import loader from './../../../../assets/load.svg'


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    let headCells = props.headCells
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                    style={{fontWeight:"bold", fontSize:"16px"}} 
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function HistoryTable(props) {
    let rows = props.data
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(props.orderBy);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadFile = (file,name) => {
        const element = document.createElement('a');
        let val = 'data:application/pdf;base64,' + file;
        element.setAttribute('href', val);
        element.setAttribute('download', name);
       
    
    
        element.style.display = 'none';
    
        document.body.appendChild(element);
    
        element.click();
        document.body.removeChild(element);
    }
    
    const [loading, setLoading] = React.useState(false)
    const[message, setMessage]=React.useState("")

    const rowClickHandler = (id) => {
        if(typeof(id) === "number"){
            setLoading(true)
            postData('getScanHistoryPdfReport', {"scan_id":id})
            .then(d=>{
                if(d==="NO Scan Report Available"){
                    setMessage("No Scan Report Available!")
                    setTimeout(() => {
                        setMessage("")
                     }, 2000)
                }
                else{
                downloadFile(d["pdfString"], d.firstname+' '+d.lastname+'.pdf')
                }
                setLoading(false)
            })
            .catch(e=>{setLoading(false);setMessage("Network Error.")})
        }
    }
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const level = ["", "AND", "OR", "Advance"]
    return (
        <React.Fragment>

            {props.wrapper ? <Wrapper title={props.title}>
            {loading?<div style={{textAlign:"center",lineHeight:"50px"}}><img alt='' src={ loader } height={"50px"} style={{display:"block", margin:"auto"}}></img> <span style={{ color:"#EB2188"}}>Please Wait, Downloading Report.</span></div>:null}
            {message!=="" && !loading?<div style={{textAlign:"center",lineHeight:"50px"}}> <span style={{ color:"#EB2188"}}>{message}</span></div>:null}

                <TableContainer>
                    <Table
                        aria-labelledby={"History"}
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            fontWeight="bolder"
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={props.headCells}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name}
                                            onClick={()=>{rowClickHandler(row.scan_id)}}
                                        >
                                            <TableCell align="left">{row.order_date === undefined ? new Date(row.requestTimeStamp).toLocaleDateString() : new Date(row.order_date).toLocaleDateString()}</TableCell>
                                            <TableCell>{row.scans === undefined ? row.firstname + ' ' + row.middlename + ' ' + row.lastname : row.scans}</TableCell>
                                            <TableCell>{row.price === undefined ? row.searchInList.toUpperCase() : '$ '+row.price/100}</TableCell>
                                            {row.payment_id !== undefined && <TableCell> {row.payment_id}</TableCell>}
                                            <TableCell>{row.status === undefined ? level[row.searchLevel] : row.status}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* {loading?<div style={{textAlign:"center"}}><img alt='' src={ loader } height={"50px"}></img></div>:null} */}
            </Wrapper>
                :
                <div style={{background:"white", boxShadow:"box-shadow: 3px 3px 5px #888888", marginTop:"5px"}}>
                    {loading?<div style={{textAlign:"center",lineHeight:"50px"}}><img alt='' src={ loader } height={"50px"} style={{display:"block", margin:"auto"}}></img> <span style={{ color:"#EB2188"}}>Please Wait, Downloading Report.</span></div>:null}
                    <TableContainer>
                        <Table
                            aria-labelledby={"History"}
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={props.headCells}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.name}
                                                onClick={()=>{rowClickHandler(row.scan_id)}}
                                            >
                                                <TableCell align="left">{row.order_date === undefined ? new Date(row.requestTimeStamp).toLocaleDateString() : new Date(row.order_date).toLocaleDateString()}</TableCell>
                                                <TableCell>{row.scans === undefined ? row.firstname + ' ' + row.middlename + ' ' + row.lastname : row.scans}</TableCell>
                                                <TableCell>{row.price === undefined ? row.searchInList.toUpperCase() : row.price}</TableCell>
                                                {/* <TableCell>{row.payment_id === undefined ? row.adverseSelected ? "Included" : "Excluded" : row.payment_id}</TableCell> */}
                                                <TableCell>{row.status === undefined ? level[row.searchLevel] : row.status}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    
                </div>

            }
        </React.Fragment>

    );
}



