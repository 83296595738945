import React from 'react';
import ContactUs from './../../shared/contact/Contact'

const Contact = () => {
    return (
        <React.Fragment>
            <div style={{marginTop:"65px"}}>
            <ContactUs />
            </div>
        </React.Fragment>
    )
}

export default Contact;