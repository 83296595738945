import React, { useState } from "react";
import "./forgotPassword.css";
import EmailIcon from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import { postData } from "../../../utility/networkCall";
import loader from "./../../../assets/load.svg";
import ResetPassword from "./ResetPassword";
import Alert from "@mui/material/Alert";
import intellewings_logo1 from "./../../../assets/intellewings_logo1.png";
import showcase from "./../../../assets/showcase.png";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "95%",

    margin: theme.spacing(1.5, 0),
  },
}));

const theme = createTheme({
  palette: {
    primary: pink,
  },
});

const ForgotPassword = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(false);
  const onSubmit = (data) => {
    setEmail(data);
    setLoading(true);
    postData("forgot", data)
      .then((d) => {
        if (d.msg === "success") {
          setMessage("We have e-mailed your password reset code.");
          setToken(d.token);
          setTimeout(() => {
            setStep(2);
          }, 2000);
        }  else if (d.msg === "Unable to send code. Kindly try it again!!") {
          setMessage("Unable to send code. Kindly try it again.");
        } else {
          setMessage("Some Network error occured. Try again in some time.");
        }
        setLoading(false);
      })
      .catch((e) => {
        alert("network error!");
        setLoading(false);
      });
  };

  return (
    <>
      {step === 1 ? (
        <div className="forgot-password-page">
          <div className="page-container">
            <div className="image-container">
              <img
                alt="intellewings-logo"
                src={intellewings_logo1}
                className="logo"
              ></img>
              <img
                alt="intellewings-showcase"
                src={showcase}
                className="showcase"
              ></img>
            </div>

            <div className="forgot-password-container">
              <div className="container">
                {/* <div className="fp-heading">Forgot Password</div>
                <p className="hero">
                  Please provide your recovery email or username.
                </p> */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="registerform"
                >
                  <div class="text-header">
                    <h2>Forgot Passowrd</h2>
                    <p className="register-subtitle">
                      Please provide your recovery email or username.
                    </p>
                  </div>
                  <div className="fieldInput">
                    <ThemeProvider theme={theme}>
                      <TextField
                        label="Email Address"
                        variant="outlined"
                        id="mui-theme-provider-outlined-input"
                        name="email"
                        {...register("email", {
                          required: "Enter a valid email address",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            message: "Please enter a valid e-mail address.",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon className="fp-icons" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ThemeProvider>
                  </div>
                  {loading ? (
                    <div style={{ textAlign: "center", lineHeight: "50px" }}>
                      <img
                        src={loader}
                        height={"50px"}
                        style={{ display: "block", margin: "auto" }}
                      ></img>
                    </div>
                  ) : null}

                  <div className="register-button-container">
                    <Button type="submit" class="registerButton">
                      Forgot Password
                    </Button>
                  </div>
                </form>
                {message !== "" && (
                  <div style={{ padding: "10px", textAlign: "center" }}>
                    {message !== "" && !loading ? (
                      <Alert
                        severity={
                          message ===
                          "We have e-mailed your password reset code."
                            ? "success"
                            : "error"
                        }
                      >
                        {message}
                      </Alert>
                    ) : null}
                  </div>
                )}

                <p className="register-footer">
                  <Link to="/signin" className="fp-anchortag">
                    Go back to Sign in page
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ResetPassword email={email.email} token={token} />
      )}
    </>
  );
};

export default ForgotPassword;
