import React, {
  useState, memo,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import './card.css'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconButton } from '@material-ui/core';





const RangeSlider = memo(
  ({ classes, label, onChange, value, ...sliderProps }) => {
    const [sliderVal, setSliderVal] = useState(0);
    const [mouseState, setMouseState] = useState(null);

    useEffect(() => {
      setSliderVal(value);
    }, [value]);

    const changeCallback = e => {
      setSliderVal(e.target.value);
    };

    useEffect(() => {
      if (mouseState === "up") {
        onChange(sliderVal);
      }
    }, [mouseState]);
    return (
      <div className="range-slider">
        <p>Text Match: {sliderVal} %</p>
        <input
          type="range"
          value={sliderVal}
          {...sliderProps}
          className='input-range'
          id="myRange"
          color='secondary'
          onChange={changeCallback}
          onMouseDown={() => setMouseState("down")}
          onMouseUp={() => setMouseState("up")}
        />
      </div>
    );
  }
);


const OperationCard = (props) => {
  const title = props.title;
  var scanType=props.scanType;

  const [parentVal, setParentVal] = useState(75);

  const sliderValueChanged = useCallback(val => {
    setParentVal(val);
  });

  const sliderProps = useMemo(
    () => ({
      min: 75,
      max: 100,
      value: parentVal,
      onChange: e => sliderValueChanged(e)
    }),
    [parentVal]
  );

  const [checkbox, setCheckbox] = useState(props.checkboxSelector);
  const handleClickCheckbox = () => {
    setCheckbox(!checkbox);
  }

  useEffect(() => {
    props.inputHandler(props.id, parentVal,props.ID,checkbox)
  }, [parentVal, checkbox])
  return (
    <React.Fragment>
      <div className='card-container'>
        <div className='card-container-div1'>
          <p>{title}
            {scanType!="Batch"?<IconButton className='card-container-div1-iconbutton' onClick={handleClickCheckbox}>
              {!checkbox ? (<CheckBoxOutlineBlankIcon onClick={() => setCheckbox(!checkbox)} className='' />) : (<CheckBoxIcon onClick={() => setCheckbox(!checkbox)} className='card-check-box-icon' />)}
            </IconButton>:<p style={{marginTop:"30px",color:"#234E70"}} >""</p>}
          </p>
          {scanType!="Batch"?<p className='card-checkbox-included'>{checkbox ? 'Included' : 'Not Included'}</p>:null}

        </div>
        <div className='card-container-div2'>
          <div>
            <RangeSlider {...sliderProps} classes="additional-css-classes" />
          </div>
        </div>
        <div className='card-container-div3'>
          Search Sanction list at:<br></br>
          <span >
            {props.listitems.map(listitem => { return <p>{listitem}</p> })}
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OperationCard;