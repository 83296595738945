import React, { useState, useEffect } from 'react';
import './apiReset.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    TextField,
    IconButton,
    Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { getData } from '../../../utility/networkCall';




const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "75%",
        margin: theme.spacing(1, 0),
    },
}));

const ApiReset = (props) => {
    let userData = props.userData
    const updateUserData = props.updateUserData
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState("API Key Copied.")
    const copyToClipboard = () => {
        navigator.clipboard.writeText(userData[0].apiKey);
        setAlert(true)
        setTimeout(() => {
            setAlert(false)
        }, 2000)
    }
    useEffect(() => {
        // console.log(userData, "updated")
    }, [userData])

    const apiResetHandler = (apiKey) => {
        getData(`resetApiKey/${sessionStorage.getItem('email')}/${apiKey}`, {})
            .then(d => {
                let userData1 = userData
                userData1[0].apiKey = d.new_apiKey
                // console.log(userData1)
                updateUserData({ userData: userData1 })
            })
            .catch(e => console.log(e))

        // window.alert("reset")

    }
    const classes = useStyles();
    return (

        <div>


            <div className='statastics-container'>
                <div className="statastics-box">
                    <h3>Total Scans</h3>
                    <h2>{userData[0].total_checks}</h2>
                </div>
                <div className="statastics-box">
                    <h3>Scans Available</h3>
                    <h2>{userData[0].checks_left}</h2>

                </div>
                <div className="statastics-box">
                    <h3>Total Scans Used</h3>
                    <h2>{userData[0].api_scans}</h2>

                </div>

                {/* <div className='api-alert'>
                You should never expose your API keys in any public website's client-side code.
            </div> */}

                <div className='api-data-holder'>
                    <div className='api-reset-div'>
                        API Key<br></br>
                        <TextField
                            value={userData[0].apiKey}
                            variant="outlined"
                            fullWidth
                            className={classes.inputField}
                            name="password"
                            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                            // onChange={someChangeHandler}
                            InputProps={{
                                readOnly: true,// <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Tooltip title="Copy to Clipboard">
                            <IconButton onClick={copyToClipboard} >
                                <ContentCopyIcon className='api-content-copy-icon' />
                            </IconButton>
                        </Tooltip>

                        <Button class="button-blue" onClick={() => apiResetHandler(userData[0].apiKey)}> Reset API Key</Button>
                        <div style={{ padding: "5px", textAlign: "center" }}>{alert ? <Alert severity="success">{message}</Alert> : null}</div>



                    </div>

                </div>
            </div>

        </div>
    )
}

export default ApiReset
