import React from 'react';
import { useEffect } from 'react';
import './cartDetails.css'
import { Button,IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import { postData } from './../../../utility/networkCall'
import loader from './../../../assets/load.svg'
import Snackbar from '@mui/material/Snackbar';

const CartDetails = (props) => {
    const [cartState, setCartState] = React.useState(props.cart)
    const [order, setOrder] = React.useState([])
    const [success, setSuccess] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    let cart = []
    let scans = 0;
    let price = 0;
    for (let i = 0; i < cartState[0].length; i++) {
        cart.push({ "scans": cartState[1][i]["scans"].toString(), "unit": cartState[0][i].toString() })
        scans = scans + cartState[0][i] * cartState[1][i]["scans"]
        price = price + cartState[0][i] * cartState[1][i]["price"]
    }

    const success_processor = (pid, gid, razorpay_signature, oid) => {
        postData('payment', { email: sessionStorage.getItem('email'), payment_id: pid, price: price, scans: scans, order_id: oid, verification_hash: razorpay_signature, gs_payment_id: gid })
            .then(d => {
                cart = []
                props.deleteItemFromCart(0)
                props.deleteItemFromCart(1)
                props.deleteItemFromCart(2)
                props.deleteItemFromCart(3)
                setSuccess(true)
                setTimeout(() => { window.location = '/' }, 5000)

            })
    }

    const failed_processor = (code, desc, source, step, reason, oid, pid) => {
        // add failure capture
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    loadScript("https://checkout.razorpay.com/v1/checkout.js")

    let options = {}
    const generateOrder = () => {
        if (price === 0) {
            alert("Cart is empty!")
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true)
        postData('orderGeneration', { packages: cart, email: [sessionStorage.getItem('email')] })
            .then(d => {
                options = {
                    "key": props.mode === 'beta' ? "rzp_test_SJZgLGC7PTHK26" : "rzp_live_0knatIMeqocewk",
                    "amount": parseFloat(price) * 100, "currency": "USD",
                    "name": "Intellewings",
                    "description": "Package Amount",
                    "image": "",
                    "order_id": d.order_id,
                    "handler": function (response) {
                        const payment_id = response.razorpay_payment_id;
                        const gb_payment_id = d.process_id;
                        const razorpay_signature = response.razorpay_signature;
                        const orderid = response.razorpay_order_id;
                        console.log("SUCCESS:" + payment_id + ":" + gb_payment_id + ":" + razorpay_signature + ":" + orderid, "*");
                        success_processor(payment_id, gb_payment_id, razorpay_signature, orderid)
                    },
                    "prefill": {
                        "name": "",
                        "email": sessionStorage.getItem('email'),
                        "contact": ""
                    },
                    "notes": {
                        "address": "Autofy"
                    },
                    "theme": {
                        "color": "#DF0145"
                    },
                    "modal": {
                        "ondismiss": function () {
                            window.parent.postMessage("MODAL_CLOSED", "*");
                        }
                    }
                };
                var rzp1 = new window.Razorpay(options);
                rzp1.open()
                rzp1.on('payment.failed', function (response) {
                    failed_processor(response.error.code, response.error.description, response.error.source, response.error.step, response.error.reason, response.error.metadata.order_id, response.error.metadata.payment_id)

                });
                setLoading(false)
            })

            .catch(e => { console.log(e); setLoading(false) })
    }



    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    useEffect(() => {
        if(success){
            handleClick()
        }
    }, [success])

    return (

        <div className="cart-container">

            
            <div className='cartD-box1'>
                <p className='cartD-heading'>Cart</p>
                {price === 0 ? <p style={{ color: "#EB2188", paddingLeft: "28px", fontWeight: "bold" }}>Cart is empty, please add a package.</p> : null}
                {
                    props.cart[0].map((amount, index) => {
                        if (amount > 0) {
                            return (
                                <div className='cartD-countbox'>
                                    <div className='cartD-countbox-text'>{cartState[1][index]["scans"]} scans

                                    </div>
                                    <div className='cartD-countbox-text-div'>
                                        <span className='cartD-countbox-span'>$ {cartState[1][index]["price"]}</span>

                                        <Button
                                            onClick={() => {
                                                props.cartOperation(false, index)
                                            }}
                                        >

                                            <RemoveIcon fontSize="small" />
                                        </Button>
                                        <div className='cartD-countvalue'>{amount}</div>

                                        <Button
                                            onClick={() => {
                                                props.cartOperation(true, index)
                                            }}
                                        >
                                            {" "}
                                            <AddIcon fontSize="small" />
                                        </Button>

                                    </div>
                                    <div className='cartD-closebutton'><CloseIcon className='closeicon' onClick={() => { props.deleteItemFromCart(index) }} /></div>

                                </div>
                            )
                        }
                    })
                }

            </div>
            <div className="cartD-box2">
            <p className='cartD-heading'>Order Summary</p>

                <p className='cartD-text'>Total Cost :<span className='cartD-span'> ${price}</span></p>
                <p className='cartD-text'>Total Scans :<span className='cartD-span'> {scans}</span></p>
                <div className='cartD-button-div'>
                    <Button class="cartpinkbutton" onClick={() => { props.history.push('/pricing') }}>Add Credit Pack</Button>
                    <Button class="cartbluebutton" onClick={() => { generateOrder() }}>Checkout</Button>
                </div>
                {loading ? <div style={{ textAlign: "center", lineHeight: "50px" }}><img src={loader} height={"50px"} style={{ display: "block", margin: "auto" }}></img></div> : null}
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Payment Successful!"
                action={action}
            />
        </div>
    )
}

export default withRouter(CartDetails);