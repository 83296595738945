import React, { useState, useEffect } from 'react';
import './signin.css';
import EmailIcon from '@material-ui/icons/Email';
import intellewings_logo1 from './../../../assets/intellewings_logo1.png';
import showcase from './../../../assets/showcase.png';
import {
    TextField,
    Button,
    IconButton
} from "@material-ui/core";
import { pink } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider, createTheme, } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import { postData } from './../../../utility/networkCall';
import Alert from '@mui/material/Alert';
import loader from './../../../assets/load.svg'




const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "95%",

        margin: theme.spacing(1.5, 0),
    },
}));

const theme = createTheme({
    palette: {
        primary: pink,
    },
});

const Signin = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState("")
    const [otpMsg, setOtpMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [loginData, setLoginData] = useState();
    const [enabler, setEnabler] = useState(false);
    const [isOtpLogin, setIsOtpLogin] = useState(0);
    var counter = 30;
    const [resendCount, setResendCount] = useState(0);

    const [seconds, setSeconds] = useState(counter);
    useEffect(() => {
        
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
                counter = counter - 1
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });


    const onSubmit = (data) => {
        setLoading(true)
        setLoginData(data)
        postData('signin', data)
            .then(d => {
                if (d.msg === "success") {
                    sessionStorage.setItem("token", d.token);
                    sessionStorage.setItem("type", d.user_type);
                    sessionStorage.setItem("email", data.email);
                    setLoading(false)
                    setMessage("")
                    window.location.href = '/'
                    return;
                }
                else if (d.msg === "Invalid email or password!!!") {
                    setLoading(false)
                    setMessage(d.msg)
                }
                else if (d.msg === "OtpLogin" && d.is_otp_login == 1) {
                    //setLoading(false)


                    postData('verifyAndgOTP', data)
                        .then(v => {
                            if (v.msg === "success") {
                                setLoading(false)
                                setIsOtpLogin(1)
                                setMessage("")
                                setOtpMsg("An OTP was sent to your registered email address, please enter to continue securely.")
                                setSeconds(30)
                                setEnabler(true)

                                return;
                            }
                            else if (v.msg === "Incorrect Password!!!") {
                                setLoading(false)
                                setMessage(v.msg)
                            }
                            else if (v.msg === "Email address doesn\'t exists!!") {
                                setLoading(false)
                                setMessage(v.msg)
                            }
                            else {
                                setLoading(false)
                                setMessage("Network Error")
                            }

                        }).catch(e => {
                            setLoading(false)
                            setMessage("Network Error")
                        })
                }
                else {
                    setLoading(false)
                    setMessage("Network Error")
                }

            }).catch(e => {
                setLoading(false)
                setMessage("Network Error")
            })
    };

    const onSubmitOtp = (data) => {
        setLoading(true)
        postData('signin', data)

            .then(d => {
                if (d.msg === "success") {
                    sessionStorage.setItem("token", d.token);
                    sessionStorage.setItem("type", d.user_type);
                    sessionStorage.setItem("email", data.email);
                    setLoading(false)
                    setMessage("")
                    window.location.href = '/'
                    return;
                }
                else if (d.msg === "Incorrect Password!!!") {
                    setLoading(false)
                    setMessage(d.msg)
                }
                else if (d.msg === "Incorrect OTP") {
                    setLoading(false)
                    setMessage(d.msg)
                }
                else if (d.msg === "OTP Expired") {
                    setLoading(false)
                    setMessage(d.msg)
                }
                else if (d.msg === "Email address doesn\'t exists!!") {
                    setLoading(false)
                    setMessage(d.msg)
                }
                else {
                    setLoading(false)
                    setMessage("Network Error")
                }

            }).catch(e => {
                setLoading(false)
                setMessage("Network Error")
            })
    };

    const ResendOTP = () => {
        setResendCount(resendCount + 1)
        setSeconds(30);
        setLoading(true)
        postData('verifyAndgOTP', loginData)
            .then(v => {
                if (v.msg === "success") {
                    setLoading(false)
                    setIsOtpLogin(1)
                    setMessage("")
                    setOtpMsg("An OTP was sent to your registered email address, please enter to continue securely.")
                    return;
                }
                else if (v.msg === "Incorrect Password!!!") {
                    setLoading(false)
                    setMessage(v.msg)
                }
                else if (v.msg === "Email address doesn\'t exists!!") {
                    setLoading(false)
                    setMessage(v.msg)
                }
                else {
                    setLoading(false)
                    setMessage("Network Error")
                }

            }).catch(e => {
                setLoading(false)
                setMessage("Network Error")
            })
    };


    const classes = useStyles();

    return (
        <div className='loginpage'>
            <div className='login-container'>
                <div className='intellewingslogocontiner'>
                    <img alt='intellewings-logo' src={intellewings_logo1} className='logo'></img>
                    <img alt='intellewings-showcase' src={showcase} className='showcase'></img>
                </div>

                <div className='signincontainer'>
                    <div className='signin-div'>

                        <div className='signin-heading'>
                            <h2>Welcome Back</h2>
                            <p>SignIn with your AML Scan account</p>
                        </div>

                        <form onSubmit={isOtpLogin == 0 ? handleSubmit(onSubmit) : handleSubmit(onSubmitOtp)} id="form">
                            <div className='signin-input'>
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        className={classes.inputField}
                                        label="Email Address"
                                        variant="outlined"
                                        type="email"
                                        id="mui-theme-provider-outlined-input"
                                        name="email"
                                        disabled={enabler}
                                        {...register('email', { required: "Enter a valid email address" })}
                                        error={Boolean(errors.email)}
                                        helperText={errors.email?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon className='signin-icons' />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <TextField
                                        className={classes.inputField}
                                        label="Password"
                                        variant="outlined"
                                        id="mui-theme-provider-outlined-input"
                                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                                        name="password"
                                        disabled={enabler}
                                        {...register('password', { required: "Password is required" })}
                                        error={Boolean(errors.password)}
                                        helperText={errors.password?.message}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton className='signin-icons'
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {
                                        isOtpLogin == 1 ?
                                            <>
                                                <div className='otp-text'>{otpMsg}</div>
                                                <TextField
                                                    className={classes.inputField}
                                                    label="Enter OTP Sent on Registered Email"
                                                    variant="outlined"
                                                    type="text"
                                                    id="mui-theme-provider-outlined-input"
                                                    name="otp"
                                                    {...register('otp', {
                                                        required: "Enter a valid otp",
                                                        pattern: {
                                                            value: /^[0-9]{6}$/,
                                                            message: "Please enter 6 digit OTP.",

                                                        },
                                                    })}
                                                    error={Boolean(errors.otp)}
                                                    helperText={errors.otp?.message}
                                                />
                                                {(otpMsg == "An OTP was sent to your registered email address, please enter to continue securely." && seconds > 0) ?
                                                    <div className='resend-text'>Resend OTP in {seconds} seconds</div> : null
                                                }
                                                {(seconds <= 0 && resendCount <= 2) ? <p><a className='resend-otp' onClick={ResendOTP} >Resend OTP</a></p> : null}

                                            </>
                                            : null

                                    }
                                </ThemeProvider>
                                {loading ? <div style={{ textAlign: "center" }}><img alt='' src={loader} height="50px" /></div> : null}
                                <Button type='submit' class="signIN">Sign In</Button>
                            </div>
                        
                        </form>
                        <div style={{ padding: "5px" }}>{message !== "" && !loading ? <Alert severity="error">{message}</Alert> : null}</div>
                        <p><a className='signin-anchortag' href='/reset/password'>Forgot Password?</a></p>
                        <p><a className='signin-anchortag' href='/register'>Register</a>&nbsp;if you don't have an account</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin;