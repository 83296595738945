import React from "react";
import * as Icons from "react-icons/fa";
import "./navbar2.css";
import IntellewingsLogo from './../../../assets/intellewings_logo1.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import config from '../../../utility/configurations.json'

const PostNavBar = (props) => {
  const navbar_handler = ()=>{
    props.container(!props.sidebar);
  }

  const logout_handler = ()=>{
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('type');
    sessionStorage.removeItem('email');
    // window.location.href = '/signin';
    window.location.href = config.iwSSOClientUrl  //To be used to redirect to sso login page change with the actual url
  }
  return (
    <>
      <nav className="navbar text-center">
        <div className="sidebar-toggle">
          {(
            <Icons.FaBars
             color="#234E70"
              onClick={() =>navbar_handler()}
            />
          )}
        </div>
        <img alt='' src={IntellewingsLogo} className="m-0 p-0 Navbar2-Logo"></img>
        <div className='nav2-iconbuttons'>
        <Tooltip title="Currently LoggedIn User">
            <span className="loggedUser">{props.first_name.toUpperCase()}</span>
          </Tooltip>
          <Tooltip title="Cart">
            <IconButton >
              <Link to={"/cart"}><ShoppingCartOutlinedIcon className='nav2-icons' /></Link>
            </IconButton>
          </Tooltip>
          <Tooltip title="Log Out">
            <IconButton onClick={logout_handler}>
              <LogoutOutlinedIcon className='nav2-icons' />
            </IconButton>
          </Tooltip>

        </div>
      </nav>
    </>
  );
}

export default PostNavBar;