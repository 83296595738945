import { Button } from "@material-ui/core";
import PEPSanctionResult from './PEPSanction/PEPSanctionResult'
import { useState, useEffect, useRef } from "react";
import AdverseResult from "./AdverseMedia/AdverseResult";
import './resultHolder.css';
import loader from './../../../assets/load.svg'
import Wrapper from './../../shared/Wrapper'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { postData } from "../../../utility/networkCall";



const ResultHolder = (props) => {
    
    let focusRef = useRef(null)
    let resultkey =props.userInput.first_name+" "+props.userInput.middle_name+" "+props.userInput.last_name;
    resultkey = resultkey.trim().replace('  ', ' ')
   
    const sanctionResultData = props.amlcheckscanResult.data.result===undefined?[]:props.amlcheckscanResult.data.result[resultkey].sanction; 
    const pepResultData = props.amlcheckscanResult.data.result===undefined?[]:props.amlcheckscanResult.data.result[resultkey].pep; 
    const adverseMediaResultData = props.amlcheckscanResult.data.result===undefined?[]:props.amlcheckscanResult.data.result[resultkey].adversemedia; 
    
    const [screen, setscreen] = useState(0)
    const [loading, setLoading] = useState(false)

    const downloadFile = (file, name) => {
        const element = document.createElement('a');
        let val = 'data:application/pdf;base64,' + file;
        element.setAttribute('href', val);
        element.setAttribute('download', name);



        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }

    const pdfHandler = (id) => {
        if (typeof (id) === "number") {
            setLoading(true)
            postData('getScanHistoryPdfReport', { "scan_id": id })
                .then(d => {
                    if (d === "NO Scan Report Available") {
                        //setMessage("No Scan Report Available!")
                        setTimeout(() => {
                            postData('getScanHistoryPdfReport', { "scan_id": id })
                                .then(d => {
                                    if (d === "NO Scan Report Available") {
                                        //setMessage("No Scan Report Available!")
                                        // do the logic
                                    }
                                    else {
                                        downloadFile(d["pdfString"], d.firstname + ' ' + d.lastname + '.pdf')
                                        setLoading(false)
                                    }
                                    //
                                })
                                .catch(e => { setLoading(false); })
                        }, 15000)
                    }
                    else {
                        downloadFile(d["pdfString"], d.firstname + ' ' + d.lastname + '.pdf')
                    }
                    setLoading(false)
                })
                .catch(e => { setLoading(false); })
        }

    }
    useEffect(() => {
        if (true) {
            { window.scrollTo(0, focusRef.current.offsetTop - 70, 'smooth') }
        }
    }, [props.amlcheckscanResult.loading])
    const buttons = [<Button class="button-wrapper-cta" startIcon={<PictureAsPdfIcon />} onClick={() => pdfHandler(props.amlcheckscanResult.scan_id)}>Download</Button>]

    return (
        <>

            {props.children}
            {loading}
            <div className="result-holder" ref={focusRef}>
                {props.button ? <div className="button-holder">
                    <Button class="button-pink" onClick={() => { props.clear(); props.setActiveStep(0) }}>Back To Single Scan</Button>
                    <Button class="button-pink" onClick={() => { props.clear(); props.setActiveStep(props.activeStep - 1) }}>Back</Button>
                </div> : null}
                {loading ? <div style={{ textAlign: "center", lineHeight: "30px" }}><img alt='' src={loader} height={"30px"} style={{ display: "block", margin: "auto" }}></img> <span style={{ color: "#EB2188" }}>Please Wait, Downloading Report.</span></div> : null}


                {props.amlcheckscanResult.loading ? <div className="result-toggle"><img alt="" src={loader} height={"50px"} /> </div> : <Wrapper title="Scan Results" buttons={props.downloadBtn ? buttons : []}>
                    {props.userInput.adverseSelected === 1 ? <div className="result-toggle">
                        <div className={screen===0 ? "sanction-toggle active-toggle" : "sanction-toggle"} onClick={() => { setscreen(0) }}>Sanction</div>
                        <div className={screen===1 ? "sanction-toggle active-toggle" : "sanction-toggle"} onClick={() => { setscreen(1) }}>PEP</div>
                        <div className={screen===2 ? "adverse-toggle active-toggle" : "adverse-toggle"} onClick={() => { setscreen(2) }}>Adverse Media</div>
                    </div> : null}
                    <p style={{ color: "#EB2188", fontWeight: "bold" }}>
                        {props.amlcheckscanResult.message === "You have used up all your scans!" || props.amlcheckscanResult.message === "We encountered internal error, please try again after some time." || props.amlcheckscanResult.message === "Network Error : You are not connected to internet." ? props.amlcheckscanResult.message : `Search for "${props.userInput.first_name + ' ' + props.userInput.last_name}", found ${sanctionResultData.length} Sanction results, ${pepResultData.length} PEP results ${props.userInput.adverseSelected === 1 ? adverseMediaResultData.length.toString() + ' Adverse Media results.' : ''}`}
                    </p>
                    
                    
                    {screen === 0 ? <PEPSanctionResult data={sanctionResultData}></PEPSanctionResult> : screen === 1 ? <PEPSanctionResult data={pepResultData}></PEPSanctionResult> : <AdverseResult data={adverseMediaResultData}></AdverseResult>}
                    
                  
                    
                    {/* {screen ? <PEPSanctionResult data={pep}></PEPSanctionResult> : <AdverseResult adverseData={adverseData}></AdverseResult>} */}

                </Wrapper>}

            </div>

        </>
    );
};
export default ResultHolder