import React, { useEffect, useState } from "react";
import intellewings_logo1 from './../../../assets/intellewings_logo1.png';
import showcase from './../../../assets/showcase.png';
import { postData } from "../../../utility/networkCall";
import {useLocation} from 'react-router-dom'
import loader from "../../../assets/load.svg"
import './ssoLogin.css'
import config from  '../../../utility/configurations.json'

const SSOLogin = () => {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('query');
    const[loading,setLoading] = useState(false);
    const[message,setMessage] = useState("");
    useEffect(()=>{
        const data={
            token:token
        }
        setLoading(true);
        postData('ssologin',data).then(d=>{
            if (d.msg === "success") {
                sessionStorage.setItem("token", d.token);
                sessionStorage.setItem("type", d.user_type);
                sessionStorage.setItem("email", d.email);
                setLoading(false)
                setMessage("")
                window.location.href = '/'
                return;
            }else{
                setLoading(false);
                setMessage(d.msg);
            }
        }).catch(e=>{
            setLoading(false);
            setMessage("Network Error");
        })
    },[])


    const goBackToLoginPage =()=>{
        window.location.href=config.iwSSOClientUrl;
    }
  return (
    <div className="loginpage">
      <div className="login-container">
        <div className="intellewingslogocontiner">
          <img
            alt="intellewings-logo"
            src={intellewings_logo1}
            className="logo"
          ></img>
          <img
            alt="intellewings-showcase"
            src={showcase}
            className="showcase"
          ></img>
        </div>

        <div className="signincontainer" style={{marginTop:"20%"}}>
          <div className="signin-div">
            <div className="signin-heading">
              <h2 style={{color:"black"}}>Welcome </h2>
              {loading &&
              <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                <img alt='' src={loader} height="50px" />
                <p className="loading-message">
                    Please wait for just a moment, While we are logging you in.
                </p>
            </div>
              }

              {message && 
              <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                <p className="error-message">{message}</p>
                <button onClick={()=>goBackToLoginPage()} className="signIN sgn-btn">Go back to Login Page</button>
                </div>

              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSOLogin;
