import React, { useState, useEffect } from 'react';
import './batchHistory.css';
import HistoryFilter from '../../shared/historyfilter/HistoryFilter';
import HistoryTable from '../../shared/results/HistoryTable/HistoryTable';
import { postData } from '../../../utility/networkCall';
import loading_svg from './../../../assets/load.svg'




const BatchHistory = () => {
    const [orders, setOrders] = useState([])
    const [loading, setloading] = useState(false)
    const [userConfig, setUserConfig] = useState({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false, listType: 'All' })

    const configHandler = (config) => {
        let temporary = { "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false }

        config.endDate !== undefined ? temporary.endDateSelected = true : temporary.endDateSelected = false
        if (config.startDate !== undefined) {
            temporary.startDateSelected = true
            temporary.startDate = new Date(config.startDate).toISOString()
        }
        else {
            temporary.startDateSelected = false
        }

        if (config.endDate !== undefined) {
            temporary.endDateSelected = true
            temporary.endDate = new Date(config.endDate).toISOString()
        }
        else {
            temporary.endDateSelected = false
        }
        temporary.listType = config.listType
        temporary.scanTypeSelected = "batch scan"

        setUserConfig(temporary)
    }

    const clearHandler = () => {
        setUserConfig({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false,"listType":"All" })
    }
    const headCells = [
        {
            id: 'requestTimeStamp',
            numeric: false,
            disablePadding: false,
            label: 'Date',
        },
        {
            id: 'firstname',
            numeric: false,
            disablePadding: false,
            label: 'Scanned Name',
        },
        {
            id: 'searchInList',
            numeric: false,
            disablePadding: false,
            label: 'List Type',
        },
        // {
        //     id: 'adverseSelected',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Adverse Media',
        // },
        {
            id: 'searchLevel',
            numeric: false,
            disablePadding: false,
            label: 'Search Operation',
        },
    ];



    useEffect(() => {
        setloading(true)
        postData('history', { "email": sessionStorage.getItem("email"), "scanTypeSelected": "batch scan", ...userConfig })
            .then(data => { setOrders(data) 
                setloading(false)})
            .catch(err => { console.log(err) 
                setloading(false)})
    }, [userConfig])
    return (
        <React.Fragment>

            <div className='bh-container'>
                <HistoryFilter title='Filters' listTypeNeeded={true} configHandler={configHandler} clearHandler={clearHandler}/>
                {loading ? <div style={{textAlign:"center"}}>{<img alt=''height={"50px"} style={{background:"transparent", padding:"5px"}} src={loading_svg}/>}</div>:null}
                {orders.length === 0 && !loading ? <div style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}><span style={{ color: "#EB2188", padding: "30px", fontWeight: "900" }}>No Scan History Found!</span></div> : <HistoryTable data={orders} headCells={headCells} orderBy={'requestTimeStamp'} wrapper={true} title="History"/>}
            </div>
        </React.Fragment>
    )
}

export default BatchHistory;