import React, { useEffect, useState } from 'react';
import './orderHistory.css';
import HistoryFilter from '../../shared/historyfilter/HistoryFilter';
import HistoryTable from './../../shared/results/HistoryTable/HistoryTable'
import { postData } from './../../../utility/networkCall'
import loading_svg from './../../../assets/load.svg'


const OrderHistory = (props) => {
    const [orders, setOrders] = useState([])
    const [userConfig, setUserConfig] = useState({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false })
    const [loading, setloading] = useState(false)
    const [message, setMessage] = useState("No Order History Found!")

    const configHandler = (config) => {
        let temporary = { "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false }
         
        config.endDate !== undefined ? temporary.endDateSelected = true : temporary.endDateSelected = false
        if(config.startDate !== undefined){
            temporary.startDateSelected = true
            temporary.startDate = new Date(config.startDate).toISOString()
        }
        else{
            temporary.startDateSelected = false
        }

        if(config.endDate !== undefined){
            temporary.endDateSelected = true
            temporary.endDate = new Date(config.endDate).toISOString()
        }
        else{
            temporary.endDateSelected = false
        }
        
        setUserConfig(temporary)
    }

    const clearHandler = () => {
        setUserConfig({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false })
    }
    const headCells = [
        {
            id: 'order_date',
            numeric: false,
            disablePadding: false,
            label: 'Order Date',
        },
        {
            id: 'scans',
            numeric: false,
            disablePadding: false,
            label: 'Total Scans',
        },
        {
            id: 'price',
            numeric: false,
            disablePadding: false,
            label: 'Price',
        },
        {
            id: 'payment_id',
            numeric: false,
            disablePadding: false,
            label: 'Payment ID',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
    ];

    

    useEffect(() => {
        setloading(true)
        postData('orderHistory', { "email": sessionStorage.getItem("email"), ...userConfig })
            .then(data => { setOrders(data)
                setloading(false)
            })
            .catch(err => {
                 setMessage("Error Message")
                 setloading(false)
                })
    }, [userConfig])
    return (
        <React.Fragment>
            <div className="order-history-container">
                <HistoryFilter title='Filters' listTypeNeeded={false} configHandler={configHandler} clearHandler={clearHandler}/>
                {loading ? <div style={{textAlign:"center"}}>{<img alt=''height={"50px"} style={{background:"transparent", padding:"5px"}} src={loading_svg}/>}</div>:null}
                {orders.length === 0 && !loading ? <div style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}><span style={{ color: "#EB2188", padding: "30px", fontWeight: "900" }}>{message}</span></div> : <HistoryTable data={orders} headCells={headCells} wrapper={true} title={"Order History"} orderBy={'order_date'}/>}
            </div>

        </React.Fragment>
    )
}

export default OrderHistory;