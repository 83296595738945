import React, { useState } from 'react';
import './contact.css'
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import {
    TextField,
    Button,
    
} from "@material-ui/core";
import { pink } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider, createTheme, } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Alert from '@mui/material/Alert';
import loader from './../../../assets/load.svg';
import { postData} from './../../../utility/networkCall';


const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "95%",
        margin: theme.spacing(1.5, 0),
    },
}));

const theme = createTheme({
    palette: {
        primary: pink,
    },
});

const ContactUs = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const classes = useStyles();
    const [message, setMessage] = useState("")
    const [ loading, setLoading] = useState(false)
    const onSubmit = (data) => {
        setLoading(true)
        postData('contact', data)
        .then(d=>{
            console.log(d)
            if(d === "success"){
                setLoading(false)
                setMessage("Message sent successfully, we'll get back to you soon.")
                // window.location.href='/signin'
                // return;
            }
            else{
                setLoading(false)
                setMessage("Network Error")
            }
            
        }).catch(e=>{setLoading(false)
            setMessage("Network Error")})
    };
    
    return (
        <React.Fragment>
            <div class='contactus-text-div'>
                <p className='contactus-text'><h1 className='contactus-heading'>Contact Us</h1>
                    Get in touch with us and let us know how can we help you</p>
            </div>
            
            <div className='contactus-container'>
                <div className='contactus-div'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='contactus-input'>
                        <ThemeProvider theme={theme}>
                            <TextField
                                className={classes.inputField}
                                label="Name"
                                variant="outlined"
                                id="mui-theme-provider-outlined-input"
                                name="name"
                                {...register('name', { required: "Name can't be empty" })}
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                            />
                            <TextField
                                className={classes.inputField}
                                label="Email"
                                variant="outlined"
                                id="mui-theme-provider-outlined-input"
                                name="email"
                                {...register('email', { required: "Email address is required",
                                pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: "Please enter a valid e-mail address."
                                  } })}
                                error={Boolean(errors.email)}
                                defaultValue={sessionStorage.getItem('email')}
                                helperText={errors.email?.message}

                            />
                            <TextField
                                className={classes.inputField}
                                label="Subject"
                                variant="outlined"
                                id="mui-theme-provider-outlined-input"
                                name="subject"
                                {...register('subject', { required: "Subject can't be empty" })}
                                error={Boolean(errors.subject)}
                                helperText={errors.subject?.message}

                            />
                            <TextField
                                className={classes.inputField}
                                label="Message"
                                variant="outlined"
                                id="mui-theme-provider-outlined-input"
                                name="message"
                                {...register('message', { required: "Message can't be empty" })}
                                error={Boolean(errors.message)}
                                helperText={errors.message?.message}

                            />
                        </ThemeProvider>
                    </div>
                    
                    <div style={{padding:"5px",textAlign:"center"}}>{message!=="" && !loading ? <Alert  severity={message==="Network Error"?"error":"success"}>{message}</Alert>:null}</div>
                    {loading?<div style={{textAlign:"center" ,paddingBottom:"5px"}}><img alt='' src={loader} height="50px" /></div>:null}
                    <div className='contactus-button-div'><Button type='submit' class="contactus-button">Send</Button></div>
                    </form> 
                </div>
                
                <div className='contactus-div2'>
                    <p className='contactus-div2-text'><span className='contactus-div2-text-span1'>Connect With Us</span></p>
                    <p className='contactus-div2-text'><span className='contactus-div2-text-span2'>Contact Person:</span> Aarti Ghodke</p>
                    <p className='contactus-div2-text'><CallIcon className='contactus-icons' />&nbsp;&nbsp;&nbsp;<span className='contactus-div2-text-span3'>+91-8600142193</span></p>
                    <p className='contactus-div2-text'><EmailIcon className='contactus-icons' />&nbsp;&nbsp;&nbsp;<span className='contactus-div2-text-span3'>arati@intellewings.com</span></p>

                </div>
            </div>
        
        </React.Fragment>
    )
}

export default ContactUs;