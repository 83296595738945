import React from 'react';
import './scanConfiguration.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import { Button, Card } from '@mui/material';
import Switch from '@mui/material/Switch';
import Toggle from './Toggle'
import OperationCard from './OperationCard';
import FormControlLabel from '@mui/material/FormControlLabel';


const ScanConfiguration = (props) => {
    var scanType=props.scanType;
    //props.handleInput({scan_state:"not ready"})
    var isOrganisation=props.inputData.entity==="Organization"?true:false;
    const [filterState, setFilterState] = React.useState(props.inputData.filters)
    const [selectedValue, setSelectedValue] = React.useState("2");
    const [expandedAcc, setExpandedAcc] = React.useState("2");
    const [currentConfiguration, setCurrentConfiguration] = React.useState({
        "search_option": selectedValue,
        "0":
        {
            fullnameselected:true,
            firstnameselected:props.inputData.first_name===""?false:true,
            middlenameselected:props.inputData.middle_name===""?false:true,
            lastnameselected:props.inputData.last_name===""?false:true,
            identifierselected:props.inputData.identifier.length===0?false:true,
            dobselected:props.inputData.dob===""?false:true,
            full_name_range:75,
            first_name_range: 75,
            middle_name_range: 75,
            last_name_range: 75,
            dob_range: 75,
            identifier_range: 75,
            logicalSelected: [0, 0, 0, 0, 0],
        },
        "1": {
            fullnameselected:true,
            firstnameselected:props.inputData.first_name===""?false:true,
            middlenameselected:props.inputData.middle_name===""?false:true,
            lastnameselected:props.inputData.last_name===""?false:true,
            identifierselected:props.inputData.identifier.length===0?false:true,
            dobselected:props.inputData.dob===""?false:true,
            full_name_range:75,
            first_name_range: 75,
            middle_name_range: 75,
            last_name_range: 75,
            dob_range: 75,
            identifier_range: 75,
            logicalSelected: [1, 1, 1, 1, 1],
        },
        "2": {
            fullnameselected:true,
            firstnameselected:props.inputData.first_name===""?false:true,
            middlenameselected:props.inputData.middle_name===""?false:true,
            lastnameselected:props.inputData.last_name===""?false:true,
            identifierselected:props.inputData.identifier.length===0?false:true,
            dobselected:props.inputData.dob===""?false:true,
            full_name_range:75,
            first_name_range: 75,
            middle_name_range: 75,
            last_name_range: 75,
            dob_range: 75,
            identifier_range: 75,
            logicalSelected: [0, 0, 0, 0, 0],
        }
    })

    const handleAND = (id, value,ID,IDValue) => {
        let dataObj = {}
        dataObj[id] = value
        dataObj[ID]=IDValue

        setCurrentConfiguration({ ...currentConfiguration, "0": { ...currentConfiguration["0"], ...dataObj } })
    }
    const handleOR = (id, value,ID,IDValue) => {
        let dataObj = {}
        dataObj[id] = value
        dataObj[ID]=IDValue
        setCurrentConfiguration({ ...currentConfiguration, "1": { ...currentConfiguration["1"], ...dataObj } })
    }
    const handleAdvance = (id, value,ID,IDValue) => {
        let dataObj = {}
        dataObj[id] = value
        dataObj[ID]=IDValue
        setCurrentConfiguration({ ...currentConfiguration, "2": { ...currentConfiguration["2"], ...dataObj } })
    }
    const handleLogicalSelected = (id, value) => {
        let intermediate_array = currentConfiguration["2"].logicalSelected
        intermediate_array[id] = value
        let dataObj = { logicalSelected: intermediate_array }
        setCurrentConfiguration({ ...currentConfiguration, "2": { ...currentConfiguration["2"], ...dataObj } })
    }

    const handleChange = (val) => {
        setSelectedValue(val);
        setCurrentConfiguration({ ...currentConfiguration, "search_option": val });
    };
    const nextHandler = () => {
        var notReadyToScan=currentConfiguration[selectedValue].firstnameselected==false && currentConfiguration[selectedValue].lastnameselected==false&& currentConfiguration[selectedValue].fullnameselected==false;
        props.handleInput({
            search_option: parseInt(currentConfiguration.search_option),
            logicalSelected: currentConfiguration[selectedValue].logicalSelected,
            scan_state: notReadyToScan? "not ready":"ready",
            full_name_range:parseInt(currentConfiguration[selectedValue].full_name_range),
            first_name_range: parseInt(currentConfiguration[selectedValue].first_name_range),
            middle_name_range: parseInt(currentConfiguration[selectedValue].middle_name_range),
            last_name_range: parseInt(currentConfiguration[selectedValue].last_name_range),
            dob_range: parseInt(currentConfiguration[selectedValue].dob_range),
            identifier_range: parseInt(currentConfiguration[selectedValue].identifier_range),
            fullnameselected:(currentConfiguration[selectedValue].fullnameselected),
            firstnameselected:(currentConfiguration[selectedValue].firstnameselected),
            middlenameselected:(currentConfiguration[selectedValue].middlenameselected),
            lastnameselected:(currentConfiguration[selectedValue].lastnameselected),
            identifierselected:(currentConfiguration[selectedValue].identifierselected),
            dobselected:(currentConfiguration[selectedValue].dobselected),
            filters: filterState
        })
        //console.log(currentConfiguration[selectedValue],"8888")
        if (currentConfiguration[selectedValue].firstnameselected==false && currentConfiguration[selectedValue].lastnameselected==false&& currentConfiguration[selectedValue].fullnameselected==false ) {
            props.setActiveStep(2)
        }
        else{
            props.setActiveStep(props.activeStep + 1)
        }
        
    }

    const backHandler = () => {
        if (props.userInput === "PEP") {
            props.setActiveStep(0)
        }
        else {
            props.setActiveStep(props.activeStep - 1)
        }
    }

    const zeroHandler = () => {
        props.setActiveStep(0)
    }

    const SearchOption = (props) => {
        const searchType = props.searchType;
        return (
            <div className='sc-circle-option'>
                <div className='sc-circle'>+</div>
                <div>{searchType}</div>
            </div>


        );
    }

    const item1 = 'List Of Names';
    const item2 = 'List of Alias Names';
    const item3 = 'Phonectics Included';
    const item4 = 'Translation to english include';
    const item5 = 'Date of Birth List'

    const field_to_field = { inputProps: { 'aria-label': 'fieldToField'} };
    const nullable = { inputProps: { 'aria-label': 'nullable'}};
    const wikipedia = { inputProps: { 'aria-label': 'wikipedia'} };
    const nationality = { inputProps: { 'aria-label': 'nationality' } };
    const foreignPEP = {inputProps : {'aria-label':'foreignPEP'}};
    const anyordermatch = {inputProps :{'aria-label':'anyordermatch'}}

    const handleFilters= (e)=>{
        let action = {}
        action[e.target.attributes['aria-label'].nodeValue] = e.target.checked
        setFilterState({...filterState, ...action})
    }
    return (
        <div>
            <Card className='filter-holder'>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.fieldToField} {...field_to_field} />} label="Field To Field" onClick={handleFilters} labelPlacement="start"/>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.nullable} {...nullable} />} label="Nullable" onClick={handleFilters} labelPlacement="start"/>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.wikipedia} {...wikipedia} />} label="Wikipedia" onClick={handleFilters} labelPlacement="start"/>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.nationality} {...nationality} />} label="Nationality" onClick={handleFilters} labelPlacement="start"/>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.foreignPEP} {...foreignPEP} />} label="ForeignPEP" onClick={handleFilters} labelPlacement="start"/>
                <FormControlLabel className='filter-item' control={<Switch defaultChecked={props.inputData.filters.anyordermatch} {...anyordermatch} />} label="Any Order Match" onClick={handleFilters} labelPlacement="start"/>
            </Card>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAcc === "0" ? true : false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        handleChange(0)
                        setSelectedValue("0")
                        expandedAcc === "0" ? setExpandedAcc("") : setExpandedAcc("0")

                    }}
                >
                    <Typography><Radio
                        checked={selectedValue === "0"}
                        onChange={() => { handleChange(0); setSelectedValue("0") }}
                        value="0"
                        name="AND"
                        inputProps={{ 'aria-label': 'AND' }}
                    />Option 1 : Basic Search With AND Operation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="conf-holder">
                       {<> <OperationCard listitems={[item1, item2, item3, item4]} title={"Full Name"} id={"full_name_range"} ID={"fullnameselected"} inputHandler={handleAND} checkboxSelector={true} scanType={scanType}/><SearchOption searchType="AND"  /></>}
                       {!isOrganisation && <> <OperationCard listitems={[item1, item2, item3, item4]} title={"First Name"} id={"first_name_range"} ID={"firstnameselected"} inputHandler={handleAND} checkboxSelector={props.inputData.first_name===""?false:true} scanType={scanType}/><SearchOption searchType="AND"  /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Middle Name"} id={"middle_name_range"} ID={"middlenameselected"} inputHandler={handleAND} checkboxSelector={props.inputData.middle_name===""?false:true} scanType={scanType}/><SearchOption searchType="AND" /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Last Name"} id={"last_name_range"} ID={"lastnameselected"} inputHandler={handleAND} checkboxSelector={props.inputData.last_name===""?false:true} scanType={scanType}/><SearchOption searchType="AND" /></>}
                        <OperationCard listitems={[item1, item2, item3, item4]} title={"Identifier"} id={"identifier_range"} ID={"identifierselected"} inputHandler={handleAND} checkboxSelector={props.inputData.identifier.length===0?false:true} scanType={scanType}/>{!isOrganisation &&<SearchOption searchType="AND" />}
                        {!isOrganisation && <><OperationCard listitems={[item5]} title={"DOB"} id={"dob_range"} ID={"dobselected"} inputHandler={handleAND} checkboxSelector={props.inputData.dob===""?false:true} scanType={scanType}/></>}
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAcc === "1" ? true : false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    value="1"
                    onClick={() => {
                        handleChange(1)
                        setSelectedValue("1")
                        expandedAcc === "1" ? setExpandedAcc("") : setExpandedAcc("1")

                    }}
                >
                    <Typography><Radio
                        checked={selectedValue === "1"}
                        onChange={() => { handleChange(1); setSelectedValue("1") }}
                        value="1"
                        name="OR"
                        inputProps={{ 'aria-label': 'AND' }}
                    />Option 2 : Basic Search With OR Operation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="conf-holder">
                        <OperationCard listitems={[item1, item2, item3, item4]} title={"Full Name"} id={"full_name_range"} ID={"fullnameselected"} inputHandler={handleOR} checkboxSelector={true} scanType={scanType}/><SearchOption searchType="OR" />
                        {!isOrganisation && <> <OperationCard listitems={[item1, item2, item3, item4]} title={"First Name"} id={"first_name_range"} ID={"firstnameselected"} inputHandler={handleOR} checkboxSelector={props.inputData.first_name===""?false:true} scanType={scanType}/><SearchOption searchType="OR" /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Middle Name"} id={"middle_name_range"} ID={"middlenameselected"} inputHandler={handleOR} checkboxSelector={props.inputData.middle_name===""?false:true} scanType={scanType}/><SearchOption searchType="OR" /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Last Name"} id={"last_name_range"} ID={"lastnameselected"} inputHandler={handleOR} checkboxSelector={props.inputData.last_name===""?false:true} scanType={scanType}/><SearchOption searchType="OR" /></>}
                        <OperationCard listitems={[item1, item2, item3, item4]} title={"Identifier"} id={"identifier_range"} ID={"identifierselected"} inputHandler={handleOR} checkboxSelector={props.inputData.identifier.length===0?false:true} scanType={scanType}/>{!isOrganisation &&<SearchOption searchType="OR" />}
                        {!isOrganisation && <><OperationCard listitems={[item5]} title={"DOB"} id={"dob_range"} ID={"dobselected"} inputHandler={handleOR} checkboxSelector={props.inputData.dob===""?false:true} scanType={scanType}/></>}

                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expandedAcc === "2" ? true : false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        handleChange(2)
                        setSelectedValue("2")
                        expandedAcc === "2" ? setExpandedAcc("") : setExpandedAcc("2")
                    }}
                >
                    <Typography><Radio
                        checked={selectedValue === "2"}
                        onChange={() => { handleChange(2); setSelectedValue("2") }}
                        value="2"
                        name="Advance"
                        inputProps={{ 'aria-label': 'AND' }}
                    />Option 3 : Advance Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="conf-holder">
                        <OperationCard listitems={[item1, item2, item3, item4]} title={"Full Name"} ID={"fullnameselected"} inputHandler={handleAdvance} id={"full_name_range"} checkboxSelector={true} scanType={scanType}/><Toggle identity={0} inputHandler={handleLogicalSelected} />
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"First Name"} ID={"firstnameselected"} inputHandler={handleAdvance} id={"first_name_range"} checkboxSelector={props.inputData.first_name===""?false:true} scanType={scanType}/><Toggle identity={1} inputHandler={handleLogicalSelected} /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Middle Name"} ID={"middlenameselected"} inputHandler={handleAdvance} id={"middle_name_range"} checkboxSelector={props.inputData.middle_name===""?false:true} scanType={scanType}/><Toggle identity={2} inputHandler={handleLogicalSelected} /></>}
                        {!isOrganisation && <><OperationCard listitems={[item1, item2, item3, item4]} title={"Last Name"} ID={"lastnameselected"} inputHandler={handleAdvance} id={"last_name_range"} checkboxSelector={props.inputData.last_name===""?false:true} scanType={scanType}/><Toggle identity={3} inputHandler={handleLogicalSelected} /></>}
                        <OperationCard listitems={[item1, item2, item3, item4]} title={"Identifier"} ID={"identifierselected"} inputHandler={handleAdvance} id={"identifier_range"} checkboxSelector={props.inputData.identifier.length===0?false:true} scanType={scanType}/>{!isOrganisation && <Toggle identity={4} inputHandler={handleLogicalSelected} />}
                        {!isOrganisation && <><OperationCard listitems={[item5]} title={"DOB"} id={"dob_range"} ID={"dobselected"} inputHandler={handleAdvance} checkboxSelector={props.inputData.dob===""?false:true} scanType={scanType} /></>}
                    </div>
                </AccordionDetails>
            </Accordion>
{currentConfiguration[selectedValue].firstnameselected==false && currentConfiguration[selectedValue].lastnameselected==false&& currentConfiguration[selectedValue].fullnameselected==false ?
<div>
    <p style={{ color: "#EB2188", padding: "5px", textAlign: "center" }}>Plese include atleast one from Full name, First nama or Last name to continue. </p>
</div>
:null}
            <div className="button-holder">
                {props.flag !== 'kyc' && <Button onClick={zeroHandler} class="button-pink">Back To {props.scanType} Scan</Button>}
                {props.flag !== 'kyc' && <Button onClick={backHandler} class="button-pink">Back</Button>}
                <Button onClick={nextHandler} class="button-blue">Check</Button>
            </div>
        </div>
    )
}

export default ScanConfiguration