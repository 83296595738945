import React from 'react';
import PriceTable from './../../shared/pricing/PriceTable';
import config from './../../../utility/configurations.json';
import MiniFeatureCard from '../../shared/featuresCard/MiniFeatureCard';
import './../../shared/pricing/pricing.css'
const Pricing = (props) => {
    return (
        <React.Fragment>
            <MiniFeatureCard/>
           <PriceTable price={config.pricing} cartOperation={props.cartOperation} carting={true}></PriceTable>
       </React.Fragment>
     )
 }

 export default Pricing;