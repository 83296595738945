import React from 'react';
import './miniFeatureCard.css'
import config from './../../../utility/configurations.json';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const feature = ["Global PEP Database", "Global Sanction Database", "API Integration", "API History", "Batch Scanning", "Batch History", "Adverse Media", "Scan History & Reporting"]
const MiniFeatureCard = (props) => {
    return (
        <>
            <div className='price-headings'>
                <h2>Pricing that scales to fit your needs </h2>
                <p>Our pricing scales to fit your needs with each package includes</p>



                <div className='chipHolder'>
                    <div className='feature-chip'>
                        {

                            feature.map((d) => (
                                <div className='chip'>
                                <Chip label={d} />
                                </div>
                            ))

                        }
                        
                    </div>
                </div>
            </div>



        </>
    )
}
export default MiniFeatureCard;