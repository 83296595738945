import React from "react";
import './postlogincontainer.css'
import {getData, postData} from './../../utility/networkCall';



//temp imports
import Dashboard from './Dashboard/DashboardScreen';
import Setting from './settings/Settings';
import SettingsPage from './SettingsPage/SettingsPage';
import ScanHistory from './scanHistory/ScanHistory';
import BatchHistory from './batchHistory/BatchHistory';

import SingleScan from './singleScan/SingleScan';
import NameScan from './nameScan/NameScan'
import BatchScan from './batchScan/BatchScan';
import API from './api/Api';
import OrderHistory from './orderHistory/OrderHistory';
import Pricing from './pricing/Pricing';
import PostNavBar from './../shared/navbar/PostNavBar';
import SideBar from './../shared/navbar/SideBar';
//import Cart from './../shared/cart/cartDetails'
import conf from './../../utility/configurations.json'
import CartDetails from './cart/Cart'
import Contact from './contact/Contact'



import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
const config = require('./../../utility/configurations.json');

class PostLoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar: window.innerWidth>800?true:false,
            container: "sidebar-item-a-span",
            email:"",
            first_name:"",
            last_name:"",
            user_type:"",
            country:"",
            industry:"",
            organization:"",
            contact:"",
            is_otp_login:0,
            userData:[{checks_left:0}],
            auto_refresh : 0,
            cart : [[0,0,0,0],conf.pricing],
            current_screen : window.location.pathname,
            mode:""

        }
        
        this.setContainer = this.setContainer.bind(this);
        this.cartOperation = this.cartOperation.bind(this);
        this.deleteItemFromCart = this.deleteItemFromCart.bind(this)
        this.changeCurrentScreen = this.changeCurrentScreen.bind(this)
        this.updateUserData = this.updateUserData.bind(this)
    }
    updateUserData(data){
        this.setState({...data})       
    }

    deleteItemFromCart(id){
        let temp_state = this.state.cart
        temp_state[0][id] = 0
        this.setState({cart:temp_state})
    }

    cartOperation(type,pack){
        let temp_state = this.state.cart
        if (type) {
            let new_value = this.state.cart[0][pack]+1
            temp_state[0][pack] = new_value
            this.setState({cart:temp_state})
        }
        else{
            let new_value = this.state.cart[0][pack]-1
            temp_state[0][pack] = new_value
            this.setState({cart:temp_state})
        }
    }

    changeCurrentScreen(name){
        this.setState({current_screen:name})
    }

    componentDidMount(){
        postData('getUserDetails',{email:sessionStorage.getItem("email")}).then(d=>{
            this.setState({email:d.email, first_name:d.first_name, last_name:d.last_name, organization:d.org_name, industry:d.industry_type, country:d.country, contact:d.contact, logo:d.logo,is_otp_login:d.is_otp_login})
            postData('getUserScanDetails',{email:this.state.email}).then(d=>{
                this.setState({userData:d})
                getData('mode')
                .then(d=>{
                    this.setState({mode:d})
                })
                .catch(e=>{console.log(e)})
            })
            .catch(e=>{console.log(e)})
        })
        .catch(e=>{console.log(e)})
    }

    setContainer(val) {
        this.setState({ sidebar: val })
    }

    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <PostNavBar sidebar={this.state.sidebar} container={this.setContainer} first_name={this.state.first_name}/>
                    <SideBar container={this.state.container} setContainer={this.setContainer} sidebar={this.state.sidebar} current_screen={this.state.current_screen} />
                    <div className={this.state.sidebar ? "main-sidebar-active" : "main"}>
                        {this.state.mode === "beta"?<p style={{fontWeight:"bold", marginLeft:"10px"}}>Running in {this.state.mode} mode.</p>:null}
                        <Switch>
                            <Route exact path="/pricing">
                                <Pricing cartOperation={this.cartOperation}></Pricing>
                            </Route>
                            <Route exact path="/contact" ><Contact/></Route>
                            <Route exact path="/namescan">
                                <NameScan {...this.state} creditUpdate={this.updateUserData}>
                                <div className="checks-left" >Credit Balance : <span style={{fontWeight:900}}>{this.state.userData[0].checks_left}</span></div>
                                </NameScan>
                            </Route>
                            <Route exact path="/singlescan">
                                <SingleScan {...this.state} creditUpdate={this.updateUserData}>
                                    <div className="checks-left">Credit Balance : <span style={{fontWeight:900}}>{this.state.userData[0].checks_left}</span></div>
                                </SingleScan>
                            </Route>
                            <Route exact path="/batchscan">
                                <BatchScan creditUpdate={this.updateUserData}>
                                <div className="checks-left">Credit Balance : <span style={{fontWeight:900}}>{this.state.userData[0].checks_left}</span></div>
                                </BatchScan>
                            </Route>
                            <Route exact path="/scanhistory/single" component={ScanHistory}></Route>
                            <Route exact path="/scanhistory/batch" component={BatchHistory}></Route>
                            <Route exact path="/orderhistory">
                                <OrderHistory></OrderHistory>
                            </Route>
                            <Route exact path="/api">
                                <API userData={this.state.userData} updateUserData={this.updateUserData}></API>
                            </Route>
                            <Route exact path="/settings">
                                <SettingsPage dataUpdateHandler={this.updateUserData} data={this.state} first_name={this.state.first_name} last_name={this.state.last_name} organization={this.state.organization} industry={this.state.industry} contact={this.state.contact} country={this.state.country} logo={this.state.logo} is_otp_login={this.state.is_otp_login}/>
                            </Route>
                            <Route exact path="/cart">
                                <CartDetails cart={this.state.cart} cartOperation = {this.cartOperation} deleteItemFromCart={this.deleteItemFromCart} mode={this.state.mode}></CartDetails>
                            </Route>
                            <Route path="/">
                                <Dashboard changelocation={this.changeCurrentScreen} userdetails={{email:this.state.email, first_name:this.state.first_name, last_name:this.state.last_name}} userData={this.state.userData} ><div className="checks-left">Credit Balance : <span style={{fontWeight:900}}>{this.state.userData[0].checks_left}</span></div></Dashboard>
                            </Route>
                        </Switch>
                    </div>
                </BrowserRouter>
            </React.Fragment>
        )
    }
}

export default PostLoginContainer;