import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";

import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ApiIcon from '@mui/icons-material/Api';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChatIcon from '@mui/icons-material/Chat';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Tooltip } from "@material-ui/core";
import HomeIcon from '@mui/icons-material/Home';
import ScannerIcon from '@mui/icons-material/Scanner';
import HistoryIcon from '@mui/icons-material/History';
import Search from '@mui/icons-material/Search'


const SideBar = (props) => {

    const [active, setActive] = useState(props.current_screen)

    const handle_Active = (id) => {
        setActive(id)
        if(window.innerWidth<700){
        props.setContainer(!props.sidebar);}
    }

    const navItems = [
        {
            id: 1,
            title: "Dashboard",
            path: "/",
            sName: `sidebar-item ${active === "/" ? "sidebar-item-active" : ""}`,

            icon: <Tooltip title='Dashboard'><HomeIcon /></Tooltip>,
        },
        {
            id: 2,
            title: "Name Scan",
            path: "/namescan",
            sName: `sidebar-item ${active === "/namescan" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Name Scan'><Search /></Tooltip>,
        },
        {
            id: 3,
            title: "Single Scan",
            path: "/singlescan",
            sName: `sidebar-item ${active === "/singlescan" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Single Scan'><ScannerIcon /></Tooltip>,
        },
        {
            id: 4,
            title: "Single Scan History",
            path: "/scanhistory/single",
            sName: `sidebar-item ${active === "/scanhistory/single" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Single Scan History'><HistoryIcon /></Tooltip>,
        },
        {
            id: 5,
            title: "Batch Scan",
            path: "/batchscan",
            sName: `sidebar-item ${active === "/batchscan" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Batch Scan'><DocumentScannerIcon /></Tooltip>,
        },
        {
            id: 6,
            title: "Batch Scan History",
            path: "/scanhistory/batch",
            sName: `sidebar-item ${active === "/scanhistory/batch" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Batch Scan History'><HistoryEduIcon /></Tooltip>,
        },
        {
            id: 7,
            title: "API",
            path: "/api",
            sName: `sidebar-item ${active === "/api" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='API'><ApiIcon /></Tooltip>,
        },
        {
            id: 8,
            title: "Upgrade Plan",
            path: "/pricing",
            sName: `sidebar-item ${active === "/pricing" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Upgrade Plan'><AttachMoneyIcon /></Tooltip>,
        },
        {
            id: 9,
            title: "Order History",
            path: "/orderhistory",
            sName: `sidebar-item ${active === "/orderhistory" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Order History'><ChatIcon /></Tooltip>,
        },
        {
            id: 10,
            title: "Settings",
            path: "/settings",
            sName: `sidebar-item ${active === "/settings" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Settings'><SettingsIcon /></Tooltip>,
        },
        {
            id: 11,
            title: "Contact Us",
            path: "/contact",
            sName: `sidebar-item ${active === "/contact" ? "sidebar-item-active" : ""}`,
            icon: <Tooltip title='Contact Us'><ContactPhoneIcon /></Tooltip>,
        },
    ];
    return (
        <>
            <div className={props.sidebar ? "sidebar active" : "sidebar"}>
                
                <div className="sidebar-content">
                <ul className="sidebar-items">
                    {navItems.map((item) => {
                        return (
                            <li
                                key={item.id}
                                id={item.id}
                                className={item.sName}
                                // onClick={navbar_handler}
                                onClick={(e) =>{ handle_Active(item.path)}}
                            >
                                <Link to={item.path} >
                                    {item.icon}
                                    <span className={props.container}>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                </div>
            </div>
        </>
    );
}

export default SideBar;