import React, { useState } from 'react';
import './PEPSanction.css';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper, Box, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AltUserImage from './../../../../assets/user-alt.png'



const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1),
    // width: theme.spacing("100%"),
    // height: theme.spacing('auto')
  },
  paper2: {
    margin: theme.spacing(0),
    // width: theme.spacing("100%"),
    // height: theme.spacing('auto')
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    // height:'50px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
      margin: 'auto',
    },
    '&$expanded': {
      // margin: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const Accordion2 = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: '2px 2px 5px #888888;',
    // height:'50px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
      margin: 'auto',
    },
    '&$expanded': {
      // margin: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      // marginTop:'20px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#234E70',
    borderBottom: '1px solid #12738E',
    marginBottom: -1,
    color: 'white',
    fontWeight: 'bold',
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionSummary2 = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid white',
    marginBottom: -1,
    color: '#234E70',
    fontWeight: 'bold',
    overflow: 'auto',
    minHeight: 90,
    '&$expanded': {
      minHeight: 90,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);


const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    flexWrap: 'wrap',
  },
}))(MuiAccordionDetails);



const PEPSanctionResult = (props) => {

  const resultData=props.data.sort((a, b) => {
    if (a.fullNameScore === null && b.fullNameScore === null) {
      return 0;
    } else if (a.fullNameScore === null) {
      return 1;
    } else if (b.fullNameScore === null) {
      return -1;
    } else {
      return b.fullNameScore - a.fullNameScore;
    }
  });
  const classes = useStyles();
  const [summaryExpanded, setSummaryExpanded] = useState(true)
  const handleSummaryExpand = () => {
    setSummaryExpanded(!summaryExpanded)
  }
 
  return (
    <>

      {resultData && resultData.length===0 && <div className='not-found'>Result not found in {props.resultType===1 ?'Sanctions\'':'Pep\'s'} list check other lists for more results.</div>}
        {resultData.map((item, index) => (
          <div>
            <Accordion2 TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary2 className='accordion-summary2' expandIcon={<ExpandMoreIcon className='expand-more-icon2' />}>
                <div className='accordion-summary2-container'>
                  <div className='as2-div1'>
                    {item.listType === 'PEP' &&
                        <div className='as2-div1-name'>{index + 1 + ". " + item.fullName} </div>
                    }
                    {item.listType === 'Sanction' && item.name!=null && item.sources!="UNSCR" && item.sources!="FATF" &&
                        <div className='as2-div1-name'>{index + 1 + ". " + item.name} </div>
                    }
                    {item.listType === 'Sanction' && item.name==null && (item.sources=="UNSCR" || item.sources=="FATF") &&
                        <div className='as2-div1-name'>{index + 1 + ". "}<span style={{fontWeight:'bold'}}>Nationality-</span>{" "+ item.nationality[0]} </div>
                    }
                    {item.listType === 'Sanction' && item.fullNameScore !=null?<div className='as2-div2'>{"Match : " + ((item.fullNameScore).toFixed(2)) + "%"}</div>:null}
                    {item.listType === 'PEP' && item.fullNameScore !=null?<div className='as2-div2'>{"Match : " + ((item.fullNameScore)*100) + "%"}</div>:null}
                  </div>
                  <div className='as2-second-div'>
                    {/* {item.listType==='PEP' ?<div className='as2-list-type-pep'>{item.listType}</div>:<div className='as2-list-type-sanction'>{item.listType+': '+item.sources}</div>} */}
                    
                    {item.listType==='Sanction' && <div className='as2-list-type-sanction'>{item.listType+': '+item.sources}</div>}
                    {item.listType === 'PEP' &&
                      <>
                        <div>{item.facebook && item.facebook !== null && <IconButton href={item.facebook[0]}><FacebookRoundedIcon className='fb-icon' /></IconButton>}</div>
                        <div>{item.instagram && item.instagram !== null && <IconButton href={item.instagram[0]}><InstagramIcon className='insta-icon' /></IconButton>}</div>
                        <div>{item.twitter && item.twitter!== null && <IconButton href={item.twitter[0]}><TwitterIcon className='fb-icon' /></IconButton>}</div>
                        <div>{item.wikipediaLink && item.wikipediaLink !== null && <IconButton href={item.wikipediaLink}>W</IconButton>}</div>
                      </>}
                    {item.listType === 'Sanction' &&
                      <>
                        <div>{item.facebook.length > 0 && <IconButton href={item.facebook[0]}><FacebookRoundedIcon className='fb-icon' /></IconButton>}</div>
                        <div>{item.instagram.length > 0 && <IconButton href={item.instagram[0]}><InstagramIcon className='insta-icon' /></IconButton>}</div>
                        <div>{item.twitter.length > 0 && <IconButton href={item.twitter[0]}><TwitterIcon className='fb-icon' /></IconButton>}</div>
                      </>}

                  </div>
                </div>
              </AccordionSummary2>
              {item.listType === 'PEP' &&
                <div className='accordion-details2-container'>
                  <div className='accordion-details2'>
                    <div className='ad2-image'>
                   
                    {item.imageUrl !== null && item.imageUrl !== "" ?
                        <img alt='' src={ item.imageUrl } width="160" height="160"></img>
                        :
                        <img alt='' src={ AltUserImage } width='160' height='160'></img>
                      }
                    </div>
                    <div className='ad2-div1'>
                      <div className='details-div1'>
                        <div>First Name: <span>{item.firstName}</span></div>
                        <div>Full Name: <span> {item.fullName}</span></div>
                        <div>DOB: <span>{item.dob!==null?((item.dob[0].date).substring(0, 10)):" "}</span></div>
                        <div>Age:  <span>{item.age}</span></div>
                        <div>Gender: <span>{item.gender}</span></div>
                       
                      </div>
                      <div className='details-div1'>
                          <div>
                            Nationality: <span >{item.nationality !==""  ? item.nationality.replace(/"/g,'') :" "}</span>
                              </div>

                              {/* <div>Education:  {item.nationality && item.nationality.length>=1 && item.nationality.map((na) => (
                          <span> {education + ''}</span>
                        ))}</div>
                        <div>Occup */}

                        <div>Desigination: <span>{item.designation}</span></div>
                        <div>Education:  {item.education && item.education.length>=1 && item.education.map((education) => (
                          <span> {education + ''}</span>
                        ))}</div>
                        <div>Occupation: {item.occupation && item.occupation !== null && item.occupation.map((occupation) => (
                          <span> {occupation + ','}</span>
                        ))}</div>
                        <div>Political Association: {item.politicalAssociations && item.politicalAssociations !== null && item.politicalAssociations.map((politicalAssociation) => (
                          <span> {politicalAssociation + ','}</span>
                        ))}</div>
                      </div>
                    </div>
                    
                  </div>
                  {/* <div className='source-link-pep'>
                    <b>Source: </b>
                    <span> 
                      <a href={item.sourceurl} target='_blank'>
                        {item.sourceurl}
                      </a>
                    </span>
                    </div> */}
                    <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Sources
                  </AccordionSummary>
                  <AccordionDetails >
                    
                      <Paper className={classes.paper} component={Box} p={1}>

                        {<a style={{textDecoration:"none",color:"#EB2188"}} href={item.sourceurl}>{item.sourceurl}</a>}
                      </Paper>
                    
                  </AccordionDetails>
                </Accordion>

                    
                </div>
              }
              
              {item.listType === 'Sanction' && item.sources!="UNSCR" && item.sources!="FATF" &&
                <div className='accordion-details2-container'>
                  <div className='accordion-details2'>
                    <div className='ad2-image'>
                      {item.imageUrl !== null && item.imageUrl !== ""?
                        <img alt='' src={ item.imageUrl } width="160" height="160"></img>
                        :
                        <img alt='' src={ AltUserImage } width='160' height='160'></img>
                      }
                    </div>
                    <div className='ad2-div1'>
                      <div className='details-div1'>
                        <div>First Name: <span>{item.firstName}</span></div>
                        <div>Full Name: <span> {item.name}</span></div>
                        <div>DOB: <span>{item.dobs[0]}</span></div>
                        <div>Gender: <span>{item.gender}</span></div>
                      </div>
                      <div className='details-div1'>
                        <div>Nationality:  {item.nationality !== null && item.nationality.map((nationality) => (
                          <span> {nationality + ' '}</span>
                        ))}</div>
                        <div>Program: <span>{item.program}</span></div>
                        <div>Education:  {item.education !== null && item.education.map((education) => (
                          <span> {education + ','}</span>
                        ))}</div>
                        <div>Type: {item.type !== null &&
                          <span> {item.type}</span>}</div>

                      </div>
                    </div>

                  </div>
                </div>
              }
              {item.summary !== undefined && item.summary !== null && item.summary!==''&&
                <Accordion expanded={summaryExpanded} onClick={handleSummaryExpand} >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Summary
                  </AccordionSummary>
                  <Paper className={classes.paper} component={Box} p={0}>
                    <AccordionDetails >
                      {item.summary}
                    </AccordionDetails>
                  </Paper>
                </Accordion>
              }
              {item.listType === 'PEP' && item.aliases !== undefined && item.aliases !== null && item.aliases[0]!==''&&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Also Known As
                  </AccordionSummary>
                  <AccordionDetails >
                    {item.aliases[0]!==''&& item.aliases.map((aliasesNames, i) => (
                      <div key={i}>
                        <Paper className={classes.paper} component={Box} p={1}>

                          {(aliasesNames)}
                        </Paper>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }
              {item.listType === 'Sanction' && item.alias !== undefined && item.alias.length > 0 &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Also Known As
                  </AccordionSummary>
                  <AccordionDetails >
                    {item.alias.map((aliasNames, i) => (
                      <div key={i}>
                        <Paper className={classes.paper} component={Box} p={1}>

                          {(aliasNames)}
                        </Paper>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }
              {item.listType === 'PEP' && item.positionsHeld !== undefined && item.positionsHeld !== null &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Position Held
                  </AccordionSummary>
                  <AccordionDetails >
                    {item.positionsHeld.map((positionHeld) => (
                      <Paper className={classes.paper} component={Box} p={1}>

                        {positionHeld}
                      </Paper>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }
              {item.listType === 'Sanction' && item.positionsHeld !== undefined && item.positionsHeld.length > 0 &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Position Held
                  </AccordionSummary>
                  <AccordionDetails >
                    {item.positionsHeld.map((positionHeld) => (
                      <Paper className={classes.paper} component={Box} p={1}>

                        {positionHeld}
                      </Paper>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }
              {item.listType === 'PEP' && item.criminalCharges !== undefined && item.criminalCharges !== null &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Criminal Charges
                  </AccordionSummary>
                  <AccordionDetails >
                    {item.criminalCharges.map((criminalCharges) => (
                      <div>
                        <Paper className={classes.paper} component={Box} p={1}>

                          {criminalCharges}
                        </Paper>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              }

              {item.listType === 'PEP' && (item.father !== null || item.mother !== null   ) &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Family Details
                  </AccordionSummary>

                  <AccordionDetails >
                    {item.father !== undefined && item.father !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Father's Name:</span>{item.father}
                      </Paper>
                    }
                    {item.mother !== undefined && item.mother !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Mother's Name: </span>{item.mother}
                      </Paper>
                    }
                    {item.children !== undefined && item.children !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Children: </span>{item.children}
                      </Paper>
                    }
                    {item.spouse !== undefined && item.spouse !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Spouse: </span>{item.spouse}
                      </Paper>
                    }
                    {item.siblings !== undefined && item.siblings !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Sibiling: </span>{item.siblings}
                      </Paper>
                    }
                    {item.relatives !== undefined && item.relatives !== null &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Relatives: </span>{item.relatives}
                      </Paper>
                    }

                  </AccordionDetails>

                </Accordion>
              }
              {item.listType === 'Sanction' && (item.father !== "" || item.mother !== "" || item.children !== "" || item.spouse !== "" || item.siblings !== "" || item.relatives !== "") &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Family Details
                  </AccordionSummary>

                  <AccordionDetails >
                    {item.father !== undefined && item.father !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Father's Name:</span>{item.father}
                      </Paper>
                    }
                    {item.mother !== undefined && item.mother !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Mother's Name: </span>{item.mother}
                      </Paper>
                    }
                    {item.children !== undefined && item.children !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Children: </span>{item.children}
                      </Paper>
                    }
                    {item.spouse !== undefined && item.spouse !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Spouse: </span>{item.spouse}
                      </Paper>
                    }
                    {item.siblings !== undefined && item.siblings !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Sibiling: </span>{item.siblings}
                      </Paper>
                    }
                    {item.relatives !== undefined && item.relatives !== "" &&
                      <Paper className={classes.paper} component={Box} p={1}>
                        <span className='bold-text'>Relatives: </span>{item.relatives}
                      </Paper>
                    }

                  </AccordionDetails>

                </Accordion>
              }
              {item.listType === 'PEP' && (item.birthName !== null || item.birthPlace !== null || item.nativeName !== null ) &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Personal Details
                  </AccordionSummary>

                  <AccordionDetails >
                    {item.birthName !== undefined && item.birthName !== null &&
                      <Paper className={classes.paper} component={Box} p={0.5}>
                        <span className='bold-text'>Birth Name: </span>  {item.birthName}
                      </Paper>
                    }
                    {item.birthPlace !== undefined && item.birthPlace !== null &&
                      <Paper className={classes.paper} component={Box} p={0.5}>
                        <span className='bold-text'>Birth Place:</span> {item.birthPlace}
                      </Paper>
                    }
                    {item.nativeName !== undefined && item.nativeName !== null &&
                      <Paper className={classes.paper} component={Box} p={0.5}>
                        <span className='bold-text'>Native Name: </span>  {item.nativeName}
                      </Paper>
                    }
                  </AccordionDetails>
                  {item.residence !== undefined && item.residence !== null &&
                    <Paper className={classes.paper} component={Box} p={0.5}>

                      <AccordionDetails >
                        <span className='bold-text'>Residence: </span>
                        {item.residence.map((residence) => (
                          <Paper className={classes.paper} component={Box} p={0.5}>

                            {residence}

                          </Paper>
                        ))}
                      </AccordionDetails>
                    </Paper>
                  }
                  {item.otherWeblinks!==undefined && item.otherWeblinks !== null && item.otherWeblinks.map((otherWeblink) => (
                    <Paper className={classes.paper} component={Box} p={0.5}>
                      <AccordionDetails >
                        <span className='bold-text'>Websites: &nbsp;</span>{<a href={otherWeblink} > {otherWeblink}</a>}
                      </AccordionDetails>
                    </Paper>
                  ))}

                </Accordion>
              }
              {item.listType === 'Sanction' && (item.placeOfBirth.length > 0 || item.address.length > 0 || item.otherWeblinks.length > 0) &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Personal Details
                  </AccordionSummary>

                  <AccordionDetails >
                    {item.placeOfBirth !== undefined && item.placeOfBirth.length > 0 &&
                      item.placeOfBirth.map((placeOfBirth) => (
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Birth Place: </span> {placeOfBirth}
                        </Paper>
                        // {placeOfBirth}
                      ))
                    }
                  </AccordionDetails>
                  {item.address !== undefined && item.address.length > 0 && item.address[0]!=='' && item.address.map((address) => (
                   
                      <AccordionDetails >
                      {address.city !== null && address.city !== "" &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>City:</span>{' '+address.city}
                        </Paper>
                      }
                        {address.country !== null && address.country !== "" &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Country:</span>{' '+address.country}
                        </Paper>
                      }
                      {address.pincode !== null && address.pincode !== "" &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Pin Code:</span>{' '+address.pincode}
                        </Paper>
                      }
                      </AccordionDetails>
                    
                  ))}
                  {item.otherWeblinks !== undefined && item.otherWeblinks !==null && item.otherWeblinks.map((otherWeblink) => (
                    <Paper className={classes.paper} component={Box} p={0.5}>
                      <AccordionDetails >
                        <span className='bold-text'>Websites: &nbsp;</span>{<a href={otherWeblink} > {otherWeblink}</a>}
                      </AccordionDetails>
                    </Paper>
                  ))}

                </Accordion>
              }
              {item.listType === 'Sanction' && item.identifier !== undefined && item.identifier.length > 0 &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Identifiers
                  </AccordionSummary>

                  {item.identifier.map((identifierItems) => (
                    <AccordionDetails >
                      {identifierItems.identifiertype !== null &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Identifier Type:</span>{' '+identifierItems.identifiertype}
                        </Paper>
                      }
                      {identifierItems.identifiernummber !== null &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Identifier Number:</span>{' '+identifierItems.identifiernumber}
                        </Paper>
                      }
                      {identifierItems.country !== null &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Country:</span>{' '+identifierItems.country}
                        </Paper>
                      }
                      {identifierItems.issuedate !== null && identifierItems.issuedate !== '' &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Issue Date:</span>{' '+identifierItems.issuedate}
                        </Paper>
                      }
                      {identifierItems.description !== null &&
                        <Paper className={classes.paper} component={Box} p={1}>
                          <span className='bold-text'>Description:</span>{' '+identifierItems.description}
                        </Paper>
                      }
                    </AccordionDetails>
                  ))}

                </Accordion>
              }
              {item.listType === 'PEP' || item.listType==="Sanction" && item.sources !== undefined && item.sources !== null &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Sources
                  </AccordionSummary>
                  <AccordionDetails >
                    {typeof item.sources!=='string' && item.sources.map((sources) => (
                      <Paper className={classes.paper} component={Box} p={1}>

                        {<a href={sources}>{sources}</a>}
                      </Paper>
                    ))}
                    {typeof item.sources=='string' && 
                      <Paper className={classes.paper} component={Box} p={1}>

                        {<a href={item.sources}>{item.sourcelink}</a>}
                      </Paper>
                    }
                  </AccordionDetails>
                </Accordion>
              }
              {/* {item.list_type === 'Sanction' && item.sources !== undefined && item.sources !== null &&
                <Accordion >
                  <AccordionSummary expandIcon={<ExpandMoreIcon className='expand-more-icon' />}>
                    Sources
                  </AccordionSummary>
                  <AccordionDetails >
                    <Paper className={classes.paper} component={Box} p={1}>

                      {item.sources}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              } */}


              <br></br><br></br>
            </Accordion2>
          </div>


        ))}

    </>
  )
}


export default PEPSanctionResult;
