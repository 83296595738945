export const countryLookUp=[
	{
		"countrycode" : "AF",
		"countryDesc" : "Afghanistan"
	},
	{
		"countrycode" : "AX",
		"countryDesc" : "Aland Islands"
	},
	{
		"countrycode" : "AL",
		"countryDesc" : "Albania"
	},
	{
		"countrycode" : "DZ",
		"countryDesc" : "Algeria"
	},
	{
		"countrycode" : "AS",
		"countryDesc" : "American Samoa"
	},
	{
		"countrycode" : "AD",
		"countryDesc" : "Andorra"
	},
	{
		"countrycode" : "AO",
		"countryDesc" : "Angola"
	},
	{
		"countrycode" : "AI",
		"countryDesc" : "Anguilla"
	},
	{
		"countrycode" : "AQ",
		"countryDesc" : "Antarctica"
	},
	{
		"countrycode" : "AG",
		"countryDesc" : "Antigua And Barbuda"
	},
	{
		"countrycode" : "AR",
		"countryDesc" : "Argentina"
	},
	{
		"countrycode" : "AM",
		"countryDesc" : "Armenia"
	},
	{
		"countrycode" : "AW",
		"countryDesc" : "Aruba"
	},
	{
		"countrycode" : "AU",
		"countryDesc" : "Australia"
	},
	{
		"countrycode" : "AT",
		"countryDesc" : "Austria"
	},
	{
		"countrycode" : "AZ",
		"countryDesc" : "Azerbaijan"
	},
	{
		"countrycode" : "BS",
		"countryDesc" : "Bahamas"
	},
	{
		"countrycode" : "BH",
		"countryDesc" : "Bahrain"
	},
	{
		"countrycode" : "BD",
		"countryDesc" : "Bangladesh"
	},
	{
		"countrycode" : "BB",
		"countryDesc" : "Barbados"
	},
	{
		"countrycode" : "BY",
		"countryDesc" : "Belarus"
	},
	{
		"countrycode" : "BE",
		"countryDesc" : "Belgium"
	},
	{
		"countrycode" : "BZ",
		"countryDesc" : "Belize"
	},
	{
		"countrycode" : "BJ",
		"countryDesc" : "Benin"
	},
	{
		"countrycode" : "BM",
		"countryDesc" : "Bermuda"
	},
	{
		"countrycode" : "BT",
		"countryDesc" : "Bhutan"
	},
	{
		"countrycode" : "BO",
		"countryDesc" : "Bolivia"
	},
	{
		"countrycode" : "BA",
		"countryDesc" : "Bosnia And Herzegovina"
	},
	{
		"countrycode" : "BW",
		"countryDesc" : "Botswana"
	},
	{
		"countrycode" : "BV",
		"countryDesc" : "Bouvet Island"
	},
	{
		"countrycode" : "BR",
		"countryDesc" : "Brazil"
	},
	{
		"countrycode" : "IO",
		"countryDesc" : "British Indian Ocean Territory"
	},
	{
		"countrycode" : "BN",
		"countryDesc" : "Brunei Darussalam"
	},
	{
		"countrycode" : "BG",
		"countryDesc" : "Bulgaria"
	},
	{
		"countrycode" : "BF",
		"countryDesc" : "Burkina Faso"
	},
	{
		"countrycode" : "BI",
		"countryDesc" : "Burundi"
	},
	{
		"countrycode" : "KH",
		"countryDesc" : "Cambodia"
	},
	{
		"countrycode" : "CM",
		"countryDesc" : "Cameroon"
	},
	{
		"countrycode" : "CA",
		"countryDesc" : "Canada"
	},
	{
		"countrycode" : "CV",
		"countryDesc" : "Cape Verde"
	},
	{
		"countrycode" : "KY",
		"countryDesc" : "Cayman Islands"
	},
	{
		"countrycode" : "CF",
		"countryDesc" : "Central African Republic"
	},
	{
		"countrycode" : "TD",
		"countryDesc" : "Chad"
	},
	{
		"countrycode" : "CL",
		"countryDesc" : "Chile"
	},
	{
		"countrycode" : "CN",
		"countryDesc" : "China"
	},
	{
		"countrycode" : "CX",
		"countryDesc" : "Christmas Island"
	},
	{
		"countrycode" : "CC",
		"countryDesc" : "Cocos (Keeling) Islands"
	},
	{
		"countrycode" : "CO",
		"countryDesc" : "Colombia"
	},
	{
		"countrycode" : "KM",
		"countryDesc" : "Comoros"
	},
	{
		"countrycode" : "CG",
		"countryDesc" : "Congo"
	},
	{
		"countrycode" : "CD",
		"countryDesc" : "Congo,Democratic Republic"
	},
	{
		"countrycode" : "CK",
		"countryDesc" : "Cook Islands"
	},
	{
		"countrycode" : "CR",
		"countryDesc" : "Costa Rica"
	},
	{
		"countrycode" : "CI",
		"countryDesc" : "Côte D'ivoire"
	},
	{
		"countrycode" : "HR",
		"countryDesc" : "Croatia"
	},
	{
		"countrycode" : "CU",
		"countryDesc" : "Cuba"
	},
	{
		"countrycode" : "CY",
		"countryDesc" : "Cyprus"
	},
	{
		"countrycode" : "CZ",
		"countryDesc" : "Czech Republic"
	},
	{
		"countrycode" : "DK",
		"countryDesc" : "Denmark"
	},
	{
		"countrycode" : "DJ",
		"countryDesc" : "Djibouti"
	},
	{
		"countrycode" : "DM",
		"countryDesc" : "Dominica"
	},
	{
		"countrycode" : "DO",
		"countryDesc" : "Dominican Republic"
	},
	{
		"countrycode" : "EC",
		"countryDesc" : "Ecuador"
	},
	{
		"countrycode" : "EG",
		"countryDesc" : "Egypt"
	},
	{
		"countrycode" : "SV",
		"countryDesc" : "El Ssalvador"
	},
	{
		"countrycode" : "GQ",
		"countryDesc" : "Equatorial Guinea"
	},
	{
		"countrycode" : "ER",
		"countryDesc" : "Eritrea"
	},
	{
		"countrycode" : "EE",
		"countryDesc" : "Estonia"
	},
	{
		"countrycode" : "ET",
		"countryDesc" : "Ethiopia"
	},
	{
		"countrycode" : "FK",
		"countryDesc" : "Falkland Islands (Malvinas)"
	},
	{
		"countrycode" : "FO",
		"countryDesc" : "Faroe Islands"
	},
	{
		"countrycode" : "FJ",
		"countryDesc" : "Fiji"
	},
	{
		"countrycode" : "FI",
		"countryDesc" : "Finland"
	},
	{
		"countrycode" : "FR",
		"countryDesc" : "France"
	},
	{
		"countrycode" : "GF",
		"countryDesc" : "French Guiana"
	},
	{
		"countrycode" : "PF",
		"countryDesc" : "French Polynesia"
	},
	{
		"countrycode" : "TF",
		"countryDesc" : "French Southern Territories"
	},
	{
		"countrycode" : "GA",
		"countryDesc" : "Gabon"
	},
	{
		"countrycode" : "GM",
		"countryDesc" : "Gambia"
	},
	{
		"countrycode" : "GE",
		"countryDesc" : "Georgia"
	},
	{
		"countrycode" : "DE",
		"countryDesc" : "Germany"
	},
	{
		"countrycode" : "GH",
		"countryDesc" : "Ghana"
	},
	{
		"countrycode" : "GI",
		"countryDesc" : "Gibraltar"
	},
	{
		"countrycode" : "GR",
		"countryDesc" : "Greece"
	},
	{
		"countrycode" : "GL",
		"countryDesc" : "Greenland"
	},
	{
		"countrycode" : "GD",
		"countryDesc" : "Grenada"
	},
	{
		"countrycode" : "GP",
		"countryDesc" : "Guadeloupe"
	},
	{
		"countrycode" : "GU",
		"countryDesc" : "Guam"
	},
	{
		"countrycode" : "GT",
		"countryDesc" : "Guatemala"
	},
	{
		"countrycode" : "GG",
		"countryDesc" : "Guernsey"
	},
	{
		"countrycode" : "GN",
		"countryDesc" : "Guinea"
	},
	{
		"countrycode" : "GW",
		"countryDesc" : "Guinea-Bissau"
	},
	{
		"countrycode" : "GY",
		"countryDesc" : "Guyana"
	},
	{
		"countrycode" : "HT",
		"countryDesc" : "Haiti"
	},
	{
		"countrycode" : "HM",
		"countryDesc" : "Heard Island And Mcdonald Islands"
	},
	{
		"countrycode" : "VA",
		"countryDesc" : "Vatican City State"
	},
	{
		"countrycode" : "HN",
		"countryDesc" : "Honduras"
	},
	{
		"countrycode" : "HK",
		"countryDesc" : "Hong Kong"
	},
	{
		"countrycode" : "HU",
		"countryDesc" : "Hungary"
	},
	{
		"countrycode" : "IS",
		"countryDesc" : "Iceland"
	},
	{
		"countrycode" : "IN",
		"countryDesc" : "India"
	},
	{
		"countrycode" : "ID",
		"countryDesc" : "Indonesia"
	},
	{
		"countrycode" : "IR",
		"countryDesc" : "Iran"
	},
	{
		"countrycode" : "IQ",
		"countryDesc" : "Iraq"
	},
	{
		"countrycode" : "IE",
		"countryDesc" : "Ireland"
	},
	{
		"countrycode" : "IM",
		"countryDesc" : "Isle Of Man"
	},
	{
		"countrycode" : "IL",
		"countryDesc" : "Israel"
	},
	{
		"countrycode" : "IT",
		"countryDesc" : "Italy"
	},
	{
		"countrycode" : "JM",
		"countryDesc" : "Jamaica"
	},
	{
		"countrycode" : "JP",
		"countryDesc" : "Japan"
	},
	{
		"countrycode" : "JE",
		"countryDesc" : "Jersey"
	},
	{
		"countrycode" : "JO",
		"countryDesc" : "Jordan"
	},
	{
		"countrycode" : "KZ",
		"countryDesc" : "Kazakhstan"
	},
	{
		"countrycode" : "KE",
		"countryDesc" : "Kenya"
	},
	{
		"countrycode" : "KI",
		"countryDesc" : "Kiribati"
	},
	{
		"countrycode" : "KP",
		"countryDesc" : "Korea, Democratic People's Republic"
	},
	{
		"countrycode" : "KR",
		"countryDesc" : "Korea"
	},
	{
		"countrycode" : "KW",
		"countryDesc" : "Kuwait"
	},
	{
		"countrycode" : "KG",
		"countryDesc" : "Kyrgyzstan"
	},
	{
		"countrycode" : "LA",
		"countryDesc" : "Lao People's Democratic Republic"
	},
	{
		"countrycode" : "LV",
		"countryDesc" : "Latvia"
	},
	{
		"countrycode" : "LB",
		"countryDesc" : "Lebanon"
	},
	{
		"countrycode" : "LS",
		"countryDesc" : "Lesotho"
	},
	{
		"countrycode" : "LR",
		"countryDesc" : "Liberia"
	},
	{
		"countrycode" : "LY",
		"countryDesc" : "Libyan Arab Jamahiriya"
	},
	{
		"countrycode" : "LI",
		"countryDesc" : "Liechtenstein"
	},
	{
		"countrycode" : "LT",
		"countryDesc" : "Lithuania"
	},
	{
		"countrycode" : "LU",
		"countryDesc" : "Luxembourg"
	},
	{
		"countrycode" : "MO",
		"countryDesc" : "Macao"
	},
	{
		"countrycode" : "MK",
		"countryDesc" : "Macedonia, The Former Yugoslav Repub"
	},
	{
		"countrycode" : "MG",
		"countryDesc" : "Madagascar"
	},
	{
		"countrycode" : "MW",
		"countryDesc" : "Malawi"
	},
	{
		"countrycode" : "MY",
		"countryDesc" : "Malaysia"
	},
	{
		"countrycode" : "MV",
		"countryDesc" : "Maldives"
	},
	{
		"countrycode" : "ML",
		"countryDesc" : "Mali"
	},
	{
		"countrycode" : "MT",
		"countryDesc" : "Malta"
	},
	{
		"countrycode" : "MH",
		"countryDesc" : "Marshall Islands"
	},
	{
		"countrycode" : "MQ",
		"countryDesc" : "Martinique"
	},
	{
		"countrycode" : "MR",
		"countryDesc" : "Mauritania"
	},
	{
		"countrycode" : "MU",
		"countryDesc" : "Mauritius"
	},
	{
		"countrycode" : "YT",
		"countryDesc" : "Mayotte"
	},
	{
		"countrycode" : "MX",
		"countryDesc" : "Mexico"
	},
	{
		"countrycode" : "FM",
		"countryDesc" : "Micronesia"
	},
	{
		"countrycode" : "MD",
		"countryDesc" : "Moldova"
	},
	{
		"countrycode" : "MC",
		"countryDesc" : "Monaco"
	},
	{
		"countrycode" : "MN",
		"countryDesc" : "Mongolia"
	},
	{
		"countrycode" : "ME",
		"countryDesc" : "Montenegro"
	},
	{
		"countrycode" : "MS",
		"countryDesc" : "Montserrat"
	},
	{
		"countrycode" : "MA",
		"countryDesc" : "Morocco"
	},
	{
		"countrycode" : "MZ",
		"countryDesc" : "Mozambique"
	},
	{
		"countrycode" : "MM",
		"countryDesc" : "Myanmar"
	},
	{
		"countrycode" : "NA",
		"countryDesc" : "Namibia"
	},
	{
		"countrycode" : "NR",
		"countryDesc" : "Nauru"
	},
	{
		"countrycode" : "NP",
		"countryDesc" : "Nepal"
	},
	{
		"countrycode" : "NL",
		"countryDesc" : "Netherlands"
	},
	{
		"countrycode" : "AN",
		"countryDesc" : "Netherlands Antilles"
	},
	{
		"countrycode" : "NC",
		"countryDesc" : "New Caledonia"
	},
	{
		"countrycode" : "NZ",
		"countryDesc" : "New Zealand"
	},
	{
		"countrycode" : "NI",
		"countryDesc" : "Nicaragua"
	},
	{
		"countrycode" : "NE",
		"countryDesc" : "Niger"
	},
	{
		"countrycode" : "NG",
		"countryDesc" : "Nigeria"
	},
	{
		"countrycode" : "NU",
		"countryDesc" : "Niue"
	},
	{
		"countrycode" : "NF",
		"countryDesc" : "Norfolk Island"
	},
	{
		"countrycode" : "MP",
		"countryDesc" : "Northern Mariana Islands"
	},
	{
		"countrycode" : "NO",
		"countryDesc" : "Norway"
	},
	{
		"countrycode" : "OM",
		"countryDesc" : "Oman"
	},
	{
		"countrycode" : "PK",
		"countryDesc" : "Pakistan"
	},
	{
		"countrycode" : "PW",
		"countryDesc" : "Palau"
	},
	{
		"countrycode" : "PS",
		"countryDesc" : "Palestinian Territory, Occupied"
	},
	{
		"countrycode" : "PA",
		"countryDesc" : "Panama"
	},
	{
		"countrycode" : "PG",
		"countryDesc" : "Papua New Guinea"
	},
	{
		"countrycode" : "PY",
		"countryDesc" : "Paraguay"
	},
	{
		"countrycode" : "PE",
		"countryDesc" : "Peru"
	},
	{
		"countrycode" : "PH",
		"countryDesc" : "Philippines"
	},
	{
		"countrycode" : "PN",
		"countryDesc" : "Pitcairn"
	},
	{
		"countrycode" : "PL",
		"countryDesc" : "Poland"
	},
	{
		"countrycode" : "PT",
		"countryDesc" : "Portugal"
	},
	{
		"countrycode" : "PR",
		"countryDesc" : "Puerto Rico"
	},
	{
		"countrycode" : "QA",
		"countryDesc" : "Qatar"
	},
	{
		"countrycode" : "RE",
		"countryDesc" : "Réunion"
	},
	{
		"countrycode" : "RO",
		"countryDesc" : "Romania"
	},
	{
		"countrycode" : "RU",
		"countryDesc" : "Russian Federation"
	},
	{
		"countrycode" : "RW",
		"countryDesc" : "Rwanda"
	},
	{
		"countrycode" : "BL",
		"countryDesc" : "Saint Barthélemy"
	},
	{
		"countrycode" : "SH",
		"countryDesc" : "Saint Helena, Ascension And Tristan"
	},
	{
		"countrycode" : "KN",
		"countryDesc" : "Saint Kitts And Nevis"
	},
	{
		"countrycode" : "LC",
		"countryDesc" : "Saint Lucia"
	},
	{
		"countrycode" : "MF",
		"countryDesc" : "Saint Martin"
	},
	{
		"countrycode" : "PM",
		"countryDesc" : "Saint Pierre And Miquelon"
	},
	{
		"countrycode" : "VC",
		"countryDesc" : "Saint Vincent And The Grenadines"
	},
	{
		"countrycode" : "WS",
		"countryDesc" : "Samoa"
	},
	{
		"countrycode" : "SM",
		"countryDesc" : "San Marino"
	},
	{
		"countrycode" : "ST",
		"countryDesc" : "Sao Tome And Principe"
	},
	{
		"countrycode" : "SA",
		"countryDesc" : "Saudi Arabia"
	},
	{
		"countrycode" : "SN",
		"countryDesc" : "Senegal"
	},
	{
		"countrycode" : "RS",
		"countryDesc" : "Serbia"
	},
	{
		"countrycode" : "SC",
		"countryDesc" : "Seychelles"
	},
	{
		"countrycode" : "SL",
		"countryDesc" : "Sierra Leone"
	},
	{
		"countrycode" : "SG",
		"countryDesc" : "Singapore"
	},
	{
		"countrycode" : "SK",
		"countryDesc" : "Slovakia"
	},
	{
		"countrycode" : "SI",
		"countryDesc" : "Slovenia"
	},
	{
		"countrycode" : "SB",
		"countryDesc" : "Solomon Islands"
	},
	{
		"countrycode" : "SO",
		"countryDesc" : "Somalia"
	},
	{
		"countrycode" : "ZA",
		"countryDesc" : "South Africa"
	},
	{
		"countrycode" : "GS",
		"countryDesc" : "South Georgia And The South Sandwich"
	},
	{
		"countrycode" : "ES",
		"countryDesc" : "Spain"
	},
	{
		"countrycode" : "LK",
		"countryDesc" : "Sri Lanka"
	},
	{
		"countrycode" : "SD",
		"countryDesc" : "Sudan"
	},
	{
		"countrycode" : "SR",
		"countryDesc" : "Suriname"
	},
	{
		"countrycode" : "SJ",
		"countryDesc" : "Svalbard And Jan Mayen"
	},
	{
		"countrycode" : "SZ",
		"countryDesc" : "Swaziland"
	},
	{
		"countrycode" : "SE",
		"countryDesc" : "Sweden"
	},
	{
		"countrycode" : "CH",
		"countryDesc" : "Switzerland"
	},
	{
		"countrycode" : "SY",
		"countryDesc" : "Syrian Arab Republic"
	},
	{
		"countrycode" : "TW",
		"countryDesc" : "Taiwan, Province Of China"
	},
	{
		"countrycode" : "TJ",
		"countryDesc" : "Tajikistan"
	},
	{
		"countrycode" : "TZ",
		"countryDesc" : "Tanzania"
	},
	{
		"countrycode" : "TH",
		"countryDesc" : "Thailand"
	},
	{
		"countrycode" : "TL",
		"countryDesc" : "Timor-Leste"
	},
	{
		"countrycode" : "TG",
		"countryDesc" : "Togo"
	},
	{
		"countrycode" : "TK",
		"countryDesc" : "Tokelau"
	},
	{
		"countrycode" : "TO",
		"countryDesc" : "Tonga"
	},
	{
		"countrycode" : "TT",
		"countryDesc" : "Trinidad And Tobago"
	},
	{
		"countrycode" : "TN",
		"countryDesc" : "Tunisia"
	},
	{
		"countrycode" : "TR",
		"countryDesc" : "Turkey"
	},
	{
		"countrycode" : "TM",
		"countryDesc" : "Turkmenistan"
	},
	{
		"countrycode" : "TC",
		"countryDesc" : "Turks And Caicos Islands"
	},
	{
		"countrycode" : "TV",
		"countryDesc" : "Tuvalu"
	},
	{
		"countrycode" : "UG",
		"countryDesc" : "Uganda"
	},
	{
		"countrycode" : "UA",
		"countryDesc" : "Ukraine"
	},
	{
		"countrycode" : "AE",
		"countryDesc" : "United Arab Emirates"
	},
	{
		"countrycode" : "GB",
		"countryDesc" : "United Kingdom"
	},
	{
		"countrycode" : "US",
		"countryDesc" : "United States"
	},
	{
		"countrycode" : "UM",
		"countryDesc" : "United States Minor Outlying Islands"
	},
	{
		"countrycode" : "UY",
		"countryDesc" : "Uruguay"
	},
	{
		"countrycode" : "UZ",
		"countryDesc" : "Uzbekistan"
	},
	{
		"countrycode" : "VU",
		"countryDesc" : "Vanuatu"
	},
	{
		"countrycode" : "VE",
		"countryDesc" : "Venezuela"
	},
	{
		"countrycode" : "VN",
		"countryDesc" : "Viet Nam"
	},
	{
		"countrycode" : "VG",
		"countryDesc" : "Virgin Islands, British"
	},
	{
		"countrycode" : "VI",
		"countryDesc" : "Virgin Islands, U.S."
	},
	{
		"countrycode" : "WF",
		"countryDesc" : "Wallis And Futuna"
	},
	{
		"countrycode" : "EH",
		"countryDesc" : "Western Sahara"
	},
	{
		"countrycode" : "YE",
		"countryDesc" : "Yemen"
	},
	{
		"countrycode" : "ZM",
		"countryDesc" : "Zambia"
	},
	{
		"countrycode" : "ZW",
		"countryDesc" : "Zimbabwe"
	},
	{
		"countrycode" : "SS",
		"countryDesc" : "South Sudan"
	},
	{
		"countrycode" : "CW",
		"countryDesc" : "Curacao"
	},
	{
		"countrycode" : "BQ",
		"countryDesc" : "Bonaire, Sint Eustatius and Saba"
	},
	{
		"countrycode" : "SX",
		"countryDesc" : "Sint Marteen"
	},
	{
		"countrycode" : "XX",
		"countryDesc" : "Not available"
	},
	{
		"countrycode" : "ZZ",
		"countryDesc" : "Others"
	}
]
