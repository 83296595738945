import React, { useState } from "react";
import "./register.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  isIWSSOEnable,
  iwSSOClientUrl,
} from "../../../utility/configurations.json";
import {
  MenuItem,
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Button,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import loader from "./../../../assets/load.svg";
import { postData } from "./../../../utility/networkCall";
import IntellewingsLogo from "./../../../assets/showcase.png";

import showcase from "./../../../assets/showcase.png";
import { pink, green } from "@material-ui/core/colors";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import ServiceAgreement from "../serviceAgreement/serviceAgreement";
import intellewings_logo1 from "./../../../assets/intellewings_logo1.png";
const { enc, pad, AES, mode, SHA1 } = require("crypto-js");
const set = (keys, value) => {
  const key = enc.Utf8.parse(keys);
  const passwd = enc.Utf8.parse(value.toString());

  const encrypted = AES.encrypt(passwd, key, {
    keySize: 128 / 8,
    iv: key,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  return encrypted.toString();
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "80%",

    margin: theme.spacing(0, 0),
  },
}));

const theme1 = createTheme({
  palette: {
    primary: pink,
    main: "#EB2188",
  },
});
const Register = () => {
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [serviceAgreementModalOpen, setserviceAgreementModalOpen] =
    useState(false);
  const closeServiceAgreementModal = () => {
    setserviceAgreementModalOpen(false);
  };

  const [name, setName] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const [message, setMessage] = useState("");
  const [frstname, setFrstname] = useState("");
  const [scndname, setScndname] = useState("");
  const [formData, setformData] = useState();

  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setformData(data);
    setserviceAgreementModalOpen(true);
    console.log("onsubmit excuted", formData);
  };
  const formSubmit = () => {
    console.log("event ");
    console.log("formsubmit executed", formData);
    if (serviceAgreementModalOpen == true) {
      setserviceAgreementModalOpen(false);
      setLoading(true);

      const { email, password } = formData;

      const encryptedPassword = set(email, password);
      const newFormData = { ...formData, password: encryptedPassword };
      console.log(encryptedPassword);

      console.log("handle submit data", newFormData);
      postData("register", newFormData)
        .then((d) => {
          // console.log(d)
          if (d === "success") {
            setLoading(false);
            setMessage(
              "Registration completed. We have sent you a confirmation email, please confirm your email and login."
            );
            // window.location.href='/signin'
            // return;
          } else if (d === "Email Already Exists!!!!") {
            setLoading(false);
            setMessage(
              "ERROR!! Email Already Exists. Kindly change the Email Address."
            );
          } else if (d === "") {
          } else {
            setLoading(false);
            setMessage("Network Error");
          }
        })
        .catch((e) => {
          setLoading(false);
          setMessage("Network Error");
        });
    }
  };
  const validateEmail = (value) => {
    const regx =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regx.test(value)) {
      console.log(value, "true");
      return 1;
    } else {
      console.log(value, "false");
      return 0;
    }
  };
  const validatePassword = (value) => {
    const regx =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (regx.test(value)) {
      console.log(value, "true");
      return 1;
    } else {
      console.log(value, "false");
      return 0;
    }
  };
  const validateName = (value) => {
    const regx = /^[A-Za-z ,.'-]+$/;

    if (value.trim().length > 0) {
      if (regx.test(value)) {
        return 1;
      }
      return 0;
    } else {
      return 0;
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <div className="register-form-child register-form-image-container">
          <img
            alt=""
            src={intellewings_logo1}
            className="registeration-image logo"
          ></img>
          <img
            alt=""
            src={IntellewingsLogo}
            className="registeration-image showcase"
          ></img>
        </div>
        <div className="register-form-child register-form-container">
          <form onSubmit={handleSubmit(onSubmit)} className="registerform">
            <div class="text-header">
              <h2>Welcome to AML Check</h2>
              <p className="register-subtitle">
                Register now to explore our AML Check offerings.
              </p>
            </div>
            <div class="container">
              <div class="singleDiv">
                <div class="divDivide">
                  <p class="textField-label">
                    First Name <span id="requiredField"> *</span>
                  </p>

                  <TextField
                    class="fieldInput"
                    placeholder="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.inputField}
                    name="firstName"
                    onChange={(e) => setName(e.target.value)}
                    {...register("firstName", {
                      required: "First Name is required",
                      validate: (value) =>
                        validateName(value) > 0 ||
                        "First Name should only consist of alphabets",
                    })}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName?.message}
                  />
                </div>
                <div class="divDivide">
                  <p class="textField-label">
                    Last Name <span id="requiredField"> *</span>
                  </p>

                  <TextField
                    class="sideFieldInput"
                    placeholder="Last Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.inputField}
                    name="lastName"
                    {...register("lastName", {
                      required: "Last Name is required",
                      validate: (value) =>
                        validateName(value) > 0 ||
                        "Last name should only consists of alphabets",
                    })}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName?.message}
                  />
                </div>
              </div>
              <div class="singleDiv">
                <div class="divDivide">
                  <p class="textField-label">
                    Email <span id="requiredField"> *</span>
                  </p>

                  <TextField
                    class="fieldInput"
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.inputField}
                    name="email"
                    type="email"
                    {...register("email", {
                      required: "Email Address is required",
                      pattern: {
                        value:
                          /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/,
                        message: "Please enter a valid e-mail address.",
                      },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                </div>

                <div class="divDivide">
                  <p class="textField-label">
                    Organization Name <span id="requiredField"> *</span>
                  </p>
                  <TextField
                    class="fieldInput"
                    placeholder="Organization Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.inputField}
                    name="org"
                    {...register("org", {
                      required: "This field is required",
                      validate: (value) =>
                        validateName(value) > 0 ||
                        "Organisation Name should only consists of alphabets",
                    })}
                    error={Boolean(errors.org)}
                    helperText={errors.org?.message}
                  />
                </div>

                {/* <div class='divDivide'>
                                        <p class='textField-label' >Contact Number </p>

                                        <TextField class='sideFieldInput'
                                            placeholder="Contact Number"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            className={classes.inputField}
                                            name="contact"
                                            {...register('contact')}
                                        />
                                    </div> */}
              </div>
              <div class="singleDiv">
                <div class="divDivide">
                  <p class="textField-label">
                    Password <span id="requiredField"> *</span>
                  </p>

                  <TextField
                    class="fieldInput"
                    placeholder="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.inputField}
                    name="password"
                    type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="pricing-icons"
                            aria-label="toggle password visibility"
                            size="small"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility className="visibility-icon" />
                            ) : (
                              <VisibilityOff className="visibility-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register("password", {
                      required: "Password is required",
                      validate: (value) =>
                        validatePassword(value) > 0 ||
                        "Password must contain minimum 8 characters, at least 1 uppercase, 1 lowercase, 1 number and 1 special character(@,$,!,%,*,?,&) ",
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                  />
                </div>
                <div class="divDivide">
                  <p class="textField-label">
                    Confirm Password <span id="requiredField"> *</span>
                  </p>

                  <TextField
                    class="sideFieldInput"
                    placeholder="Confirm Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.inputField}
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"} // <-- This is where the magic happens
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="pricing-icons"
                            aria-label="toggle password visibility"
                            size="small"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility className="visibility-icon" />
                            ) : (
                              <VisibilityOff className="visibility-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register("confirmPassword", {
                      required: " Confirm Password is required",
                      validate: (value) =>
                        value === getValues("password") ||
                        "Password and Confirm Password do not match.",
                    })}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                  />
                </div>
              </div>

              <div class="singleDiv">
                {/* <div class='divDivide'>
                                        <p class='textField-label'>Organization Name <span id='requiredField'> *</span></p>
                                        <TextField class='fieldInput'
                                            placeholder="Organization Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            className={classes.inputField}
                                            name="org"
                                            {...register('org', {
                                                required: "This field is required",
                                                validate: (value) =>
                                                    validateName(value) > 0 || "Empty string not allowed"
                                            })}
                                            error={Boolean(errors.org)}
                                            helperText={errors.org?.message}
                                        />
                                    </div> */}
                {/* <div class='divDivide'>
                                        <p class='textField-label'>Industry Type</p>

                                        <TextField class='fieldInput'
                                            placeholder="Industry Type"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            className={classes.inputField}
                                            name="industry"
                                            {...register('industry')}
                                            error={Boolean(errors.industry)}
                                            helperText={errors.industry?.message}
                                        />
                                    </div> */}
              </div>
              <div class="singleDiv">
                {/* <div class='divDivide'>
                                        <p class='textField-label'>Company Size</p>

                                        <FormControl class='sideFieldInput'
                                            fullWidth
                                            size="small"
                                            className={classes.inputField}
                                            variant="outlined"
                                        >

                                            <Select {...register('company')} name='companySize' defaultValue='0-10'>
                                                <option value={'0-10'}>0-10</option>
                                                <option value={'11-50'}>11-50</option>
                                                <option value={'51-100'}>51-100</option>
                                                <option value={'100+'}>100+</option>
                                            </Select>

                                        </FormControl>
                                    </div> */}
                <div className="divDivide">
                  <p className="textField-label">
                    Country <span id="requiredField"> *</span>
                  </p>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!errors.country}
                  >
                    {/* <InputLabel id="country-label">Country</InputLabel> */}
                    <Select
                      // labelId="country-label"
                      {...register("country", {
                        required: "Country name is required",
                        validate: (value) =>
                          country_list.includes(value) ||
                          "Please select a valid country",
                      })}
                      defaultValue="India"
                      // label="Country"
                    >
                      {country_list.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.country && (
                      <FormHelperText>{errors.country.message}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>

              <div
                style={{ padding: "30px", width: "80%", textAlign: "center" }}
              >
                {message !== "" && !loading ? (
                  <Alert
                    severity={
                      message ===
                      "Registration completed. We have sent you a confirmation email, please confirm your email and login."
                        ? "success"
                        : "error"
                    }
                  >
                    {message}
                  </Alert>
                ) : null}
              </div>

              {loading ? (
                <div style={{ marginLeft: "35%", paddingBottom: "5px" }}>
                  <img alt="" src={loader} height="50px" />
                </div>
              ) : null}
              <div class="register-button-container">
                <Button
                  class="registerButton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setFrstname(getValues("firstName"));
                    setScndname(getValues("lastName"));
                    console.log(getValues("errors.firstName"), "hey");
                  }}
                >
                  {" "}
                  Register{" "}
                </Button>
                <p className="register-footer">
                  Already have an account?
                  {!isIWSSOEnable ? (
                    <span className="register-span">
                      <Link to="/signin" className="register-span-link">
                        {" "}
                        SignIn
                      </Link>
                    </span>
                  ) : (
                    <span>
                      <a className="register-span" href={iwSSOClientUrl}>
                        SignIn
                      </a>
                    </span>
                  )}
                </p>
              </div>

              <ServiceAgreement
                open={serviceAgreementModalOpen}
                onClose={closeServiceAgreementModal}
                formData={formSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const country_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua",
  "Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States of America(USA)",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default Register;
