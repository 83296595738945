import './App.css';
import PostLoginContainer from './components/postlogin/PostLoginContainer';
import PreLoginContainer from './components/prelogin/PreLoginContainer';
import React, { Component } from 'react';


class App extends Component {

  checkAuthentication = async(auth_token)=>{
    if (auth_token!== undefined || auth_token!==null){
        return true; 
    }
    return false;
  }

  constructor() {
    super();
    this.state = {access : "prelogin"};
    let session_store = window.sessionStorage;
    let auth_token = session_store.getItem('token');
    if (auth_token !== null) {
      this.checkAuthentication(auth_token)
        this.state={access : "postlogin"};
    }
  }


  render() {
    console.log(this.state.access)
    if (this.state.access === 'postlogin') {
      return <PostLoginContainer/>
    }
    else{
      return <PreLoginContainer/>
    }
  }
}

export default App;
