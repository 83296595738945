import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PreNavBar from '../shared/navbar/PreNavBar';

import LandingPage from './landingPage/LandingPage';
import Signin from './signin/Signin';
import Register from './register/Register';
import Pricing from './pricing/Pricing';
import ContactUs from './contact/Contact';
import ForgotPassword from "./reset/ForgotPassword";
import KycAssessment from "./kycAssessment/KycAssessment";
import SSOLogin from "./ssoLogin/SSOLogin";
const {isIWSSOEnable} = require('../../utility/configurations.json')

class PreLoginContainer extends React.Component {

    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                {/* <PreNavBar/> */}
                    <Switch>
                        <Route exact path="/pricing"><Pricing/></Route>
                        <Route exact path="/contact" component={ContactUs}></Route>
                        <Route exact path="/signin" component={isIWSSOEnable?SSOLogin:Signin}/>
                        <Route exact path="/register" component={Register}/>
                        <Route exact path="/ssologin" component={SSOLogin}/>
                        <Route exact path="/reset/password"><ForgotPassword/></Route>
                        <Route exact path="/KYC" component={KycAssessment}/>
                        <Route path="/" component={isIWSSOEnable?SSOLogin:Signin}></Route>
                    </Switch>
                </BrowserRouter>
            </React.Fragment>
        )
    }
}

export default PreLoginContainer;