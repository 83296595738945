import Wrapper from './../../Wrapper';
import * as React from 'react';
import './batchTable.css'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { postData } from '../../../../utility/networkCall';
import loader from './../../../../assets/load.svg'
import { style } from '@mui/system';
import { countryLookUp } from '../../../../utility/countryList';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'scannedName',
        numeric: false,
        disablePadding: false,
        label: 'Scanned Name',
    },
    {
        id: 'dob',
        numeric: false,
        disablePadding: false,
        label: 'DOB',
    },
    {
        id: 'identifier',
        numeric: false,
        disablePadding: false,
        label: 'Identifier',
    },
    {
        id: 'nationality',
        numeric: false,
        disablePadding: false,
        label: 'Nationality',
    },
    {
        id: 'address',
        numeric: false,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'clickToScan',
        numeric: false,
        disablePadding: false,
        label: 'Click to Scan',
    },
];


function EnhancedTableHead(props) {
    // let headCells = props.headCells
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};






export default function BatchTable(props) {
    const rows=props.tableData
    // console.log(rows[1].name)
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(headCells.scannedName);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    var countryData = {};
    {countryLookUp.map((key, i) => (
        countryData[key.countrycode] = key.countryDesc
    ))}


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")

    const rowClickHandler = (row) => {
        props.setCurrentScan(row)

        
    }
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    return (
        <React.Fragment>

            {props.wrapper ? <Wrapper title={props.title}>
                {loading ? <div style={{ textAlign: "center", lineHeight: "50px" }}><img alt='' src={loader} height={"50px"} style={{ display: "block", margin: "auto" }}></img> <span style={{ color: "#EB2188" }}>Please Wait, Downloading Report.</span></div> : null}
                {message !== "" && !loading ? <div style={{ textAlign: "center", lineHeight: "50px" }}> <span style={{ color: "#EB2188" }}>{message}</span></div> : null}

                <TableContainer>
                    <Table
                        aria-labelledby={"History"}
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            fontWeight="bolder"
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    // console.log(row)
                                    return (
                                        <TableRow style={{cursor:'pointer'}}
                                            className={` ${props.currentScan === row ? 'active-row' : 'tr'}`}
                                            // hover
                                            tabIndex={-1}
                                            key={row.name}
                                            onClick={() => { rowClickHandler(row) }}
                                        >
                                            
                                            <TableCell  >{row[0][0]+" "+row[0][1]+" " +row[0][2]}</TableCell>
                                            <TableCell >{row[0][3]}</TableCell>
                                            <TableCell>{row[0][4]}</TableCell>
                                            <TableCell>{countryData[row[0][5]] }</TableCell>
                                            <TableCell>{row[0][6] }</TableCell>
                                            <TableCell style={{color:'#234e70',fontWeight:'bold'}}>{'Scan Details'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* {loading?<div style={{textAlign:"center"}}><img alt='' src={ loader } height={"50px"}></img></div>:null} */}
            </Wrapper>
                :
                <div style={{ background: "white", boxShadow: "box-shadow: 3px 3px 5px #888888", marginTop: "5px" }}>
                    {loading ? <div style={{ textAlign: "center", lineHeight: "50px" }}><img alt='' src={loader} height={"50px"} style={{ display: "block", margin: "auto" }}></img> <span style={{ color: "#EB2188" }}>Please Wait, Downloading Report.</span></div> : null}
                    <TableContainer>
                        <Table
                            aria-labelledby={"History"}
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={headCells}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow style={{cursor:'pointer'}}
                                            className={` ${props.currentScan === row ? 'active-row' : 'tr'}`}
                                                hover
                                                tabIndex={-1}
                                                key={row.name}
                                                onClick={() => { rowClickHandler(row) }}
                                            >
                                                <TableCell >{row[0][0]+" "+row[0][1]+" " +row[0][2]}</TableCell>
                                            <TableCell>{row[0][3]}</TableCell>
                                            <TableCell>{row[0][4]}</TableCell>
                                            <TableCell>{countryData[row[0][5]] }</TableCell>
                                            <TableCell>{row[0][6] }</TableCell>
                                            <TableCell style={{color:'green'}}>{'Scan Details'}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </div>

            }
        </React.Fragment>

    );
}



