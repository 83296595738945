import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import './SettingsPage.css';
import MyProfile from './MyProfile/MyProfile';
import ManagePassword from './ManagePassword/ManagePassword'

const SettingsPage = (props) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div className="App">
{/* 
            <div className="settingsHeader">

                <h1>Settings</h1>
            </div> */}
            <div className="tabsContainer">
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="tabs">
                    <Tab label="My Profile" />
                    <Tab label="Password Setup" />
                </Tabs>
            </div>
            <div className="tab-content">
                {activeTab === 0 && <div><MyProfile data={props}/></div>}
                {activeTab === 1 && <div><ManagePassword data={props}/></div>}
            </div> 
        </div>
    );
}

export default SettingsPage;
