import React from 'react';
import './dashboardScreen.css';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ScannerOutlinedIcon from '@material-ui/icons/ScannerOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { withRouter } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import {
    Button
} from "@material-ui/core";
import Wrapper from '../../shared/Wrapper';


class Dashboard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        // const buttons = [
        //     <Button class="button-white" startIcon={<ShoppingCartOutlinedIcon />} href="/pricing" /*onClick={()=>{this.props.history.push('/pricing');this.props.changelocation('/pricing')}}*/>Purchase Scan Credit</Button>,
        //     <Button class="button-white" startIcon={<ScannerOutlinedIcon />} href="/singlescan" /* onClick={()=>{this.props.history.push('/singlescan')}}*/>Single Scan</Button>,
        //     <Button class="button-white" startIcon={<DocumentScannerOutlinedIcon />} href="/batchscan" /*onClick={()=>{this.props.history.push('/batchscan')}}*/>Batch Scan</Button>]
        return (
            <>
                <div class="dashboard-main-container" >
                    <div className='greeting'>
                        <span className='greeting-span-name'>Hey {this.props.userdetails.first_name.toUpperCase()},</span><br />
                        <span className='greeting-span-welcome'>Welcome to the IntelleWings Dashboard!<br />
                            Use this dashboard to perform scans, check scan & order history, manage API & lot more. If you have any questions about how to get started, feel free to
                            <a className='ds-anchortag' href='/contact'>&nbsp;contact us </a> at anytime.</span>
                    </div>

                    <div className="statastics-main-container">
                        <div className='statastics-container'>
                            <div className="statastics-box">
                                <h3>Total Scans</h3>
                                <h2>{this.props.userData[0].total_checks}</h2>
                            </div>
                            <div className="statastics-box">
                                <h3>Scans Available</h3>
                                <h2>{this.props.userData[0].checks_left}</h2>
                            </div>
                            <div className="statastics-box">
                                <h3>Total Scans Used</h3>
                                <h2>{this.props.userData[0].checks_used}</h2>

                            </div>
                        </div>

                        <div className='statastics-container'>

                            <div className="statastics-box">
                                <h3>Single Scans</h3>
                                <h2>{this.props.userData[0].single_scans}</h2>
                            </div>
                            <div className="statastics-box">
                                <h3>Batch Scans</h3>
                                <h2>{this.props.userData[0].batch_scans}</h2>

                            </div>
                            <div className="statastics-box">
                                <h3>API Scans</h3>
                                <h2>{this.props.userData[0].api_scans}</h2>

                            </div>
                        </div>

                    </div>

                    {/* <Wrapper title={"My Balances"} buttons={buttons}>
                        {this.props.children} */}
                    <div className="pie-holder">
                        <div>
                            <h2>Available Credits</h2>
                            <h3 style={{textAlign:'center'}}>{this.props.userData[0].checks_left}</h3>
                        </div>

                        <div className="pie-data">
                            <div className="data">
                                <p>Total Scans : {this.props.userData[0].total_checks}</p>
                                <p>Scans Available : {this.props.userData[0].checks_left}</p>
                                <p>Scans Used : {this.props.userData[0].checks_used}</p>
                            </div>
                            <div className="pie">
                                <PieChart
                                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}

                                    lineWidth={60}
                                    data={[
                                        { title: 'Checks Used', value: this.props.userData[0].checks_used, color: '#EB2188' },
                                        { title: 'Checks Left', value: this.props.userData[0].checks_left, color: '#234E70' },

                                    ]}
                                    //radius={PieChart.defaultProps.radius - 7}
                                    segmentsShift={0.5}
                                />
                            </div>
                        </div>

                        <div className="pie-data">
                            <div className="data">
                                <p>Single Scans : {this.props.userData[0].single_scans}</p>
                                <p>Batch Scans : {this.props.userData[0].batch_scans}</p>
                                <p>API Scans : {this.props.userData[0].api_scans}</p>
                            </div>
                            <div className="pie">
                                <PieChart

                                    segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}

                                    lineWidth={60}
                                    data={[
                                        { title: 'Single Scans', value: this.props.userData[0].single_scans, color: '#EB2188' },
                                        { title: 'Batch Scans', value: this.props.userData[0].batch_scans, color: '#234E70' },
                                        { title: 'API Scans', value: this.props.userData[0].api_scans, color: '#4566e0' },
                                    ]}
                                    //radius={PieChart.defaultProps.radius - 7}
                                    segmentsShift={0.5}
                                />
                            </div>
                        </div>

                    </div>
                    {/* </Wrapper> */}
                </div>
            </>
        )
    }
}

export default withRouter(Dashboard);
