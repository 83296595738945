import React, { useState, useEffect, useRef } from "react";
import { postData } from '../../../../utility/networkCall';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from "react-hook-form";
import {
    TextField,
    Button,
    IconButton
} from "@material-ui/core";
import './ManagePassword.css'


const
{enc, pad, AES, mode,SHA1 }=
require('crypto-js')
;
const set=(keys, value)=>{
  const key = enc.Utf8.parse(keys);
  const passwd = enc.Utf8.parse(value.toString());
  
  const encrypted = AES.encrypt(passwd, key,
  {
      keySize: 128 / 8,
      iv: key,
      mode: mode.CBC,
      padding: pad.Pkcs7
  });

  return encrypted.toString();
}

const ManagePassword = (props) => {
    console.log(props, "props");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState("")
    const [profile, setProfile] = useState({ confirm_current_password: "" })


    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();


    useEffect(() => {
        setProfile({ confirm_current_password: "" })
    }, [props])


    const onSubmitPassword = (data) => {
        data.email = sessionStorage.getItem('email')
        let current_password = data.current_passowrd;
        let new_password = data.new_password;

        data.current_passowrd = set(data.email,current_password);
        
        data.new_password = set(data.email, new_password);
        console.log(data,"data");
        console.log("Data Submitted.....");
        postData('update_password', data)
            .then(d => {
                if (d === "success") {
                    setMessage("Password Changed Successfully.")
                    setTimeout(() => {
                    }, 3000)
                }
                else if (d === "Incorrect Current Password."|| d==="Incorrect Current Password!!!") {
                    setMessage("Incorrect Current Password.")
                    setTimeout(() => {
                    }, 3000)
                }else if(d==="You can not use an old password"){
                    setMessage("You can not use an old password");
                    setTimeout(()=>{
                    },3000)

                }
                else {
                    setMessage("Network Error. Kindly change the Password again.")
                    setTimeout(() => {
                    }, 3000)
                }

            }).catch(e => {
                setMessage("Network Error")
            })
    };

    return (
        <>
            <div className="main-div">
                <form onSubmit={handleSubmit(onSubmitPassword)}>
                    <div className='setting-divDivide'>
                        <p>Current Password <span id='setting-requiredField'> *</span></p>
                        <TextField className='setting-fieldInput'
                            variant="outlined"
                            label="Current Password"
                            margin="normal"
                            size='small'
                            fullWidth
                            name="current_passowrd"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...register('current_passowrd', { required: " Current Password is required" })}
                            error={Boolean(errors.current_passowrd)}
                            helperText={errors.current_passowrd?.message}
                        />
                    </div>

                    <div class='setting-divDivide'>
                        <p>New Password <span id='setting-requiredField'> *</span></p>

                        <TextField className='setting-fieldInput'
                            variant="outlined"
                            label="New Password"
                            margin="normal"
                            size='small'
                            name="new_password"
                            fullWidth
                            type={showNewPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                        >
                                            {showNewPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...register('new_password', {
                                required: "Password is required",
                                validate: (value) =>
                                    value.length > 5 || "Password must contain atleast 6 characters "
                            })}
                            error={Boolean(errors.new_password)}
                            helperText={errors.new_password?.message}
                        />
                    </div>
                    <div class='setting-divDivide'>
                        <p>Confirm Password <span id='setting-requiredField'> *</span></p>

                        <TextField className='setting-fieldInput'
                            variant="outlined"
                            label="Confirm Password"
                            margin="normal"
                            size='small'
                            fullWidth
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                        >
                                            {showConfirmPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...register('confirmPassword', {
                                required: "Confirm Password is required",
                                validate: (value) =>
                                    value === getValues("new_password") || "Password and Confirm Password do not match."
                            })}
                            error={Boolean(errors.confirmPassword)}
                            helperText={errors.confirmPassword?.message}
                        />
                    </div>

                    <div className='setting-button-div'>
                        <Button type='submit' className="ChangeBtn1">Change Password</Button>
                    </div>

                    {message?<div className={message==="Password Changed Successfully."?'success':'error'}>
                        {message}
                    </div>:<></>}
                </form>
               
            </div>


        </>
    );

}


export default ManagePassword;

