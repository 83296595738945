import React, { useState ,useEffect} from 'react';
import { TextField,Button } from '@mui/material'; 
import { postData } from '../../../../utility/networkCall';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ServiceAgreement from "../../../prelogin/serviceAgreement/serviceAgreement";
import './MyProfile.css';

const MyProfile = (props) => {
    const [serviceAgreementModalOpen, setserviceAgreementModalOpen] =
    useState(false);
    const [profile, setProfile] = useState({ first_name: props.data.first_name, last_name: props.data.last_name, org_name: props.data.organization, contact: props.data.contact, industry_type: props.data.industry, country: props.data.country, logo: props.data.logo, is_otp_login: props.data.is_otp_login, confirm_current_password: "" });
    const [image, setImage] = useState(profile.logo);
    const [data1, setData1] = useState({ email: sessionStorage.getItem('email'), flag: false })
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    
    const [editable, setEditable] = useState(false);
    useEffect(() => { setImage(profile.logo) }, [profile])
    const handleImageChange = (ev) => {
        ev.preventDefault();
        
        let file = ev.target.files[0]
        var reader = new FileReader();
        
        
        reader.readAsDataURL(file);
        
        reader.onload = function () {
            let base64String = reader.result.replace("data:", "")
            .replace(/^.+,/, "");
            console.log(base64String,"BAse64string")
            saveNewLogo(base64String);
            setImage(base64String)
            
        }
    };
    
    const openTermsAndConditons =()=>{
        setserviceAgreementModalOpen(true);
    }

    const closeServiceAgreementModal = () => {
        setserviceAgreementModalOpen(false);
      };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if(value.trim().length>0){
            console.log("setting error null-->>",name)
        setErrors({ ...errors, [name]: null });
        }
        if(name=="firstName"){
            firstName(value);
        }
        else if(name=="lastName"){
            lastName(value);
        }
        else if(name=="organizationName"){
            orgName(value)
        }
        else if(name=="industryType"){
            industryType(value)
        }
        else if(name=="contactNumber"){
            contactNo(value)
        }
        else if(name=="country"){
            countryName(value)
        }
    };
    const handleRemoveImage = () => {
         saveNewLogo("");
         setImage(null);
    };
    const handleEditClick = () => {
        setEditable(!editable);
    };

    const handleUpdateClick = () => {
        if (profile.first_name.trim() === '') {
            setErrors({ ...errors, firstName: 'First Name is required' });
            return;
        }
        if(profile.last_name.trim()===''){
            setErrors({...errors, lastName:'Last Name is required'});
            return;
        }
        setOpen(true);
        setEditable(editable);
        setData1({ ...data1, data: profile, flag: true })
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    

    useEffect(() => {
        if (data1.flag) {
            postData('update_profile', data1)
                .then(d => {
                    data1.flag = false
                    if (d === "success") {
                        setMessage("Profile Updated Successfully.")
                        props.data.dataUpdateHandler({
                            first_name: data1.data.first_name,
                            last_name: data1.data.last_name,
                            country: data1.data.country,
                            industry: data1.data.industry_type,
                            organization: data1.data.org_name,
                            contact: data1.data.contact,
                        })

                    }
                   
                    else {
                        setMessage("Error, Kindly update the profile again!")
                    }
                }).catch(e => {
                    data1.flag = false
                    setMessage("Network Error")
                })
        }
    }, [data1.flag])


    function saveNewLogo(imageData) {
        if (imageData === null) {
            return;
        }
        if (true) {
            postData('updateLogo', { file: imageData }, {})
                .then(d => {
                    if (d.message === "logo updated successfully.") {
                        props.data.dataUpdateHandler({ logo: imageData })
                        setProfile({ ...profile, logo: imageData });
                    }
                })
                .catch(e => {
                   console.log("Error Occurred" ,e);
                })
        }
    }

    useEffect(() => {
        setProfile({ first_name: props.data.first_name, last_name: props.data.last_name, org_name: props.data.organization, contact: props.data.contact, industry_type: props.data.industry, country: props.data.country, logo: props.data.logo, is_otp_login: props.data.is_otp_login, confirm_current_password: "" })
    }, [props])
    const firstName = (e) => {
        setProfile({ ...profile, first_name: e })
    }

    const lastName = (name) => {
        setProfile({ ...profile, last_name: name })
    }

    const orgName = (e) => {
        setProfile({ ...profile, org_name: e})
    }
    const contactNo = (e) => {
        setProfile({ ...profile, contact: e })
    }
    const industryType = (e) => {
        setProfile({ ...profile, industry_type: e})
    }
    const countryName = (e) => {
        setProfile({ ...profile, country: e })
    }


    return (
        <>
        <div className='main-container'>
           
            <div className='profileimagecontainer'>

                <div className='imagebox'>
                    {image && (
                        <img
                            src={"data:image/png;base64, " + profile.logo}
                            alt="Uploaded"
                            style={{maxWidth: '100%', maxHeight: '100%' }}
                        />
                    )}
                </div>
                <div className='upload-buttons'>
                    <Button
                        variant="contained"
                        component="label"
                        style={{marginRight:"3px",backgroundColor:'#234E70'}}
                    >
                        Upload
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                        />
                    </Button>
                    {image && (
                        <Button
                            variant="contained"
                            style={{backgroundColor:"#EB2188"}}
                            onClick={handleRemoveImage}
                        >
                            Remove
                        </Button>
                    )}
                </div>
                
            </div>
            <div className='form-container'>
                {/* Right Section - Form */}

                    <div className='inputgroup'>
                        <span><b>First Name</b></span>
                        {editable ? (
                            <TextField
                                label="First Name"
                                variant="outlined"
                                // margin="normal"
                                name="firstName"
                                size="small"
                                value={profile.first_name}
                                onChange={handleInputChange}
                                error={Boolean(errors.firstName)}
                                helperText={errors.firstName}
                                required
                            />
                        ) : (
                            <p className='text'>{profile.first_name}</p>
                        )}
                    </div>
                    <div className='inputgroup'>
                        <span><b>Last Name</b></span>
                        {editable ? (
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            // margin="normal"
                            name="lastName"
                            size="small"
                            value={profile.last_name}
                            onChange={handleInputChange}
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name}
                            required
                        />
                        ):(
                            <p className='text'>{profile.last_name}</p>
                        )}
                    </div>
                    <div className='inputgroup'>
                        <span><b>Organization Name</b></span>
                        {editable ? (
                        <TextField
                            label="Organization Name"
                            variant="outlined"
                            // margin="normal"
                            size="small"
                            name="organizationName"
                            value={profile.org_name}
                            onChange={handleInputChange}
                        />
                        ):(
                            <p className='text'>{profile.org_name}</p>
                        )}
                    </div>
                    <div className='inputgroup'>
                        <span><b>Contact Number</b></span>
                        {editable ? (
                        <TextField
                            label="Contact Number"
                            variant="outlined"
                            // margin="normal"
                            size="small"
                            name="contactNumber"
                            value={profile.contact}
                            onChange={handleInputChange}
                            
                        />
                        ):(
                            <p className='text'>{profile.contact}</p>
                        )}
                    </div>
                    <div className='inputgroup'>
                        <span><b>Industry Type</b></span>
                        {editable ? (
                        <TextField
                            label="Industry Type"
                            variant="outlined"
                            size="small"
                            // margin="normal"
                            name="industryType"
                            value={profile.industry_type}
                            onChange={handleInputChange}
                        />
                        ):(
                            <p className='text'>{profile.industry_type}</p>
                        )}
                    </div>
                    <div className='inputgroup'>
                        <span><b>Country Name</b></span>
                        {editable ? (
                        <TextField
                            label="Country"
                            variant="outlined"
                            size="small"
                            // margin="normal"
                            name="country"
                            value={profile.country}
                            onChange={handleInputChange}
                            disabled={!editable}
                        />
                        ):( <p className='text'>{profile.country}</p>)}
                    </div>
                    {!editable &&<div className='inputgroup'>
                        <span><b>Service Agreement</b></span>
                             <p className="text" onClick={openTermsAndConditons} style={{color:'#234E70',cursor:"pointer"}}>View</p>
                        </div>}

                        

            </div>
          
        </div>
          <div className='button-container'>
          {!editable ? (
              <Button onClick={handleEditClick} variant="contained" style={{backgroundColor:'#234E70'}}>
                  Edit
              </Button>
          ) : (
              <Button onClick={handleUpdateClick} variant="contained" style={{backgroundColor:'#234E70'}}>
                  Update
              </Button>
          )}
      </div>
      <div>
      <ServiceAgreement
                isProfileView ={false}
                  open={serviceAgreementModalOpen}
                  onClose={closeServiceAgreementModal}
                />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical: 'center', horizontal: 'center' }} sx={{marginLeft:'10%'}}>
        {message!="" ?
        <Alert
          onClose={handleClose}
          severity={message === "Profile Updated Successfully." ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%'}}
        >
         {message}
        </Alert>
:null}
      </Snackbar>
    </div>
      </>
        
    );
}

export default MyProfile;
