import React, { useState } from 'react';
import './adverseResult.css';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TablePagination from '@mui/material/TablePagination';
import AltUserImage from './../../../../assets/user-alt.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(1),
        width: '95%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'auto',
    },
    paper2: {
        width: '95%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));

const AdverseResult = (props) => {
   
    console.log(props, "adverse result")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayResult = props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
            let patterns = item['keywordUsedForIdentifingTheArticle'].split(',')
            return (
                

                    <div className='adverse-main-container' >
                        <div className='adverse-main-container-div1'>
                            {item.imgs.length > 0 ?
                                <img alt='' src={item.imgs[0]}  width='160' height='160' ></img>
                                :
                                <img alt='' src={AltUserImage} width='160' height='160'></img>
                            }
                        </div>
                        <div className='adverse-main-container-div2' >
                            <div className='adverse-div2-div1'>
                                <div style={{color:"#234E70",fontWeight:"bold"}}>Article Date: {item['articleDate'] !==""?item['articleDate'].substring(0, 10):"Not Found"}</div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                <div className='adverse-match-div'>{"Match : " + item.matchScore.toFixed(2) + "%"}</div>
                                    {/* {adverseHidden?<ExpandMoreIcon/>:<ExpandLessIcon/>} */}
                                </div>
                                
                            </div>
                            <a href={item['articleURL']} className="adverse-link" target='_blank'>Source: <p>{item['articleURL']}</p></a>
                            
                            <div className={'adverse-details-div1 '} >
                                <div style={{marginTop:"10px",marginLeft:"0"}}>
                                    Pattern Match: <span>{
                                        patterns.map(pattern=>{
                                            return (
                                                <>
                                                {pattern.trim().length>0?
                                                <div style={{boxShadow:"1px 1px 2px #888888", width:"min-content", display:"inline", backgroundColor:"white", margin:"5px", padding:"3px 5px 3px 5px", borderRadius:"5px", fontWeight:"bold", fontSize:"14px"}}>{pattern.toUpperCase()}</div>
                                               
                                                 :null} 
                                                 </>
                                            )
                                        })
                                        }</span>
                                </div>
                                {/* <div>Full Name Score: <span> {item.matchScore.toFixed(2) + " %"}</span></div> */}
                                <div>
                                    {item['organizationNameMentionedInTheNews'].length < 70 ?
                                        <div>Organization Name Mentioned: <span>{item['organizationNameMentionedInTheNews']}</span></div>
                                        :

                                        <div className='item'>
                                            <div className='title' >
                                                <div> Organization Name Mentioned: <span>{item['organizationNameMentionedInTheNews']}</span></div>
                                               
                                            </div>
                                            
                                        </div>
                                    }
                                </div>

                                <div>
                                    {item['personNameMentionedInTheNews'].length < 70 ?
                                        <div>Person Name Mentioned:  <span>{item['personNameMentionedInTheNews']}</span></div>
                                        : <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> Person Name Mentioned: <span>{item['personNameMentionedInTheNews']}</span></div>
                                                   
                                                </div>
                                               
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    {item['cityStateMentionedUnderTheNews'].length < 70 ?
                                        <div>City/State Mentioned: <span>{item['cityStateMentionedUnderTheNews']}</span></div>
                                        :
                                        <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> City/State Mentioned: <span>{item['cityStateMentionedUnderTheNews']}</span></div>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                {item['personMatched'].length < 70 ?
                                        <div>Person Name Matched: <span>{item['personMatched']}</span></div>
                                        :
                                        <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> Person Name Matched: <span>{item['personMatched']}</span></div>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div>
                                {item['age'].length < 70 ?
                                        <div>Person Age Matched: <span>{item['age']}</span></div>
                                        :
                                        <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> Person Age Matched: <span>{item['age']}</span></div>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div>
                                {item['cityMap'].length < 70 ?
                                        <div>Person  City Matched: <span>{item['cityMap']}</span></div>
                                        :
                                        <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> Person  City Matched: <span>{item['cityMap']}</span></div>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* <div>
                                {item['adverseUuid'].length < 70 ?
                                        <div>Unique Id: <span>{item['adverseUuid']}</span></div>
                                        :
                                        <div className='accor'>
                                            <div className='item'>
                                                <div className='title'>
                                                    <div> Unique Id: <span>{item['adverseUuid']}</span></div>
                                                  
                                                </div>
                                                
                                            </div>
                                        </div>
                                    }
                                </div> */}

                            </div>
                        </div>
                    </div>

           
            );
        });

    return (
        <div>
            {props.data.length===0 && <div className='not-found'> Result not found in adverse media's List check other Lists for more Details</div>}
            {displayResult}
            <div className='adverse-footer'>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={props.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>

    )
}

export default AdverseResult

