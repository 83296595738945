import React, { useState, useRef, useEffect } from 'react';
import './CustomerProfileDetails.css';
import { countryLookUp } from '../../../../utility/countryList';
import {
  TextField,
  Select,
  MenuItem,
  Button
} from "@material-ui/core";

const CustomerProfileDetails = (props) => {
    const [profile, setProfile] = useState({ scanType: "single scan", fileName: "file", scan_state: "not ready", searchInList: props.userInput.searchInList, adverseSelected: props.userInput.adverseSelected })
    const [orgSetup, setOrgSetup] = useState(props.userInput.entity)
    const [identValue, setIdentValue] = useState('');
    const [identList, setIdentList] = useState(props.userInput.identifier)
    const firstName = (e) => {
      setProfile({ ...profile, first_name: e.target.value.trim() })
    }
  
    const middleName = (e) => {
      setProfile({ ...profile, middle_name: e.target.value.trim() })
    }
  
    const lastName = (e) => {
      setProfile({ ...profile, last_name: e.target.value.trim() })
    }
    const address = (e) => {
      setProfile({ ...profile, address: e.target.value })
    }
    const identifierValue = (e) => {
      setIdentValue(e.target.value)
    }
  
    const dob = (e) => {
      setProfile({ ...profile, dob: e.target.value.trim() })
    }
  
    const listType = (e) => {
      setProfile({ ...profile, searchInList: e.target.value.trim() })
    }
  
    const nationality = (e) => {
      setProfile({ ...profile, nationality: e.target.value.trim() })
    }
  
    const entityType = (e) => {
      setProfile({ ...profile, entity: e.target.value.trim(), last_name: "", middle_name: "", dob: "" })
  
      setOrgSetup(e.target.value.trim())
  
  
    }
    const adverseMedia = (val) => {
      setProfile({ ...profile, adverseSelected: val })
    }
    const ref = useRef(null)
  
    const handleCollection = (e) => {
        console.log(profile, "profile customerprofiledetails")
      e.preventDefault()
      if (ref.current.reportValidity()) {
  
        props.handleInput({ ...profile })
        if (profile.searchInList === "PEP") {
          props.setActiveStep(2)
        }
        else {
          props.setActiveStep(1)
        }
      }
  
    }
  
    const handleDelete = (toBeRemoved) => {
      setIdentList(identList.filter(email => email !== toBeRemoved))
    };
  
    const handleKeyDown = (evt) => {
      if (['Enter', 'Tab', ','].includes(evt.key)) {
        evt.preventDefault();
  
        var identifierText = identValue.trim();
        if (identifierText) {
          setIdentList([...identList, identifierText])
          setIdentValue('')
  
        }
      }
    };
    useEffect(() => {
      setProfile({ ...profile, identifier: identList })
    }, [identList]);
  
  
    return (
      <form method="post" ref={ref} size="small">
        <div className="ss-input-container">
          <div className="ss-input-holder">
            {orgSetup === "Organization" ? <p>Full Name<span style={{ color: "red" }}>*</span></p> : <p>First Name<span style={{ color: "red" }}>*</span></p>}
            <TextField id="first-name" size='small' variant="outlined" placeholder={orgSetup === "Organization" ? "Organization Full Name" : "First Name"} required fullWidth onChange={firstName} defaultValue={props.userInput.first_name !== undefined ? props.userInput.first_name : ""} />
          </div>
          <div className="ss-input-holder">
            <p style={{ display: orgSetup === "Organization" ? "none" : "block" }}>Middle Name</p>
            <TextField style={{ display: orgSetup === "Organization" ? "none" : "block" }} id="middle-name" size='small' variant="outlined" placeholder="Middle Name" fullWidth onChange={middleName} defaultValue={props.userInput.middle_name !== undefined ? props.userInput.middle_name : " "} />
          </div>
          <div className="ss-input-holder" style={{ display: orgSetup === "Organization" ? "none" : "block" }}>
            <p>Last Name<span style={{ color: "red" }}>*</span></p>
            <TextField id="last-name" size='small' variant="outlined" placeholder="Last Name" required={orgSetup === "Organization" ? false : true} fullWidth onChange={lastName} defaultValue={props.userInput.last_name !== undefined ? props.userInput.last_name : ""} />
          </div>
          <div className="ss-input-holder" style={{ display: orgSetup === "Organization" ? "none" : "block" }}>
            <p>DOB</p>
            <TextField id="dob" size='small' variant="outlined" placeholder="YYYY/MM/DD" fullWidth onChange={dob} defaultValue={props.userInput.dob !== undefined ? props.userInput.dob : " "} />
          </div>
          <div className="ss-input-holder">
            <p>List Type</p>
            <Select id="list-type" defaultValue="All"  fullWidth variant="outlined" onChange={listType} defaultValue={props.userInput.searchInList}>
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"PEP"}>PEP</MenuItem>
              <MenuItem value={"Sanction"}>Sanction</MenuItem>
            </Select>
          </div>
          <div className="ss-input-holder">
            <p>Identifier <span style={{ fontSize: "10px" }}>(unique identification no. i.e. passport number)</span></p>
            {identList.map(email => (
              <div className="tag-item" key={email}>
                {email}
  
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(email)}
                >
                  &times;
                </button>
              </div>
            ))}
            <TextField id="identifier" variant="outlined" placeholder="Identifier" fullWidth onChange={identifierValue} onKeyDown={handleKeyDown} value={identValue} defaultValue={props.userInput.identifier !== undefined ? props.userInput.identifier : " "} >
  
            </TextField>
  
          </div>
          <div className="ss-input-holder">
            <p>Entity type</p>
            <Select id="entity-type" fullWidth variant="outlined" onChange={entityType} defaultValue={props.userInput.entity}>
              <MenuItem value={"Individual"}>Individual</MenuItem>
              <MenuItem value={"Organization"}>Organization</MenuItem>
            </Select>
          </div>
  
          <div className="ss-input-holder">
            {orgSetup === "Organization" ? <p>Place of Registration</p> : <p>Nationality</p>}
            <Select id="nationality" fullWidth variant="outlined" onChange={nationality} defaultValue={props.userInput.nationality} >
              {countryLookUp.map((key, i) => (
                <MenuItem value={key.countrycode}>{key.countryDesc}</MenuItem>
              ))}
            </Select>
          </div>
          <div className='ss-input-holder'>
            <p>Address</p>
            <TextField id="address" variant="outlined" placeholder="Address" fullWidth onChange={address} defaultValue={props.userInput.address !== undefined ? props.userInput.address : " "} >
            </TextField>
          </div>
  
          <div className="ss-input-holder">
            <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', paddingTop: "60px" }}>
              <div style={{ paddingTop: "10px" }}>Adverse Media : &nbsp;</div>
              <div className="adverse-holder"><div className={profile.adverseSelected === 0 ? "adverse adverse-active-pink" : "adverse"} onClick={() => { adverseMedia(0) }}>Excluded</div><div onClick={() => { adverseMedia(1) }} className={profile.adverseSelected === 1 ? "adverse adverse-active-blue" : "adverse"} > Included</div></div>
            </div>
          </div>
          <div className="ss-input-holder"></div>
          <div className="ss-input-holder">
            <div className="button-holder">
              <Button class="button-blue" type="submit" onClick={handleCollection}>Next</Button>
            </div>
          </div>
        </div>
      </form>
    );
}
export default CustomerProfileDetails;