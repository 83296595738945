import React, { useState, useEffect, useRef } from "react";
import './setting.css';

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from '@material-ui/core/InputAdornment';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import {
    TextField,
    Button,
    IconButton
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { postData } from './../../../utility/networkCall';
import loader from './../../../assets/load.svg';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Setting = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = ( newValue) => {
      setValue(newValue);
    };
  
    const [profile, setProfile] = useState({ first_name: props.first_name, last_name: props.last_name, org_name: props.organization, contact: props.contact, industry_type: props.industry, country: props.country, logo: props.logo, is_otp_login: props.is_otp_login, confirm_current_password: "" })

    const [base64img, setbase64Img] = useState(profile.logo)

    useEffect(() => { setbase64Img(profile.logo) }, [profile])
    const fileAddEvent = (ev) => {
        console.log('File(s) dropped');
        console.log(ev)
        ev.preventDefault();

        let file = ev.target.files[0]
        var reader = new FileReader();


        reader.readAsDataURL(file);

        reader.onload = function () {
            let base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");
            setbase64Img(base64String)
        }

    }

    function saveNewLogo() {
        if (base64img === null) {
            //window.alert("Please select ")
            return;
        }
        setLoading(true)
        if (!loading) {
            postData('updateLogo', { file: base64img }, {})
                .then(d => {
                    if (d.message === "logo updated successfully.") {
                        props.dataUpdateHandler({ logo: base64img })
                        setProfile({ ...profile, logo: base64img });
                    }
                    setLoading(false)
                    setAlert(true)
                    setMessage("Image updated successfully.")
                    setTimeout(() => {
                        setAlert(false)
                    }, 3000)

                })
                .catch(e => {
                    setLoading(false)
                    setAlert(true)
                    setMessage("Network error, please try again after some time.")
                    setTimeout(() => {
                        setAlert(false)
                    }, 3000)
                })
        }
    }




    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);


    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);


    const [status, setStatus] = React.useState(1) // 0: no show, 1: show yes, 2: show no.
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const [enabler, setEnabler] = useState(true);
    const handleClickEnabler = () => {
        setEnabler(!enabler);
        setEdit(!edit)
    }
    const [edit, setEdit] = useState(false);
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const [isDetailsValid, setIsDetailsValid] = useState(false)
    const [checked, setChecked] = useState(props.is_otp_login);
    const [isTfa,setIsTfa]=useState();
    const [data1, setData1] = useState({ email: sessionStorage.getItem('email'), flag: false })
    const [updatePopup, setUpdatePopup] = useState(false)

useEffect(()=>{
    if(props.is_otp_login==1){
        setIsTfa(true)
        setChecked(1)
    }
    else{
        setIsTfa(false)
        setChecked(0)
    }
},[props])

    useEffect(() => {
        setProfile({ first_name: props.first_name, last_name: props.last_name, org_name: props.organization, contact: props.contact, industry_type: props.industry, country: props.country, logo: props.logo, is_otp_login: props.is_otp_login, confirm_current_password: "" })
    }, [props])
    const firstName = (e) => {
        setProfile({ ...profile, first_name: e.target.value })
    }

    const lastName = (e) => {
        setProfile({ ...profile, last_name: e.target.value })
    }

    const orgName = (e) => {
        setProfile({ ...profile, org_name: e.target.value })
    }
    const contactNo = (e) => {
        setProfile({ ...profile, contact: e.target.value })
    }
    const industryType = (e) => {
        setProfile({ ...profile, industry_type: e.target.value })
    }
    const countryName = (e) => {
        setProfile({ ...profile, country: e.target.value })
    }

    const confirmCurrentPassword = (e) => {
        setProfile({ ...profile, confirm_current_password: e.target.value })
    }

    const handleToggleChange = (e) => {

        if (checked == 0) {
            setChecked(1);
            setIsTfa(true)
            setProfile({ ...profile, is_otp_login: 1 })
        }
        else if(checked==1) {
            setChecked(0);
            setIsTfa(false)
            setProfile({ ...profile, is_otp_login: 0 })
        }
    };
    const ref = useRef(null)
    const radioHandler = (status) => {
        setStatus(status);
    };

    const handleUpdatePopup = () => {
        setUpdatePopup(true)
    };

    const onSubmitProfile = (e) => {
        e.preventDefault()
        
        if (loading) {
            return;
        }
        //if (edit) {
        if (ref.current.reportValidity()) {
            profile.first_name = profile.first_name.trim()
            profile.last_name = profile.last_name.trim()
            profile.org_name = profile.org_name.trim()
            if ( profile.contact!=null && profile.contact.length > 0 ) {
                profile.contact = profile.contact.trim()
            }
            if (profile.industry_type!=null && profile.industry_type.length > 0  ) {
                profile.industry_type = profile.industry_type.trim()
            }
            profile.country = profile.country.trim()
            if (profile.first_name.length > 0 && profile.last_name.length > 0 && profile.org_name.length > 0 && profile.country.length > 0) {
                setAlert(true)
                setLoading(true)
                setData1({ ...data1, data: profile, flag: true })

                // console.log(data1)
            }
            else {
                setIsDetailsValid(true)
                setTimeout(() => {
                    setIsDetailsValid(false)
                }, 3000)
                // console.log("whitespaces are not allowed")
            }
        }
        //}

    };
    useEffect(() => {
        if (data1.flag) {
            //    console.log(data1)
            postData('update_profile', data1)
                .then(d => {
                    data1.flag = false
                    if (d === "success") {
                        setLoading(false)
                        setMessage("Profile Updated Successfully.")
                        setUpdatePopup(false)
                        setTimeout(() => {
                            setAlert(false)
                        }, 3000)
                        props.dataUpdateHandler({
                            first_name: data1.data.first_name,
                            last_name: data1.data.last_name,

                            country: data1.data.country,
                            industry: data1.data.industry_type,
                            organization: data1.data.org_name,
                            contact: data1.data.contact,
                            is_otp_login:data1.data.is_otp_login
                        })

                    }
                    else if(d==="Incorrect Password."){
                        setLoading(false)
                        setMessage("Error, Incorrect Password")
                        setTimeout(() => {
                            setAlert(false)
                        }, 3000)
                    }
                    else {
                        setLoading(false)
                        setMessage("Error, Kindly update the profile again!")
                        setTimeout(() => {
                            setAlert(false)
                        }, 3000)
                    }
                }).catch(e => {
                    data1.flag = false
                    setLoading(false)
                    setMessage("Network Error")
                })
        }
    }, [data1.flag])
    const onSubmitPassword = (data) => {
        setAlert(true)
        setLoading(true)
        data.email = sessionStorage.getItem('email')
        postData('update_password', data)
            .then(d => {
                if (d === "success") {
                    setLoading(false)
                    setMessage("Password Changes Successfully.")
                    setTimeout(() => {
                        setAlert(false)
                    }, 3000)
                }
                else if (d === "Incorrect Current Password!!!") {
                    setLoading(false)
                    setMessage("Incorrect Current Password.")
                    setTimeout(() => {
                        setAlert(false)
                    }, 3000)
                }
                else {
                    setLoading(false)
                    setMessage("Network Error. Kinndly change the Password again.")
                    setTimeout(() => {
                        setAlert(false)
                    }, 3000)
                }

            }).catch(e => {
                setLoading(false)
                setMessage("Network Error")
            })
    };
    return (
        <React.Fragment>
            

            <div className='setting-main-container'>
                <div className='setting-radio-buttons'>
                    <label>
                        <input type="radio" name="release" checked={status === 1} onClick={(e) => radioHandler(1)} defaultChecked />
                        My Profile
                    </label>
                    <label className='setting-radio-button-lebel'>
                        <input type="radio" name="release" checked={status === 2} onClick={(e) => radioHandler(2)} />
                        Change Password
                    </label>
                    <label className='setting-radio-button-lebel'>
                        <input type="radio" name="release" checked={status === 3} onClick={(e) => radioHandler(3)} />
                        Add/Change Organization Logo
                    </label>
                </div>
                <hr />

                {status === 1 && (
                    <>
                        <div className='setting-screen1'>
                            <form ref={ref}>
                                <div class='setting-singleDiv'>
                                    <div class='setting-divDivide'>
                                        <p class='setting-textField'>First Name <span id='setting-requiredField'> *</span></p>

                                        <TextField class='setting-fieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="first_name"
                                            value={profile.first_name}
                                            disabled={enabler}
                                            onChange={firstName}
                                            required
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>


                                    <div class='setting-divDivide'>
                                        <p class='setting-fieldText setting-textField' >Last Name <span id='setting-requiredField'> *</span></p>

                                        <TextField class='setting-sideFieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="last_name"
                                            value={profile.last_name}
                                            disabled={enabler}
                                            onChange={lastName}
                                            required
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class='setting-singleDiv'>

                                    <div class='setting-divDivide'>
                                        <p class='setting-textField'>Organization Name <span id='setting-requiredField'> *</span></p>
                                        <TextField class='setting-fieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="org_name"
                                            onChange={orgName}
                                            required
                                            value={profile.org_name}
                                            disabled={enabler}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div class='setting-divDivide'>
                                        <p class='setting-fieldText setting-textField' >Contact Number </p>

                                        <TextField class='setting-sideFieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="contact"
                                            value={profile.contact}
                                            onChange={contactNo}
                                            disabled={enabler}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class='setting-singleDiv'>

                                    <div class='setting-divDivide'>
                                        <p class='setting-textField'>Industry Type</p>

                                        <TextField class='setting-fieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="industry_type"
                                            onChange={industryType}
                                            disabled={enabler}
                                            value={profile.industry_type}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div class='setting-divDivide'>
                                        <p class='setting-fieldText setting-textField'>Country <span id='setting-requiredField'> *</span></p>

                                        <TextField class='setting-sideFieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="country"
                                            required
                                            onChange={countryName}
                                            value={profile.country}
                                            disabled={enabler}
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickEnabler}>
                                                            {edit ? (<EditIcon className='setting-editOffIcon' />) : (<EditOffIcon className='setting-editOffIcon' />)}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="toggle-switch-div">
                                    <p>Two Factor Authentication</p>
                                    <FormGroup className="toggle-switch">
                                        <FormControlLabel control={<Switch checked={checked==1}
                                            onChange={handleToggleChange} />} label={(isTfa&&checked==1) ? "ON" : "OFF"} />
                                    </FormGroup>
                                </div>
                                {updatePopup ?
                                    <div className="update-popup-div">
                                        <div>
                                            <p style={{ marginTop: '20px' }}>Enter your Password to Update</p>
                                            <TextField class='setting-fieldInput'
                                        variant="outlined"
                                        fullWidth
                                        required
                                        name="confirm_current_password"
                                        value={profile.confirm_current_password}
                                        onChange={confirmCurrentPassword}
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        
                                    />

                                        </div>
                                    </div>
                                    : null
                                }
{!updatePopup?
                                <div className='setting-button-div'>
                                    <Button class="setting-button1" onClick={handleUpdatePopup}>Update</Button>
                                </div>
                                :null
}

                                {updatePopup?<div className='setting-button-div'>
                                    <Button type='submit' class="setting-button1" onClick={onSubmitProfile}>Confirm</Button>
                                </div>
                                :null
}

                            </form>
                            <div style={{ padding: "30px", width: "70%", textAlign: "center" }}>{message !== "" && !loading && alert ? <Alert severity={message === "Profile Updated Successfully." ? "success" : "error"}>{message}</Alert> : null}</div>
                            <div style={{ padding: "5px", width: "70%", textAlign: "center" }}>{isDetailsValid ? <Alert severity="error">{"Invalid Input."}</Alert> : null}</div>
                            {loading ? <div style={{ marginLeft: "35%", paddingBottom: "5px" }}><img alt='' src={loader} height="50px" /></div> : null}
                        </div>
                    </>
                )}
                {status === 2 && (
                    <>
                        <div className='setting-screen1'>
                            <form onSubmit={handleSubmit(onSubmitPassword)}>
                                <div class='setting-divDivide'>
                                    <p class='setting-textField'>Current Password <span id='setting-requiredField'> *</span></p>

                                    <TextField class='setting-fieldInput'
                                        variant="outlined"
                                        fullWidth
                                        name="current_passowrd"
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        {...register('current_passowrd', { required: " Current Password is required" })}
                                        error={Boolean(errors.current_passowrd)}
                                        helperText={errors.current_passowrd?.message}
                                    />
                                </div>
                                <div class='setting-singleDiv'>
                                    <div class='setting-divDivide'>
                                        <p class='setting-textField'>New Password <span id='setting-requiredField'> *</span></p>

                                        <TextField class='setting-fieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="new_password"
                                            type={showNewPassword ? "text" : "password"} // <-- This is where the magic happens
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            onMouseDown={handleMouseDownNewPassword}
                                                        >
                                                            {showNewPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            {...register('new_password', {
                                                required: "Password is required",
                                                validate: (value) =>
                                                    value.length > 5 || "Password must contain atleast 6 characters "
                                            })}
                                            error={Boolean(errors.new_password)}
                                            helperText={errors.new_password?.message}
                                        />
                                    </div>
                                    <div class='setting-divDivide'>
                                        <p class='setting-fieldText setting-textField'>Confirm Password <span id='setting-requiredField'> *</span></p>

                                        <TextField class='setting-sideFieldInput'
                                            variant="outlined"
                                            fullWidth
                                            name="confirmPassword"
                                            type={showConfirmPassword ? "text" : "password"} // <-- This is where the magic happens
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownConfirmPassword}
                                                        >
                                                            {showConfirmPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            {...register('confirmPassword', {
                                                required: " Confirm Password is required",
                                                validate: (value) =>
                                                    value === getValues("new_password") || "Password and Confirm Password do not match."
                                            })}
                                            error={Boolean(errors.confirmPassword)}
                                            helperText={errors.confirmPassword?.message}
                                        />
                                    </div>
                                </div>
                                <div className='setting-button-div'>
                                    <Button type='submit' class="setting-button1">Change</Button>


                                </div>
                            </form>
                            <div style={{ padding: "30px", width: "70%", textAlign: "center" }}>{message !== "" && !loading && alert ? <Alert severity={message === "Password Changes Successfully." ? "success" : "error"}>{message}</Alert> : null}</div>

                            {loading ? <div style={{ marginLeft: "35%", paddingBottom: "5px" }}><img alt='' src={loader} height="50px" /></div> : null}
                        </div>
                    </>
                )}

                {status == 3 ?

                    <div className="setting-img">

                        <img src={"data:image/png;base64, " + base64img} height={70} className="setting-logo" alt='No Image Available, Please Upload By Clicking The Pencil Icon'></img>
                        {<label className='button-blue-round'><EditIcon fontSize="small" /><input type='file' name="img" accept="image/*" onChange={(e) => fileAddEvent(e)} style={{ display: "none" }}></input></label>}

                        <div>
                            <Button onClick={saveNewLogo} class="button-pink">Update</Button>{loading ? <img src={loader} height="25px" /> : null}
                        </div>

                        <div style={{ padding: "30px", width: "70%", textAlign: "center" }}>{message !== "" && !loading && alert ? <Alert severity={message === "Image updated successfully." ? "success" : "error"}>{message}</Alert> : null}</div>

                    </div> : null


                }
            </div>

        </React.Fragment>
    );
}

export default Setting;