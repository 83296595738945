import React, { useEffect, useState } from 'react';
import './batchscan.css';
import SanctionList from '../../shared/scan/sanctionList/SanctionList';
import ScanConfiguration from '../../shared/scan/operationConfiguration/ScanConfiguration';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import {
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Button
} from "@material-ui/core";
import ResultHolderBatch from '../../shared/results/ResultHolderBatch';
import { FormControl } from '@mui/material';

function getStepContent(step, handleInput, setActiveStep, scan_result, scan, handleFile, fileData,userInput, creditUpdate) {
  switch (step) {
    case 0:
      return <ProfileDetails handleInput={handleInput} setActiveStep={setActiveStep} handleFile={handleFile} />;
    case 1:
      return <SanctionList handleInput={handleInput} setActiveStep={setActiveStep} activeStep={step} scanType={"Batch"}/>;
    case 2:
      return <ScanConfiguration handleInput={handleInput} setActiveStep={setActiveStep} scan={scan} activeStep={step} scanType={"Batch"} userInput={userInput.searchInList} inputData={userInput}/>;
    case 3:
      return <ResultHolderBatch creditUpdate={creditUpdate} activeStep={step} setActiveStep={setActiveStep} result={scan_result} fileData={fileData} userInput={userInput} ></ResultHolderBatch>;
    default:
      return <div>Unknown State Error : error_code={step}</div>;
  }
}


const ProfileDetails = (props) => {
  const [profile, setProfile] = useState({ entity: "Individual", searchInList: "All", adverseSelected: 1, scanType: "batch scan", fileName: "file", first_name: "", last_name: "", middle_name: "", identifier: "",address:"", adverseSelected: 1, dob: "", scan_state: "not ready" })
  const [fileData, setFileData] = useState([])
  const [fileName, setFileName] = useState('.csv');
  const [alert, setAlert] = useState('')
  const validate=(file)=>{
    for(var i =0; i<file.length; i++){
      console.log(file[i])
      if(file[i][0].length!==7){
        setFileName(".csv")
        setAlert("Incorrect format at line number : "+(parseInt(i)+1))
        
        return false
      }
    }
    setAlert('')
    return true
  }
  useEffect(() => {
    validate(fileData)?props.handleFile(fileData):console.log("validating")
  }, [fileData])

  const listType = (e) => {
    setProfile({ ...profile, searchInList: e.target.value.trim() })
  }
  const entityType=(e)=>{
    setProfile({ ...profile, entity: e.target.value.trim() })
  }
  const adverseMedia = (val) => {
    setProfile({ ...profile, adverseSelected: val })
  }

  const download_sample = ()=>{
  const element = document.createElement('a');
        
        element.setAttribute('href', '/Sample_Batch_Scan.csv');
        element.setAttribute('download', 'Sample_Batch_Scan.csv');



        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }
    const download_country_lookup = ()=>{
      const element = document.createElement('a');
            
            element.setAttribute('href', '/Country_Code_Lookups.pdf');
            element.setAttribute('download', 'Country_Code_Lookups.pdf');
    
    
    
            element.style.display = 'none';
    
            document.body.appendChild(element);
    
            element.click();
            document.body.removeChild(element);
        }

  const handleCollection = () => {
    
    
    if (fileName !== "" && fileName!==".csv" ){
      props.handleInput({ ...profile })
    
    if (profile.searchInList === "PEP") {
      props.setActiveStep(2)
    }
    else {
      props.setActiveStep(1)
    }
    }
  }
  

  useEffect(() => {
    if (!fileName.endsWith('.csv')) {
      setAlert("Invalid file type, please upload a CSV file.")
      setFileName('')

    }
  }, [fileName])

  const fileAddEvent=(ev) => {
    console.log('File(s) dropped');
    console.log(ev)
    ev.preventDefault();

    setFileName(ev.target.files[0].name)

    Array.from(ev.target.files)
    .filter((file)=> file.type==="text/csv"||file.type==="application/vnd.ms-excel")
    .forEach(async (file)=>{

      const text=await file.text()
      
      let split1= text.split('\n')
      let inputs = []
      for(var i =1 ;i<split1.length;i++){
        inputs.push([split1[i].trim().split(',')])
      }
      if(inputs[inputs.length-1][0][0]===""){
        inputs.pop()
      }
      setFileData(inputs.filter(item=>item[0][0]!==""))
    })
  }
  

  return (
    <>


      <div className='bs-input-div'>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div  style={{ textAlign:'center',width:'100%' ,marginRight:'-10%'}}>List Type</div>
        <div>
          <FormControl sx={{minWidth:120}} size="small">
          <Select id="list-type" className='bs-input-holder' defaultValue="All" fullWidth variant="outlined" onChange={listType}>
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"PEP"}>PEP</MenuItem>
            <MenuItem value={"Sanction"}>Sanction</MenuItem>
          </Select>
          </FormControl>
        </div>
        </div>
        <div className="ss-input-holder" style={{display:'flex',alignItems:'center',justifyContent:'center',marginLeft:'2%'}}>
          <div style={{ textAlign:'center',minWidth:'20%'}}>Entity type</div>
          <FormControl sx={{minWidth:300}} size="small">
          <Select id="entity-type" defaultValue="Individual" variant="outlined" onChange={entityType} >
            <MenuItem value={"Individual"}>Individual</MenuItem>
            <MenuItem value={"Organization"}>Organization</MenuItem>
          </Select>
          </FormControl>
        </div>
        <div className='bs-adverse-div' >
          <div style={{ textAlign:'center',width:'100%',marginRight:'2px' }}>Adverse Media</div>
          <div className="adverse-holder"><div className={profile.adverseSelected === 0 ? "adverse adverse-active-pink" : "adverse"} onClick={() => { adverseMedia(0) }}>Excluded</div><div onClick={() => { adverseMedia(1) }} className={profile.adverseSelected === 1 ? "adverse adverse-active-blue" : "adverse"} > Included</div></div>
        </div>
      </div>

    

      <div className='bs-warning-div'>
        <div className='bs-csv-warning'>
          The File shoud be in .csv format. The First Name & Last Name are required fields.
        </div>
        <div className='bs-sample-file-button-div'><Button class="bs-sample-file-button" onClick={download_sample}><DownloadSharpIcon />&nbsp; Sample File</Button></div>
        <div className='bs-sample-file-button-div'><Button class="bs-sample-file-button" onClick={download_country_lookup}><DownloadSharpIcon />&nbsp; Country Code Lookup</Button></div>
      </div>
      <div className='bs-file-name-display'>{fileName === ".csv" ? null : fileName}</div>
      <div className='bs-drag-drop-div'

        onDragOver={(ev) => {
          console.log('File(s) in drop zone');

          ev.preventDefault();
        }}


        onDrop={(ev) => {
          console.log('File(s) dropped');

          ev.preventDefault();

          setFileName(ev.dataTransfer.files[0].name)

          Array.from(ev.dataTransfer.files)
          .filter((file)=> file.type==="text/csv"||file.type==="application/vnd.ms-excel")
          .forEach(async (file)=>{
            //console.log(file)
            const text=await file.text()
            //console.log(text)
            let split1= text.split('\n')
            let inputs = []
            for(var i =1 ;i<split1.length;i++){
              inputs.push([split1[i].trim().split(',')])
            }
            if(inputs[inputs.length-1][0][0]===""){
              inputs.pop()
            }
            setFileData(inputs.filter(item=>item[0][0]!==""))
          })
        }}
      >
        <div><CloudUploadSharpIcon className='bs-file-upload-icon' /></div>
        <div className='bs-drag-drop-text'>Drop File Here</div>
        {<div ><label className='button-blue'>Choose File <input  type='file' onChange={(e)=>fileAddEvent(e)} style={{display:"none"}}></input></label></div>}
      </div>
      <div className="bs-input-holder">
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#EB2188", fontWeight: "bold" }}>{alert}</p>
         
        </div>

        <div className="button-holder">
          <Button class="button-blue" onClick={handleCollection}>Next</Button>
        </div>
      </div>

    </>
  );
}


const BatchScan = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Profile Details",
    "Sanction List",
    "Screen Profiles",
    "Results",
  ]

  const [userInput, updateUserInput] = useState({ email: sessionStorage.getItem('email'),sanction_list:[],filters :{fieldToField: true, nullable: true, wikipedia: false, nationality: false,foreignPEP:false,anyordermatch:false} });
  const [scanResult, setScanResult] = useState({ data: { results: [] } });
  const [fileData, setFileData] = useState([])
  const [file, setFile] = useState(null)

  const handleFile = (fileD) => {
    //parse the file and set the fileData
    setFileData(fileD)
    
  }

  const handleInput = (data) => {
    updateUserInput({ ...userInput, ...data })
  }

  const scan = () => {
    //  get table data here
  }
  React.useEffect(() => { console.log(userInput) }, [userInput])
  React.useEffect(() => { if (userInput.scan_state === "ready") { scan() } }, [userInput])
  return (
    <React.Fragment>
      {props.children}
      <div className="ss-container">
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step) => {
            return (
              <Step>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep, handleInput, setActiveStep, scanResult, scan, handleFile,fileData,userInput, props.creditUpdate)}
      </div>
    </React.Fragment>
  );
};

export default BatchScan;