import React, { useState, useEffect } from 'react';
import './historyFilter.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Wrapper from './../Wrapper';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    MenuItem,
    Button,
    TextField,
} from "@material-ui/core";



const HistoryFilter = (props) => {
    const listTypeNeeded = props.listTypeNeeded;
    const [refresher, setRefresher] = useState(props.userConfig)
    function checkHandler() {
        props.configHandler({ "startDate": startDate, "endDate": endDate, "listType": listType })
    }



    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [listType, setListType] = useState("All")
    function clear() {
        props.clearHandler()
        setStartDate(undefined)
        setEndDate(undefined)
    }

    return (
        <React.Fragment>
            <Wrapper title={"Filters"}>
                <div className="date-filter">
                    <div className='data-holder'>
                         <div className='date_box'><label>
                            <DatePicker className='date-picker'
                                placeholderText='&nbsp;&nbsp;&nbsp;Start Date'
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                maxDate={new Date()}
                               
                            />
                            <CalendarTodayIcon className='date-picker-icon' />
                        </label>
                        </div>
                        <div className='date_box'><label>
                            <DatePicker className='date-picker'
                                placeholderText='&nbsp;&nbsp;&nbsp;End Date'
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                minDate={startDate}

                            />
                            <CalendarTodayIcon className='date-picker-icon' />
                        </label>
                        </div>
                    </div>
                    
                    {listTypeNeeded ?
                        <div className="listType-holder">
                            <h5 className='headers'>List Type</h5>
                            <FormControl sx={{minWidth:120}} size="small">
                                    <Select value={listType} variant="outlined" onChange={e => { setListType(e.target.value) }}>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="PEP">PEP</MenuItem>
                                    <MenuItem value="Sanction">Sanction</MenuItem>
                                    </Select>
                                 </FormControl>
                        </div>
                        : null}

                       
                    <div className='button-holder'>
                        <Button onClick={checkHandler} className='btncheck'>Check</Button>
                        <Button onClick={clear} className='btnclear'>Clear</Button>
                    </div>

                </div>

            </Wrapper>
            <div className='msg'>
                {listTypeNeeded ? <p style={{ color: "#EB2188", lineHeight: "16px", margin: "0", padding: 0 }}>Click on the row to download the report.</p> : null}
                {props.children}

            </div>

        </React.Fragment>
    )
}

export default HistoryFilter;