import { useState } from 'react';
import './wrapper.css';

const Wrapper = (props) => {
    const title = props.title; 
    const [buttons, setButtons] = useState(props.buttons)
    
    return (
            <div className='wrapper-objects'>
                <div className="wrapper-title-bar">
                    <h4 style={{display:"inline"}}>{title}</h4>
                    {buttons===undefined?null:<div className="wrapper-btn">
                        {buttons.map(btn=>{return btn})}
                        </div>}
                </div>
                <div className="wrapper-content-holder">
                    {props.children}
                </div>
            </div>
    )
}

export default Wrapper;