import React from 'react';
import './pricing.css';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import Snackbar from '@mui/material/Snackbar';
import { withRouter, useHistory } from 'react-router-dom';

const PriceTable = (props) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            


            <div className='pricing-table'>
                <div className='pricing-heading'>Upgrade your plan</div>
                <table>
                    <thead>
                        <tr>
                            <th className={props.carting ? 'post=login-table' : 'pre-login-table'}>Package Name</th>
                            <th className={props.carting ? 'post-login-table' : 'pre-login-table'}>Scans</th>
                            <th className={props.carting ? 'post-login-table' : 'pre-login-table'}>Price(USD)</th>
                            <th className={props.carting ? 'post-login-table' : 'pre-login-table'}>Cost Per Scan</th>
                            {props.carting ? <th></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {props.price.map((d) => (
                            <tr>
                                <td className={props.crating ? 'post-login-table' : 'pre-login-table'}>{d.name}</td>
                                <td className={props.carting ? 'post-login-table' : 'pre-login-table'}>{d.id==1?"-":d.scans}</td>
                                <td className={props.carting ? 'post-login-table' : 'pre-login-table'}>{d.id==1?"-":'$' + d.price}</td>
                                <td className={props.carting ? 'post-login-table' : 'pre-login-table'}>${d.id===1 ?'2':(parseFloat(d.price) / parseFloat(d.scans)).toPrecision(2)}</td>
                                {props.carting ? <td><Tooltip title="Add to Cart"><IconButton className='cart-icon-button' onClick={() => { handleClick(); props.cartOperation(true, d.id - 1) }}><ShoppingCartIcon className='pricing-icons' /></IconButton></Tooltip></td> : null}
                            </tr>
                        ))}
                    </tbody>


                </table>
                <div style={{ textAlign: "center" }}>
                    {props.carting ? <Button class="button-blue" onClick={() => { props.history.push('/cart') }}>View Cart</Button> : null}
                </div>



            </div>
            {/* <div className='pricing-footer-line'>IntelleWings has flexible pricing options for clients with<span> more than 2000 scans.</span> Kindly contact us for more information!!</div>
            <div className='pricing-button-div'><button onClick={() => history.push('/contact')} className="button-blue" style={{ paddingBottom: "8px" }}>Contact Us</button></div>
 */}

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Added To Cart!"
                action={action}
            />
        </React.Fragment>
    )
}

export default withRouter(PriceTable);