import React, { useState, useEffect } from 'react';
import './api.css';
import { postData } from './../../../utility/networkCall'
import HistoryFilter from '../../shared/historyfilter/HistoryFilter';
import ApiReset from './../../shared/resetapi/ApiReset'
import Wrapper from '../../shared/Wrapper';
import HistoryTable from '../../shared/results/HistoryTable/HistoryTable';
import loading_svg from './../../../assets/load.svg'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button } from "@material-ui/core";




const API = (props) => {
    
    const [userData, setUserData] = useState(props.userData)
    const [orders, setOrders] = useState([])
    const [userConfig, setUserConfig] = useState({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false, listType:"All" })
    const [loading, setloading] = useState(false)
    const [message, setMessage] = useState("No API History Found!")

    const configHandler = (config) => {
        let temporary = { "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false }
         
        config.endDate !== undefined ? temporary.endDateSelected = true : temporary.endDateSelected = false
        if(config.startDate !== undefined){
            temporary.startDateSelected = true
            temporary.startDate = new Date(config.startDate).toISOString()
        }
        else{
            temporary.startDateSelected = false
        }

        if(config.endDate !== undefined){
            temporary.endDateSelected = true
            temporary.endDate = new Date(config.endDate).toISOString()
        }
        else{
            temporary.endDateSelected = false
        }
        temporary.listType = config.listType
        temporary.scanTypeSelected=	"api"
        
        setUserConfig(temporary)
    }

    const clearHandler = () => {
        setUserConfig({ "startDate": undefined, "startDateSelected": false, "endDate": undefined, "endDateSelected": false,"listType":"All" })
    }
    const headCells = [
        {
            id: 'requestTimeStamp',
            numeric: false,
            disablePadding: false,
            label: 'Date',
        },
        {
            id: 'firstname',
            numeric: false,
            disablePadding: false,
            label: 'Scanned Name',
        },
        {
            id: 'searchInList',
            numeric: false,
            disablePadding: false,
            label: 'List Type',
        },
        // {
        //     id: 'adverseSelected',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Adverse Media',
        // },
        {
            id: 'searchLevel',
            numeric: false,
            disablePadding: false,
            label: 'Search Operation',
        },
    ];

    

    useEffect(() => {
        setloading(true)
        postData('history', { "email": sessionStorage.getItem("email"),"scanTypeSelected":	"api", ...userConfig })
            .then(data => { setOrders(data);setloading(false) })
            .catch(err => { setMessage("Network Error!");setloading(false) })
    }, [userConfig])
    const buttons = [<Button class="button-wrapper-cta" startIcon={<FileCopyIcon />} href="https://documenter.getpostman.com/view/14297353/UUxxhoZo" target="_blank">View API Doc</Button>]
    return (
        <div className='api-container'>
            <Wrapper title={"API Integration"} buttons={buttons}>
            <ApiReset userData={props.userData} updateUserData={props.updateUserData}/>
            </Wrapper>
            <HistoryFilter listTypeNeeded={true} configHandler={configHandler} clearHandler={clearHandler}>
            {loading ? <div style={{textAlign:"center"}}>{<img alt='' height={"50px"} style={{background:"transparent", padding:"5px"}} src={loading_svg}/>}</div>:null}
            {orders.length === 0 && !loading ? <div style={{ margin: "auto", textAlign: "center", marginTop: "30px" }}><span style={{ color: "#EB2188", padding: "30px", fontWeight: "900" }}>No Scan History Found!</span></div> : <HistoryTable data={orders} headCells={headCells} orderBy={'requestTimeStamp'}/>}
            </HistoryFilter>
            
        </div>
    )
}

export default API;