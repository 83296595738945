import { Button } from "@material-ui/core";
import PEPSanctionResult from './PEPSanction/PEPSanctionResult'
import { useState,useEffect } from "react";
import AdverseResult from "./AdverseMedia/AdverseResult";
import './resultHolder.css';
import loader from './../../../assets/load.svg'
import Wrapper from './../../shared/Wrapper'

const ResultHolderKyc = (props) => {
    const pepSanctionData = props.result.data === undefined ? [] : props.result.data
    const adverseData = props.result.adverseData === undefined ? [] : props.result.adverseData
    const [screen, setscreen] = useState(true)
    const [config, setConfiguration] = useState(props.userData)
   
    const buttons = []
    return (
        <>

            {props.children}

            <div className="result-holder">
                <div className="button-holder">
                    <Button class="button-pink" onClick={() => { props.clear(); props.setActiveStep(props.activeStep - 1) }}>Back</Button>
                </div>
                {props.result.loading ? <div className="result-toggle"><img alt="" src={loader} height={"50px"} /> </div> : <Wrapper title="Scan Results" buttons={buttons}>
                    {props.userInput.adverseSelected === 1 ? <div className="result-toggle">
                        <div className={screen ? "sanction-toggle active-toggle" : "sanction-toggle"} onClick={() => { setscreen(true) }}>PEP/Sanction</div><div className={!screen ? "adverse-toggle active-toggle" : "adverse-toggle"} onClick={() => { setscreen(false) }}>Adverse Media</div>
                    </div> : null}
                    <p style={{ color: "#EB2188", fontWeight: "bold" }}>
                        {props.result.message === "Invalid token, No such kyc request initiated from INTELLEWINGS Customer Onboarding Module" || props.result.message==="We encountered internal error, please try again after some time." || props.result.message === "Network Error : You are not connected to internet."? props.result.message : `${pepSanctionData.results.length} PEP/Sanction Profile Found and ${props.userInput.adverseSelected === 1 ? adverseData.length.toString() +' Adverse Media Profile Found.':''}`}
                        </p>
                    {screen ? <PEPSanctionResult data={pepSanctionData}></PEPSanctionResult> : <AdverseResult adverseData={adverseData}></AdverseResult>}

                </Wrapper>}

            </div>
        </>
    );
};
export default ResultHolderKyc