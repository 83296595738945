const config = require('./configurations.json');

const getData = (url) => {
    let token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
        fetch(config.base_url+url, {
            method: 'GET', 
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'token':token
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer', 
          })
            .then(function (response) {
                if(response.status===401 || response.status === 403){
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('email')
                    sessionStorage.removeItem('type')
                    window.location.reload();
                }
                else{
                resolve(response.json())
                }
            })
            .catch(function (error) {
                reject(error)
            })
    });
}

const postData = (endpoint, data,extraData, extraHeaders) => {
    console.log(config.base_url+endpoint)
    let token = sessionStorage.getItem("token");
    return new Promise((resolve, reject) => {
        fetch(config.base_url+endpoint, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json',
              'token':token,
              ...extraHeaders
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data),
            ...extraData
          })
            .then(function (response) {
                if(response.status===401 || response.status === 403){
                    sessionStorage.removeItem('token')
                    sessionStorage.removeItem('email')
                    sessionStorage.removeItem('type')
                    window.location.reload();
                }
                else{
                resolve(response.json())
                }
            })
            .catch(function (error) {
                reject(error)
            })
    });
}

//module.exports= {getData:getData, postData:postData};
export {
    getData as getData,
    postData as postData,
  };
  