import React from 'react';
import { useHistory } from "react-router-dom";
import ctaImage1 from './../../../assets/comp.jpg';
import aiImage from './../../../assets/ai.png';
import scan1Image from './../../../assets/scan1.png';
import searchImage from './../../../assets/search.png';
import apiImage from './../../../assets/api.png';
import personImage from './../../../assets/person_s.png';
import unImage from './../../../assets/UN.png';
import './landingpage.css';


const LandingPage = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="landing-cta-signin-register">
                    <div className="info-area-0">
                        <h1>A Simplified AML/CFT Compliance  For BFSI</h1>
                        <p>Experience the most innovative and intuitive tools to simplify Sanctions & Anti Money Laundering Compliance. It is the power of AI and machine learning which we harness to provide the most comprehensive solution</p>
                        <button onClick={() => history.push('/register')} className='button-blue' style={{padding:"8px"}}>Try it for free</button>
                        <button onClick={() => history.push('/signin')} className='button-pink'style={{padding:"8px"}}>Sign In</button>
                    </div>
                    <div className="">
                        <img alt='' src={ctaImage1} className="Cta-img-1"></img>
                    </div>
                </div>
                <div className="landing-info-1">
                    <h1>Simplify your AML/CFT compliance with<br />
                        IntelleWings' specialised PEP/Sanction<br />
                        and Adverse Media screening solution
                    </h1>
                    <p>Use our platform to perform customer due diligence and efficiently onboard your clients in real time.</p>
                </div>
                <div className="landing-info-2">
                    <div>
                        <h1>Harness Power for AI</h1>
                        <p>Harness power of AI and Machine learning , to reduce false positives. Usage of latest technology trends in machine learning to reduce the false positives that will save the compliance officer or analyst\'s efforts in case analysis, and eventually saves your expenses</p>
                    </div>
                    <div className="">
                        <img alt='' src={aiImage} className="Cta-img-2"></img>
                    </div>
                </div>
                <div className="landing-info-3">
                    <h1>Our Services</h1>
                    <div className='info-3-div1'>
                        <div className='info-3-text'>
                            <h1>Politically Exposed Person(PEP) & Adverse Media Check</h1>
                            <p>IntelleWings has its own proprietary PEP/Adverse Media Check, which uses machines learning to accurately crawl the web for detecting PEP & Adverse Media profiles - it is comprehensive and accurate, helps you save a lot over World Check/DowJones/Factiva.</p>
                        </div>
                        <div className="">
                            <img alt='' src={personImage} className="personImage"></img>
                        </div>
                    </div>
                    <div className='info-3-div2'>
                        <div className='info-3-text'>
                            <h1>Sanction Screening</h1>
                            <p>IntelleWings scans profiles by scrubbing them against global & national sanction lists (e.g. EU, OFAC, HMT, UN, DFAT and many more).  IntelleWings ensures that these checks are almost real time.</p>
                        </div>
                        <div className="">
                            <img alt='' src={unImage} className="unImage"></img>
                        </div>
                    </div>
                </div>
                <div className="landing-info-4">
                    <h1>Features</h1>
                    <div className='landing-info-4-images'>
                        <div className="features-imag">
                            <img alt='' src={scan1Image} className="info-4-image"></img>
                            <div className='info-4-text1'>Single Scan</div>
                        </div>
                        <div className="features-imag">
                            <img alt='' src={searchImage} className="info-4-image"></img>
                            <div className='info-4-text2'>Batch Scan</div>
                        </div>
                        <div className="features-imag">
                            <img alt='' src={apiImage} className="info-4-image"></img>
                            <div className='info-4-text3'>API Integration</div>
                        </div>
                    </div>

                </div>
                <div className="landing-cta-1-contact">
                    <div>
                        <h1>Need more details? Contact us</h1>
                        <p>We are here to assist. Contact us by phone, email or via our social media channel.</p>
                    </div>
                    <div className="cta-contactus-btn-div">
                        <button onClick={() => history.push('/contact')} className='button-blue' style={{padding:"10px",paddingLeft:"30px", paddingRight:"30px"}}>Contact Us</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LandingPage;