import React, { useState, useRef, useEffect } from 'react';
import './singlescan.css';
import SanctionList from '../../shared/scan/sanctionList/SanctionList';
import ScanConfiguration from '../../shared/scan/operationConfiguration/ScanConfiguration';
import ResultHolder from '../../shared/results/ResultHolder';
import { postData } from '../../../utility/networkCall';
import CustomerProfileDetails from '../../shared/scan/CustomerProfileDetails/CustomerProfileDetails';

import {
  TextField,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Button
} from "@material-ui/core";

function getStepContent(step, handleInput, setActiveStep, scan_result, scan, userInput, clearResult,amlcheckscanResult) {
  switch (step) {
    case 0:
      return <CustomerProfileDetails handleInput={handleInput} setActiveStep={setActiveStep} userInput={userInput} />;
    case 1:
      return <SanctionList handleInput={handleInput} setActiveStep={setActiveStep} activeStep={step} scanType={"Single"} />;
    case 2:
      return <ScanConfiguration handleInput={handleInput} setActiveStep={setActiveStep} scan={scan} activeStep={step} userInput={userInput.searchInList} scanType={"Single"} inputData={userInput} />;
    case 3:
      return <ResultHolder button={true} downloadBtn={true} activeStep={step} setActiveStep={setActiveStep} result={scan_result} userInput={userInput} clear={clearResult} amlcheckscanResult={amlcheckscanResult}></ResultHolder>;
    default:
      return <div>Unknown State Error : error_code={step}</div>;
  }
}




const SingleScan = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Profile Details",
    "Sanction List",
    "Screen Profiles",
    "Results",
  ]
  const [amlcheckscanResult, setAmlcheckScanResult] = useState({data:{},scan_id:null,loading:false,message:""  });
  const [userInput, updateUserInput] = useState({ email: sessionStorage.getItem('email'), entity: "Individual", searchInList: "All", adverseSelected: 1, scanType: "single scan", fileName: "file", middle_name: "", identifier: [], address: "", dob: "", sanction_list: [], filters: { fieldToField: true, nullable: true, wikipedia: false, nationality: false, foreignPEP: false, anyordermatch: false }, nationality: "XX" });
  //const [scanResult, setScanResult] = useState({ data: { results: [] }, adverseData: [], loading: false, message: "" });

  const handleInput = (data) => {
    console.log(data, "data in handle input single scan")
    updateUserInput({ ...userInput, ...data })
  }

  const scan = () => {
    setAmlcheckScanResult({ data: {},scan_id:null ,loading: true, message: "" })
    //setScanResult({ ...scanResult, loading: true })
    postData('scan', userInput)
      .then(data => {
      
        if (data === "You have used up all your scans!") {
          setAmlcheckScanResult({ data: {},scan_id:null, loading: false, message: "You have used up all your scans!" })
          //setScanResult({ ...scanResult, loading: false, message: "You have used up all your scans!" });
        }
        else if (data.data !== undefined) {
          //console.log(data.data,"")
          //setScanResult({ ...data.data, loading: false })
          setAmlcheckScanResult({data:data.data,scan_id:data.scan_id,loading:false})

          postData('getUserScanDetails', { email: sessionStorage.getItem('email') })
            .then(d => { props.creditUpdate({ userData: d }) })
            .catch(e => { })

        }
        else {
          setAmlcheckScanResult({ data: {},scan_id:null, loading: false, message: "We encountered internal error, please try again after some time." })
         // setScanResult({ ...scanResult, loading: false, message: "We encountered internal error, please try again after some time." })
        }
      })
      .catch(e => {
        setAmlcheckScanResult({ data: {}, scan_id:null,loading: false, message: "Network Error : You are not connected to internet." })
       // setScanResult({ ...scanResult, loading: false, message: "Network Error : You are not connected to internet." })
      })
  }

  const clearResult = () => {
    setAmlcheckScanResult({ data: {},scan_id:null ,loading: false, message: "" })
    //setScanResult({ data: { results: [] }, adverseData: [], loading: false })
  }

  useEffect(() => { console.log(userInput,"updating userinput in useeffect") }, [userInput])
  useEffect(() => { if (userInput.scan_state === "ready") { scan() } }, [userInput])

  return (
    <>
      {props.children}
      <div className="ss-container">
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step) => {
            return (
              <Step>
                <StepLabel>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep, handleInput, setActiveStep, amlcheckscanResult, scan, userInput, clearResult,amlcheckscanResult)}
      </div>
    </>
  );
};

export default SingleScan;