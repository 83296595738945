import React,{useState} from 'react';
import './forgotPassword.css';
import EmailIcon from '@material-ui/icons/Email';
import {TextField,Button,IconButton} from "@material-ui/core";
import { pink } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider, createTheme, } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import{Link} from 'react-router-dom';
import { postData } from '../../../utility/networkCall';
import loader from './../../../assets/load.svg'
import Alert from '@mui/material/Alert';
import intellewings_logo1 from "./../../../assets/intellewings_logo1.png";
import showcase from "./../../../assets/showcase.png";
import './resetPassword.css'


const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "95%",

        margin: theme.spacing(1.5, 0),
    },
}));

const theme = createTheme({
    palette: {
        primary: pink,
    },
});


const ResetPassword = (props) => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const { register, handleSubmit, control, formState: { errors },getValues } = useForm();
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const[alert,setAlert]=useState(false)
    const email=props.email
    const token=props.token

    const onSubmit = (data) => {
        data.token=token
        data.email=email
        setLoading(true)
        postData('reset', data)
            .then(d => {
                if (d === 'success') {
                    setMessage('Password reset successfully.')
                    setTimeout(() => {
                        window.location.href='/signin'
                     }, 1000)
                }
                else if(d==='Wrong verification code!!'){
                    setMessage('Wrong verification code!!')
                }
                else {
                    setMessage('Some error occured. Kindly reset password again.')
                }
                setLoading(false)
            })
            .catch(e => {
                setMessage("network error!")
                setLoading(false)
            })

    }
    const classes = useStyles();

    return (
        <div className="forgot-password-page">
            <div className="page-container">
                <div className="image-container">
                    <img
                    alt="intellewings-logo"
                    src={intellewings_logo1}
                    className="logo"
                    ></img>
                    <img
                    alt="intellewings-showcase"
                    src={showcase}
                    className="showcase"
                    ></img>
                 </div>

          
            <div className="container">

                <div className='fp-heading'>Reset Password</div>
                <p className='fp-subheading'>Please provide new password along with the verification code sent to your email</p>
                <form className='resetForm' onSubmit={handleSubmit(onSubmit)}>
                <div className='fp-input'>
                    <ThemeProvider theme={theme}>
                        <TextField
                            className={classes.inputField}
                            label="Email Address"
                            variant="outlined"
                            id="mui-theme-provider-outlined-input"
                            name="email"
                            value={email}
                            defaultValue={props.email}
                            {...register('email', { required: "Enter a valid email address" })}
                             error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon className='fp-icons'/>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            className={classes.inputField}
                            label="Verification Code"
                            variant="outlined"
                            id="mui-theme-provider-outlined-input"
                            name="code"
                            {...register('code', { required: "Enter a valid Verification Code" })}
                             error={Boolean(errors.code)}
                            helperText={errors.code?.message}
                           
                        />
                        <TextField
                            className={classes.inputField}
                            label="New Password"
                            variant="outlined"
                            id="mui-theme-provider-outlined-input"
                            type={showNewPassword ? "text" : "password"} // <-- This is where the magic happens
                            name="password"
                                {...register('password', { required: "New Password is required",
                                validate: (value) =>
                                value.length>5  || "Password must contain atleast 6 characters " })}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton className='fp-icons'
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowNewPassword}
                                            onMouseDown={handleMouseDownNewPassword}
                                        >
                                            {showNewPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            className={classes.inputField}
                            label="Confirm Password"
                            variant="outlined"
                            id="mui-theme-provider-outlined-input"
                            type={showConfirmPassword ? "text" : "password"} // <-- This is where the magic happens
                            name="ConfirmPassword"
                                {...register('confirmPassword', { required: "Confirm Password is required",
                                validate: (value) =>
                                value === getValues("password") || "Password and Confirm Password do not match." })}
                                error={Boolean(errors.confirmPassword)}
                                helperText={errors.confirmPassword?.message}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton className='fp-icons'
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                        >
                                            {showConfirmPassword ? <Visibility className='visibility-icon' /> : <VisibilityOff className='visibility-icon' />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </ThemeProvider>
                </div>
                {loading ? <div style={{ textAlign: "center", lineHeight: "50px" }}><img src={loader} height={"50px"} style={{ display: "block", margin: "auto" }}></img></div> : null}
            
                <div className='fp-button-div'><button type='submit' class="fp-button">Reset Password</button></div>
                <p style={{display:"flex",alignSelf:"flex-start",marginTop:"30px"}}><Link to='/signin' className='fp-anchortag' >Go back to Sign in page</Link></p>
                </form>
                {message!==''&&<div style={{ padding: "10px",  textAlign: "center" }}>{message !== "" && !loading  ? <Alert severity={message === "Password reset successfully." ? "success" : "error"}>{message}</Alert> : null}</div>}


            </div>
            </div>
            </div>

    )
}

export default ResetPassword;
