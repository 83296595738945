import React,{useState} from 'react'
import './kycAssessment.css';
import ScanConfiguration from '../../shared/scan/operationConfiguration/ScanConfiguration';
import ResultHolderKyc from '../../shared/results/ResultHolderKyc';
import { postData } from '../../../utility/networkCall';

import {
    Stepper,
    Step,
    StepLabel,
  } from "@material-ui/core";


  function getStepContent(step, handleInput, setActiveStep, scan,scan_result, userInput, clearResult,flag) {
    switch (step) {
      case 0:
        return <ScanConfiguration handleInput={handleInput} setActiveStep={setActiveStep} scan={scan} activeStep={step} flag={flag} />;
      case 1:
        return <ResultHolderKyc activeStep={step} setActiveStep={setActiveStep} result={scan_result} userInput={userInput} clear={clearResult}></ResultHolderKyc>;
      default:
        return <div>Unknown State Error : error_code={step}</div>;
    }
  }  


const KycAssessment = (props) => {
    const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Screen Profiles",
    "Results",
  ]
  let token=window.location.search.slice(3)
  const flag='kyc'
  const [userInput, updateUserInput] = useState({ token:token,adverseSelected:1});
  const [scanResult, setScanResult] = useState({ data: { results: [] }, adverseData: [], loading: false, message: "" });

  const handleInput = (data) => {
    updateUserInput({ ...userInput, ...data })
  }
//   console.log(userInput)
  const scan = () => {
    setScanResult({ ...scanResult, loading: true })
    postData('kyc/assessmentResponse', userInput)
      .then(data => {
        if (data === "invalid token, no such kyc request initiated from INTELLEWINGS Customer Onboarding Module") {
          setScanResult({ ...scanResult, loading: false, message: "Invalid token, No such kyc request initiated from INTELLEWINGS Customer Onboarding Module" });
        }
        else if (data.data !== undefined || data.adverseData !== undefined) {
          setScanResult({ ...data, loading: false })
        }
        else {
          setScanResult({ ...scanResult, loading: false, message: "We encountered internal error, please try again after some time." })
        }
      })
      .catch(e => {
        setScanResult({ ...scanResult, loading: false, message: "Network Error : You are not connected to internet." })
      })
  }

  const clearResult = () => {
    setScanResult({ data: { results: [] }, adverseData: [], loading: false })
  }

  React.useEffect(() => { console.log(userInput) ; if (userInput.scan_state === "ready") {scan()} }, [userInput])
 

    return (
        <React.Fragment>
        {props.children}
        <div style={{marginTop:"75px"}}className="ss-container">
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step) => {
              return (
                <Step>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {getStepContent(activeStep, handleInput, setActiveStep,scan, scanResult, userInput,clearResult,flag)}
        </div>
      </React.Fragment>
    )
}

export default KycAssessment
