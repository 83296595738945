import React, { useEffect, useState } from "react";
import "./NameScan.css";
import { FaSearch } from "react-icons/fa";
import PEPSanctionResult from "../../shared/results/PEPSanction/PEPSanctionResult";
import { postData } from "../../../utility/networkCall";
import AdverseResult from "../../shared/results/AdverseMedia/AdverseResult";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const config = require("../../../utility/configurations.json");
const NameScan = (props) => {
  const [name, setName] = useState("");

  const handleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };
  const [searchResult, setSearchResult] = useState({});
  const [resultkey, setResultKey] = useState("");

  const sanctionResultData =
    searchResult.result === undefined
      ? []
      : searchResult.result[resultkey].sanction;
  const pepResultData =
    searchResult.result === undefined ? [] : searchResult.result[resultkey].pep;
  const adverseMediaResultData =
    searchResult.result === undefined
      ? []
      : searchResult.result[resultkey].adversemedia;

  const sanctionResultLength =
    searchResult.result === undefined
      ? 0
      : searchResult.result[resultkey].sanction.length;

  const pepResultLength =
    searchResult.result === undefined
      ? 0
      : searchResult.result[resultkey].pep.length;

  const adverseMediaResultLength =
    searchResult.result === undefined
      ? 0
      : searchResult.result[resultkey].adversemedia.length;

  const [entitytypeid, setEntityTypeid] = useState(1);
  const [resultType, setResultType] = useState(1);
  const [active, setActive] = useState("sanction");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearchResult({});
    const data = {
      name: name,
      entitytypeid: entitytypeid,
      email: sessionStorage.getItem("email"),
    };
    const endpoint = "nameScan";
    setResultKey(name);
    setLoading(true);
    postData(endpoint, data)
      .then((res) => {
        setSearchResult(res);
        console.log(res);
        setLoading(false);
        postData("getUserScanDetails", {
          email: sessionStorage.getItem("email"),
        })
          .then((d) => {
            props.creditUpdate({ userData: d });
          })
          .catch((e) => {});
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const renderResult = () => {
    switch (resultType) {
      case 1:
        return (
          <PEPSanctionResult
            data={sanctionResultData}
            resultType={resultType}
          />
        );
      case 2:
        return (
          <PEPSanctionResult data={pepResultData} resultType={resultType} />
        );
      case 3:
        return <AdverseResult data={adverseMediaResultData} />;
    }
  };

  const toggleResultType = (type) => {
    setResultType(type);
    switch (type) {
      case 1:
        setActive("sanction");
        break;
      case 2:
        setActive("pep");
        break;
      case 3:
        setActive("adverse");
        break;
    }
  };

  return (
    <div className="SingleScanPage">
        <div className="searchContainer">

          {props.children}
          <div className="header">
            <FaSearch />
            <p className="searchHeader">
              Scan a profile: Sanctions, PEP and Adverse Media checker
            </p>
          </div>
          <div className="searchBoxContainer">
            <div className="searchBox">
              <input
                className="searchinput"
                placeholder="Scan a person or Company"
                type="text"
                value={name}
                name="name"
                onChange={handleNameChange}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleSubmit(e);
                }}
              />
            </div>

            <div className="searchButton">
              <button className="button" onClick={(e) => handleSubmit(e)}>
                Search
              </button>
              <button className="clear" onClick={() => setName("")}>
                Clear
              </button>
            </div>
          </div>
          <div className="radioContainer">
            <div>
              <input
                type="radio"
                id="entityType1"
                name="entityType"
                value="1"
                checked={entitytypeid === 1}
                onChange={() => setEntityTypeid(1)}
              />
              <label htmlFor="entityType1">Individual</label>
            </div>

            <div>
              <input
                type="radio"
                id="entityType2"
                name="entityType"
                value="2"
                checked={entitytypeid === 2}
                onChange={() => setEntityTypeid(2)}
              />
              <label htmlFor="entityType2">Legal Entity</label>
            </div>
          </div>
        </div>

        {searchResult.result !== undefined ? (
          
          <div className="searchResultContainer">
            <p className="resultLength" >{sanctionResultLength} Sanction profiles, {pepResultLength} PEP profiles and {adverseMediaResultLength} Adverse Media profiles found.</p>
            <div className="searchResultType">
              <div
                className={active === "sanction" ? "active" : ""}
                onClick={() => toggleResultType(1)}
              >
                <button>Sanctions</button>{" "}
                {/* <p className="resultLength">{sanctionResultLength}</p> */}
              </div>
              <div
                className={active === "pep" ? "active" : ""}
                onClick={() => toggleResultType(2)}
              >
                <button>Pep</button>{" "}
                {/* <p className="resultLength">{pepResultLength}</p> */}
              </div>
              <div
                className={active === "adverse" ? "active" : ""}
                onClick={() => toggleResultType(3)}
              >
                <button>Adverse Media</button>{" "}
                {/* <p className="resultLength">{adverseMediaResultLength}</p> */}
              </div>
            </div>

            <div className="result">
              {searchResult.result !== undefined && renderResult()}
            </div>
          </div>
        ) : (
          <div>
            {loading ? (
              <Box sx={{ width: "100%" }}>
                <CircularProgress style={{ color: "#EB2188" }} />
              </Box>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
  );
};

export default NameScan;
