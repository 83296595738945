import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import './toggle.css'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 65,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(5px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(28px)',
            '& .MuiSwitch-thumb:before': {
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: "#234E70", // on color background
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: "grey", //ball color
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: "#EB2188", //off background
        borderRadius: 20 / 2,
    },
}));

const Toggle = (props) => {
    const [operation, setOperation] = useState(false)

    useEffect(() => {
        props.inputHandler(props.identity, operation ? 1 : 0)
    }, [operation])
    return (<div className="toggle">
        <MaterialUISwitch sx={{ m: 1 }} defaultChecked onClick={() => { setOperation(!operation) }} />
        <h3>{operation ? "OR" : "AND"}</h3>
    </div>)
}

export default Toggle