import React from 'react'
import './resultHolderBatch.css';
import { useState,useEffect } from "react";
import { Button } from "@material-ui/core";
import BatchTable from './BatchTable/BatchTable';
import { postData } from './../../../utility/networkCall';
import ResultHolder from './ResultHolder';



const ResultHolderBatch = (props) => {
    let tableData=props.fileData

    const[userInput,setUserInput]=useState(props.userInput)
    const[currentScan,setCurrentScan]=useState([["","","","","","",""]])
    const [amlcheckscanResult, setAmlcheckScanResult] = useState({data:{},scan_id:null,loading:false,message:""  });
    const [scanResult, setScanResult] = useState({ data: { results: [] }, adverseData: [], loading: false, message: "" });
    const[flag,setFlag]=useState(true)
    const clearResult = () => {
      setScanResult({ data: { results: [] }, adverseData: [], loading: false,message: "" })
    }
    useEffect(() => {
       let prepareData=userInput
       prepareData.first_name=currentScan[0][0]
       prepareData.middle_name=currentScan[0][1]
       prepareData.last_name=currentScan[0][2]
       prepareData.dob=currentScan[0][3]
       prepareData.identifier=currentScan[0][4]
       prepareData.nationality=currentScan[0][5]
       prepareData.address=currentScan[0][6]
       const scan = () => {
        setAmlcheckScanResult({ data: {},scan_id:null, loading: true, message: "" })
        //setScanResult({ ...scanResult, loading: true })
        postData('scan', prepareData)
          .then(data => {
            if (data === "You have used up all your scans!") {
              setAmlcheckScanResult({ ...amlcheckscanResult,scan_id:null, loading: false, message: "You have used up all your scans!" });
             // setScanResult({ ...scanResult, loading: false, message: "You have used up all your scans!" });
            }
            else if (data.data !== undefined || data.adverseData !== undefined) {
              setAmlcheckScanResult({ ...data,scan_id:data.scan_id, loading: false })
            //  setScanResult({ ...data, loading: false })
              
              postData('getUserScanDetails', {email:sessionStorage.getItem('email')})
          .then(d=>{props.creditUpdate({userData:d})})
          .catch(e=>{})
            }
            else {
              setAmlcheckScanResult({ ...amlcheckscanResult,scan_id:null, loading: false, message: "We encountered internal error, please try again after some time." })
              //setScanResult({ ...scanResult, loading: false, message: "We encountered internal error, please try again after some time." })
            }
          })
          .catch(e => {
            setAmlcheckScanResult({ ...amlcheckscanResult,scan_id:null, loading: false, message: "Network Error : You are not connected to internet." })
            //setScanResult({ ...scanResult, loading: false, message: "Network Error : You are not connected to internet." })
          })
      }
      if(currentScan[0][0]===""){
        setFlag(false)
      }
      else{
        setFlag(true)
        scan()
       
      }
       
    }, [currentScan])
    return (
        <>
            {props.children}
            <div className="button-holder">
                    <Button class="button-pink" onClick={() => {  props.setActiveStep(0) }}>Back To Batch Scan</Button>
                    <Button class="button-pink" onClick={() => {  props.setActiveStep(props.activeStep - 1) }}>Back</Button>
                </div>
            
               <BatchTable tableData={tableData} setCurrentScan={setCurrentScan} currentScan={currentScan} wrapper={true} title="Batch File Details"/>
                {flag&&<ResultHolder amlcheckscanResult={amlcheckscanResult} userInput={userInput}  downloadBtn={true}/>}
        </>
    );
};

export default ResultHolderBatch;
