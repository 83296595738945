import React, { useState, useRef } from 'react';

import { Modal, Box, Typography, Stack } from '@mui/material';
import { Button } from '@material-ui/core';
import IntellewingsLogo from './../../../assets/iwLogoDark.e0665cfa.svg';

import './serviceAgreement.css';
const ServiceAgreement = ({ name, open, onClose, formData,isProfileView }) => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const divRef = useRef();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '55%',
        bgcolor: 'background.paper',

        boxShadow: 30,
        p: 4,

        zIndex: 10,
        overflow: 'scroll',
        height: '85%',
        display: 'block',
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
                className="modalcss"
            >


                <Box sx={style}  >


                    <div className='agreement-header'>

                        <img alt='' src={IntellewingsLogo} className="m-0 p-0 Navbar2-Logo"></img>

                        <Typography id="modal-modal-title" variant="h5" component="h2" >
                            SERVICE AGREEMENT
                        </Typography>




                    </div>
                    <hr />
                    <Typography id="modal-modal-description" style={{ fontSize: '15px' }}>

                        <p>This SERVICE AGREEMENT (“Agreement”) is made at IntelleWings office, on Date ({date}) between:

                            IntelleWings Private Limited, a private company established under the Companies Act,
                            2013 bearing CIN U72900DL2019PTC352670 and having its registered office at RZ 65/A,
                            Gali No 6, Vinoba Enclave Extn CRPF Colony, Jharoda Kalan, Delhi - 110072,
                            duly represented by its authorized signatory,
                            Pramod Sharma (hereinafter referred to as the “Service Provider” which expression shall unless repugnant to the context or meaning thereof,
                            mean and include its successors-in-interest and permitted assigns) of the ONE PART; </p>
                        <p><b >                         AND </b></p>
                        <p>
                            <b>‘{name}' </b>a company having its registered office at Address.
                            (Hereinafter called “Customer” which expression,
                            unless it is repugnant to the context or meaning thereof be deemed to mean and include his heirs,
                            administrators, executors, legal representatives, successors, and permitted assigns) of the <b>SECOND PART.</b>
                            <br></br>
                            The Customer and the Service Provider are hereinafter collectively referred to as the “Parties” and
                            individually as the “Party” as the context may require.
                        </p>
                        <ol type="A" > <b>WHEREAS:</b>
                            <li >
                                The Service Provider is engaged in the business of developing Sanctions <span>&</span> Anti-Money Laundering solutions (“Business”).
                            </li>
                            <li>
                                The Customer is desirous of appointing the Service Provider for the purpose of providing the services as mentioned in Clause 2 of this Agreement <span>(“Services”).</span>
                            </li>
                            <li>
                                The Service Provider has represented to the Customer that it has the requisite skill, knowledge, experience, expertise, infrastructure, equipment,
                                and capability to provide the Services, and has trained and experienced personnel having requisite skills, knowledge, experience, and expertise to
                                deliver and perform the Services.
                            </li>
                            <li>
                                Relying on the above and at the request of the Service Provider, the Customer has agreed to avail Services from the Service Provider on the terms
                                and conditions contained in this Agreement.
                            </li>
                        </ol>
                        NOW, THEREFORE, in consideration of the mutual covenants and agreements set forth herein and for other good and valuable consideration,
                        the receipt and sufficiency of which is hereby acknowledged, the Parties hereto agree as follows:
                        <ol>
                            <li><b><span>Customer’s</span> use of the IntelleWings Service </b>
                                <ol type="a">
                                    <li >
                                        IntelleWings will provide the Customer the access to GlobalScan Service in accordance with this Agreement.
                                    </li>
                                    <li>
                                        During this Agreement, IntelleWings grants to the Customer a non-exclusive, non-transferable,
                                        revocable license to access and use the GlobalScan Service only for the purposes, and subject to any restrictions,
                                        specified in this Agreement, including in any Addenda.
                                    </li>
                                    <li>
                                        <b>The Customer must: </b>
                                        <ol>
                                            <li>
                                                use the GlobalScan Service only for the purpose of:
                                                <ol type="A">
                                                    <li>
                                                        assisting in complying with legal duties and regulations which apply to the Customer;
                                                    </li>
                                                    <li>
                                                        performing a statutory role as a Governmental organization;
                                                    </li>
                                                    <li>
                                                        performing law enforcement duties; or
                                                    </li>
                                                    <li>
                                                        assisting a third-party entity in relation to the obligations set out in (A) to (C) above.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                only allow Permitted Users to access and use the IntelleWings Globalscan Service;
                                            </li>
                                            <li>
                                                prevent any third person from using the <span>Customer’s</span> user ID or password;
                                                inform IntelleWings immediately of any actual or potential unauthorized access to the <span>Customer’s</span> GlobalScan account,
                                                or to any part of the GlobalScan Service
                                            </li>

                                        </ol>
                                    </li>
                                    <li>
                                        <b>
                                            The Customer must not:
                                        </b>
                                        <ol>
                                            <li>
                                                reproduce, distribute, display, sell, publish, broadcast, or circulate the IntelleWings Globalscan Service or any information
                                                retrieved from the IntelleWings Globalscan Service to any third party (than otherwise required by law), nor make the IntelleWings
                                                Globalscan Service available for any such use, unless authorized in writing by IntelleWings;
                                            </li>
                                            <li>
                                                use any device, software, or routine to interfere or attempt to interfere with the proper working of the
                                                IntelleWings GlobalScan Service or any activity conducted through the Website; or
                                            </li>
                                            <li>
                                                take any action which imposes an unreasonable or disproportionately large load on IntelleWings infrastructure.
                                            </li>

                                        </ol>
                                    </li>
                                    <li>
                                        The Customer allows the use of its name and logo in any IntelleWings  marketing material,
                                        including references to you as a user of the IntelleWings Globalscan Service, on the IntelleWings/ Globalscan Services Website.
                                    </li>
                                </ol>
                            </li>
                            <li> <b>
                                Term
                            </b>
                                <ol type="a">
                                    <li>
                                        This Agreement commences on the Commencement Date and continues unless terminated in accordance with this Agreement.
                                    </li>
                                </ol>
                            </li>
                            <li> <b>
                                Fees
                            </b>
                                <ol type="a">
                                    <li>
                                        The Customer must pay to IntelleWings the fees specified on the Website at the time of subscription / purchase.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Privacy
                                </b>
                                <br></br>
                                Each party agrees to comply with all privacy and other data protection laws that apply to it and the reasonable
                                directions of the other party in relation to personal information supplied by that other party.
                            </li>
                            <li>
                                <b>
                                    Intellectual Property Rights
                                </b>
                                <ol type="a">
                                    <li>
                                        The intellectual property rights in all software, content, and functionality of the IntelleWings Services,
                                        including Globalscan Service and all information supplied through the IntelleWings Services are owned by IntelleWings Private Limited.
                                    </li>
                                    <li>
                                        The Customer retains all intellectual property rights in all information and other materials the Customer
                                        supplies to IntelleWings under this Agreement. The Customer grants IntelleWings, a nonexclusive license to
                                        use such information and materials for the purpose of supplying the IntelleWings Globalscan Service to the Customer.
                                    </li>
                                </ol>
                            </li>
                            <li><b>
                                Termination
                            </b>
                                <ol type='a'>
                                    <li>
                                        Either party may terminate this Agreement at any time.
                                    </li>
                                    <li>
                                        If this Agreement is terminated, the Customer must:
                                        <ol>
                                            <li>
                                                cease using the IntelleWings Service by the effective date of expiration or termination;
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        pay to IntelleWings all Fees for all IntelleWings Services supplied up to the effective date of termination;
                                        and return to IntelleWings, or at IntelleWings direction, destroy, all copies of all information, content,
                                        and other materials obtained by the Customer from the IntelleWings Service,
                                        except to the extent the Customer is required by law to retain any such information, content or other materials.
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <b>
                                    Representations and Warranties
                                </b>
                                <ol type='a'>
                                    <li>
                                        Each Party represents to the other Parties hereto that:
                                        <ol type='i'>
                                            <li>
                                                such Party has the full power and authority to enter into, execute and deliver this Agreement and to perform the transactions contemplated
                                                hereby and that such Party is duly incorporated or organized and existing under the laws of the jurisdiction of its incorporation or organization
                                                and that the execution and delivery by such a Party of this Agreement and the performance by such a Party of the transactions contemplated hereby
                                                have been duly authorized by all necessary corporate or other action of such Party;
                                            </li>
                                            <li>
                                                assuming the due authorization, execution, and delivery hereof by the other Parties, this Agreement constitutes the legal, valid, and binding obligation
                                                of such Party, enforceable against such Party in accordance with its terms, except as
                                                such enforceability may be limited by applicable bankruptcy, insolvency, reorganization, moratorium or similar laws affecting creditors' rights generally;
                                            </li>
                                            <li>
                                                The execution, delivery and performance of this Agreement by such Party and the consummation of the transactions contemplated hereby will not (i)
                                                violate any provision of the organizational or governance documents of such Party; (ii) require such Party to obtain any consent, approval or action
                                                of, or make any filing with or give any notice to, any Governmental Authority or any other Person pursuant to any instrument, contract or other agreement
                                                to which such Party is a party or by which such Party is bound ; (iii) conflict with or result in any material breach or violation of any of the terms
                                                and conditions of, or constitute (or with notice or lapse of time or both constitute) a default under, any instrument, contract or other agreement
                                                to which such Party is a party or by which such Party is bound; (iv) violate any order, judgement or decree against, or binding upon, such Party or upon its respective securities,
                                                properties or businesses; or (v) violate any applicable Law and/or the law of any other country in which it maintains its principal office.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Cookies
                                </b>
                                <p>
                                    A cookie will be stored as a simple text file on your computer or mobile device by a website’s server and only that server will be able to retrieve or read the contents of that cookie.
                                    Cookies let you navigate between pages efficiently as they store your preferences,
                                    and generally improve your experience of a website.
                                </p>
                                <ol type='i' >
                                    <li>
                                        Analytics cookies for anonymously remembering your computer or mobile device when you visit our website to keep track of browsing patterns.
                                    </li>
                                    <li>
                                        Service cookies for helping us to make our website work efficiently,
                                        remembering your registration and login details, settings preferences, and keeping track of the pages you view.
                                    </li>
                                    <li>
                                        Non-persistent cookies a.k.a per-session cookies. Per-session cookies serve the technical purpose of iding seamless navigation. These cookies do not collect personal information on users,
                                        and they are deleted as soon as you leave our website. The cookies do not permanently record data and they are not stored on your computer’s hard drive.
                                        The cookies are stored in memory and are only available during an active browser session. Again, once you close your browser, the cookie disappears.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Confidentiality
                                </b>
                                <ol type='a' >
                                    <li>
                                        The Parties acknowledge that the Confidential Information has been and will be made available by one Party to the other during the course of performance of this Agreement.
                                        In view of the above, each Party hereby agrees, undertakes, and covenants that it will:
                                        <ol type='i'>
                                            <li>
                                                keep the Confidential Information strictly confidential.
                                            </li>
                                            <li>
                                                not disclose the Confidential Information to any person without the prior written consent of the other Party,
                                                and then only under conditions of confidentiality approved in writing by such Party or no less onerous than those contained in this Agreement;
                                            </li>
                                            <li>
                                                use the same degree of care to protect the confidentiality of the Confidential Information as it uses to protect its own confidential
                                                information of a similar nature, being at least a reasonable degree of care;
                                            </li>
                                            <li>
                                                act in good faith always in relation to the Confidential Information; and
                                            </li>
                                            <li>
                                                not use any of the Confidential Information for any purpose other than listed under this Agreement.
                                            </li>

                                        </ol>
                                    </li>
                                    <li>
                                        Either Party may disclose the Confidential Information to their officers, employees, professional advisers, insurers, agents, and subcontractors
                                        who have a need to access the Confidential Information for the performance of their work with respect to this Agreement
                                        and who are bound by a written agreement or professional obligation to protect the confidentiality of the Confidential Information.
                                    </li>
                                    <li>
                                        This Clause imposes no obligations on the Party with respect to Confidential Information that:
                                        <ol type='i'>
                                            <li>
                                                is known to such Party before disclosure under this Agreement and is not subject to any other obligation of confidentiality;
                                            </li>
                                            <li>
                                                is or becomes publicly known through no act or default of such Party; or
                                            </li>
                                            <li>
                                                is obtained by such Party from a third party in circumstances where such Party has no reason to believe that there has been a breach of an obligation of confidentiality.
                                            </li>

                                        </ol>
                                    </li>
                                    <li>
                                        The restrictions in this Clause do not apply to the extent that any Confidential Information is required to be disclosed by any law or regulation,
                                        by any judicial or governmental order or request,
                                        or pursuant to disclosure requirements relating to the listing of the stock of the Provider on any recognized stock exchange.
                                    </li>
                                    <li>
                                        This Clause shall survive the termination/expiration of the Agreement and shall be valid for a period of 1 (One) year from the date of said termination/expiration.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Indemnity and Limitation of Liability
                                </b>
                                <ol type='a'>
                                    <li>
                                        Customer shall, at their own expense, indemnify, defend and hold harmless Service Provider from and against any and all liability suffered
                                        or incurred (including but not limited to liabilities, judgments, damages, losses, claims, costs, and expenses, including attorney’s fees and expenses)
                                        or any other loss that may occur, arising from or relating to the Agreement including but not limited to breach by such Party and/or their personnel
                                        of any of the terms, conditions, covenants, representations, undertakings, lapses, obligations or warranties under this Agreement, including but
                                        not limited to the obligations pertaining to confidentiality, violation of applicable laws or any guidelines issued by Service Provider from time
                                        to time; or acts, material omissions, errors, misrepresentations,
                                        misconduct, negligence by Customer and/or their personnel in performance of their obligations under this Agreement.
                                    </li>
                                    <li>
                                        Notwithstanding anything to the contrary elsewhere contained in this Agreement, neither Party shall, in any event, regardless of the form of claim,
                                        be liable for any indirect, special, punitive, exemplary, speculative or consequential damages, including, but not limited to, (i) any loss of use
                                        or production; (ii) business, contracts or opportunities; (iii) loss of income, revenue or profits; (iv) loss of any data, database or software;
                                        or irrespective of whether it had an advance notice of the possibility of any such damages.
                                    </li>
                                    <li>
                                        Notwithstanding anything to the contrary contained in this Agreement, the either Party’s aggregate liability for any event or series of events,
                                        whether in contract or otherwise, arising out of or in connection with this Agreement shall not exceed the Subscription Fees paid by the Customer
                                        to the Service Provider during the Term of this Agreement. This clause shall survive the termination of this Agreement. To the extent any liability
                                        of Service Provider cannot be disclaimed, excluded, or limited under applicable law, such liability shall be disclaimed, excluded, and limited to
                                        the fullest extent permitted under applicable law Neither Party shall be liable to the other Party in respect of any losses arising out of a Force Majeure Event.
                                    </li>
                                </ol>

                            </li>
                            <li>
                                <b>
                                    Governing Law and Dispute Resolution
                                </b>
                                <ol type='a'>
                                    <li>
                                        This Agreement shall be governed exclusively by and shall be construed and enforced in accordance with the laws of India.
                                    </li>
                                    <li>
                                        Customer agrees that Service Provider shall not be held accountable for any non-compliance with the local laws of other jurisdictions as applicable on the Parties under this Agreement.
                                        It will be the responsibility of the customer to ensure that his use of the service is in compliance with the local laws.
                                    </li>
                                    <li>
                                        The Parties hereby agree that they intend to discharge their obligations in utmost good faith. All disputes and differences whatsoever arising out of,
                                        or in connection with this Agreement, shall be referred to arbitration under the Arbitration and Conciliation Act, 1996.
                                        Disputes shall be referred to a panel of 03 (Three) arbitrators, with the disputing Parties entitled to appoint 01 (One)
                                        arbitrator each, and the third arbitrator shall be appointed by two of the arbitrators. Arbitration awards rendered shall
                                        be final and binding. Arbitration shall be conducted in the English language and the venue and seat of the proceedings shall be New Delhi.
                                        Arbitrator shall have a right to levy interest for a period prior to arbitration.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>
                                    Miscellaneous
                                </b>

                                <ol type='a'>
                                    <li>
                                        This Agreement constitutes the only arrangement between the Parties with respect to the subject matter
                                        hereof and supersedes all prior written agreements, understandings, and negotiations, both written and oral,
                                        between the Parties with respect to the subject matter of this Agreement. No representation, inducement, promise,
                                        understanding, condition, or warranty
                                        not set forth herein has been made or relied upon by any Party hereto.
                                    </li>
                                    <li>
                                        The Service Provider shall have the right to assign or transfer all or any of its rights, fits, or obligations under
                                        this Agreement without the prior written approval of the Customer. The Customer reserves the right to assign this Agreement
                                        or part of it to any of its affiliates or third party without the consent of Service Provider.
                                    </li>
                                    <li>
                                        The relationship between the Service Provider and the Customer is that of an independent contractor, and the Service Provider shall not be deemed to be an agent or employee of the Customer.
                                    </li>
                                    <li>
                                        Force Majeure: If and to the extent that a Party’s performance of any of its obligations pursuant to this Agreement is prevented,
                                        hindered or delayed by reason of fire, flood, earthquake, explosion or other casualty or accident or act of God, war or other violence,
                                        or any applicable law, order proclamation, regulation, ordinance, demand or requirement of any governmental or regulatory authority and
                                        such non-performance, hindrance or delay could not have been prevented by reasonable foresight or precautions (including proper planning and
                                        execution of the disaster recovery or business continuity plan) or circumvented through the use of alternate sources, work-around plans or other means,
                                        (in each case, a “Force Majeure Event”), then the non-performing, hindered or delayed Party will be excused for such non-performance, hindrance or delay,
                                        as applicable, of those obligations to the extent that they are affected by the Force Majeure Event for as long as such Force Majeure Event continues and
                                        such Party continues to use its commercially reasonable efforts to re-commence performance whenever and to whatever extent possible without delay, including
                                        through the use of alternate sources, workaround plans or other means, provided the affected Party gives prompt written notice of the Force Majeure Event to
                                        the other Party within 2 (Two) days of commencement of the Force Majeure Event. If the period of non-performance exceeds 30 (Thirty) days from the receipt
                                        of notice of the Force Majeure Event, the Party whose ability to
                                        perform has not been so affected may give written notice to terminate this Agreement.
                                    </li>
                                </ol>

                            </li>
                        </ol>

                        <hr />
                    </Typography>
                    <div>
                        <div className='agreement-footer'>
                            <Button onClick={onClose} style = {{backgroundColor:"#234e70",color:"white"}} variant="contained"  >close</Button>
                            {/* <Button   color="primary" variant="contained" onClick={formData} >
                                Accept and Continue
                            </Button> */}
                             {isProfileView !==false && <Button  onClick={formData} style= {{backgroundColor:"#eb2188",color:"white"}} variant="contained"  >Accept and Continue</Button>}
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ServiceAgreement;